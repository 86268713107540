import { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';

import { NoDataMessage } from './NoDataMessage';
import { StringsTableHeaders } from './StringsTableHeaders';
import { StringsGroup } from './StringsGroup';
import { StyledTable } from './styles';
import { StringTable, StringTableGroup, StringTableItem } from '../types';

const hasVisibleItems = (groupItems: StringTableItem[] | undefined) =>
  groupItems && !!groupItems.length && groupItems.some((item) => item.visible);

const getVisibleStringsCount = (data: StringTable | undefined) => {
  if (!data) return 0;

  return Object.values(data).reduce((acc: number, group) => {
    if (Array.isArray(group)) {
      return (
        acc + group.reduce((acc, groupChild) => acc + (groupChild.items?.filter((item) => item.visible).length ?? 0), 0)
      );
    }

    return acc + ((group as StringTableGroup).items ?? []).filter((item) => item.visible).length;
  }, 0);
};

type StringsTableProps = {
  countAllStrings: number;
  data: StringTable | undefined;
  learningLanguage: LanguageV2;
  selectedStrings: DBId[];
  onToggle: (selectedString: DBId) => void;
  onToggleCheckAll: (selectAll: boolean) => void;
};

export const StringsTable = ({
  countAllStrings,
  data,
  learningLanguage,
  selectedStrings,
  onToggle,
  onToggleCheckAll,
}: StringsTableProps) => {
  const countVisibleStrings = getVisibleStringsCount(data);

  return (
    <StyledTable data-testid="download-translation-request-table">
      <StringsTableHeaders
        countAllStrings={countAllStrings}
        countSelectedStrings={selectedStrings.length}
        learningLanguage={learningLanguage}
        onToggleCheckAll={onToggleCheckAll}
      />
      <tbody data-testid="download-translation-request-table-body">
        {data && countVisibleStrings > 0 ? (
          <>
            {[data.course, data.level, data.chapter, data.lesson].map(
              (group, index) =>
                hasVisibleItems(group.items) && (
                  <StringsGroup
                    data={group}
                    key={`strings-group-${group.id ?? index}`}
                    selectedStrings={selectedStrings}
                    onToggle={onToggle}
                  />
                ),
            )}

            {data.exercises?.map(
              (exercise) =>
                hasVisibleItems(exercise.items) && (
                  <StringsGroup
                    data={exercise}
                    key={`strings-group-${exercise.id}`}
                    selectedStrings={selectedStrings}
                    onToggle={onToggle}
                  />
                ),
            )}
          </>
        ) : (
          <NoDataMessage />
        )}
      </tbody>
    </StyledTable>
  );
};
