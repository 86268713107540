import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import { DBId } from '@common/types/DBId';
import { CardPublishingStatus } from '@components/ContentTypes/BaseContent/CardPublishingStatus';

import { ReactComponent as UnreadCommentsIcon } from './_img/unread-comments.svg';
import { ReactComponent as MoveIcon } from './ContentActionsMenu/_img/move.svg';

import {
  NAVIGATION_PANEL_WIDTH,
  OTHER_ELEMENTS_HEIGHT,
  NAVIGATION_ELEMENT_HEIGHT_REM,
  NAVIGATION_PANEL_COLLAPSED_WIDTH,
} from './constants';

export const NavigationContainer = styled.div<{ countTopButtons: number; isDisabled: boolean }>`
  ${({ countTopButtons, isDisabled }) => css`
    height: calc(100vh - (${OTHER_ELEMENTS_HEIGHT} + ${countTopButtons * NAVIGATION_ELEMENT_HEIGHT_REM}rem));
    opacity: ${isDisabled ? '0.6' : '1'};
    overflow: auto;
    width: 100%;
  `}
`;

export const NavigationPanelContainer = styled.div<{ $collapsed?: boolean }>`
  ${({ theme, $collapsed }) => css`
    background: ${theme.colorV2.navigationBackground};
    width: ${$collapsed ? NAVIGATION_PANEL_COLLAPSED_WIDTH : NAVIGATION_PANEL_WIDTH};
    z-index: ${theme.zIndex.navigationSidebar};
  `}
  min-height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 100ms;
`;

export const LogoAndNavigationContainer = styled.div<{ $collapsed?: boolean }>`
  align-items: ${({ $collapsed }) => ($collapsed ? 'center' : 'flex-start')};
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const ChildrenArray = styled.div<{ visible: boolean; childrenAmount: number }>`
  ${({ childrenAmount, visible }) => css`
    max-height: ${visible ? `${(childrenAmount + 1) * NAVIGATION_ELEMENT_HEIGHT_REM}` : '0'}rem;
    overflow: auto;
    padding-left: 1.5rem;
    transition: max-height 0.2s ease;
  `}
`;

export const ItemLabel = styled.div<{
  contentId: DBId;
  disabled: boolean;
  isActive: boolean;
  idForContentActionsMenu: DBId | undefined;
  isSelectable: boolean | undefined;
  isSelected: boolean | undefined;
}>`
  ${({ contentId, isActive, idForContentActionsMenu, theme }) => css`
    border-radius: 0.8rem;
    position: relative;
    transition: background-color ease 0.3s;
    background: ${isActive ? theme.colorV2.navigationItemSelectedBackground : 'inherit'};

    button {
      display: ${contentId === idForContentActionsMenu ? 'block' : 'none'};
    }
  `}

  ${({ disabled, isSelectable, theme }) =>
    !disabled
      ? css`
          &:hover {
            background: ${isSelectable ? 'inherit' : theme.colorV2.navigationItemHoverBackground};

            button {
              display: block !important;
            }

            ${StyledMoveIcon} {
              opacity: 1;
              visibility: visible;
            }
          }
        `
      : css`
          margin-left: 2.4rem;
          opacity: 0.5;

          ${ExpandButton} {
            display: none;
          }

          ${NavItem} {
            padding-left: 0.8rem;
            cursor: not-allowed !important;
          }
        `}

    ${({ isSelected }) =>
    isSelected &&
    css`
      ${StyledMoveIcon} {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

export const NavItem = styled.div<{ isSelectable: boolean | undefined; isSelected: boolean | undefined }>`
  ${({ isSelectable, isSelected, theme }) => css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 3.8rem;
    margin-bottom: 0.8rem;
    padding: 0.8rem 0.8rem 0.8rem ${isSelectable ? '0.8rem' : '3rem'};
    transition: border-color ease 0.3s;
    border: solid 0.2rem ${isSelected ? theme.colorV2.accentPrimary : 'transparent'};
    border-radius: ${isSelectable ? '0.8rem' : 'none'};

    &:hover {
      border-color: ${isSelectable ? theme.colorV2.accentPrimary : 'transparent'} !important;
      cursor: pointer;
    }
  `}
`;

export const NavLink = styled(Link)<{ disabled: boolean; isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
  color: black;
  flex: 1;
  font-weight: 700;
  font-size: 1.4rem;
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  ${({ disabled }) => disabled && 'pointer-events: none;'}

  &:hover {
    color: black;
    cursor: pointer;
    text-decoration: none;
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.colorV2.navigationItemText};

      &:hover {
        color: ${theme.colorV2.navigationItemText};
      }
    `}
`;

export const ChildrenLoadingContainer = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 0.5rem;
`;

export const ExpandButton = styled.div<{ expanded: boolean; isActive: boolean; visible: boolean }>`
  position: relative;
  width: 2.2rem;
  transition: all ease 0.5s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  &:before {
    transition: all ease 0.5s;
    ${({ isActive, theme }) => isActive && `color: ${theme.colorV2.navigationItemButtonExpandText};`}
    ${({ expanded }) => expanded && 'transform: rotate(90deg);'}
    font: 2.2rem/2.2rem DXIcons;
    content: '\f04e';
    position: absolute;
    top: 0.8rem;
    left: 0.5rem;
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colorV2.navigationItemButtonExpandText};
  }
`;

export const ContentActionsMenuTrigger = styled.button`
  background: transparent;
  border: none;
  height: 2.1rem;
  padding: 0;
  unset: all;
  width: 2.1rem;
`;

export const StyledCardPublishingStatus = styled(CardPublishingStatus)`
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    height: 1.3rem;
    width: 1rem;
  }

  .base-content__publishing-status__image {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export const StyledUnreadCommentsIcon = styled(UnreadCommentsIcon)`
  ${NavItem}:hover & {
    display: none;
  }
`;

export const StyledMoveIcon = styled(MoveIcon)`
  float: left;
  margin: 0.8rem 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in;

  path {
    fill: ${({ theme }) => theme.colorV2.accentPrimary};
  }
`;
