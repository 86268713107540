import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { CourseSliceInterface } from '@common/interfaces/slices/CourseSliceInterface';
import produce from 'immer';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';
import { AudioUploadActions } from '@actions/AudioUploadActions';
import { GrammarReviewActions } from '@actions/GrammarReviewActions';
import { findLocalizationInSearchedLanguage } from '@features/content';
import { ExerciseTypes } from '@common/enums/ExerciseTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { clone } from '@helpers/clone';
import { type LanguageV2, DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { AudioUploadActionsCreator } from '@actionCreators/AudioUploadActionsCreator';
import { RoleplayActions } from '@actions/RoleplayActions';

const AudioMediaUploadCourseReducers = {
  [AudioUploadActions.UPLOAD_AUDIO_FOR_CONTENT_IN_TRANSLATOR_PANEL_SUCCEEDED]: (
    state: CourseSliceInterface,
    {
      payload: { localization, visitedBranch, mediaURL, row, column, contentType, bundleName, newContentId },
    }: ReturnType<typeof AudioUploadActionsCreator.uploadAudioForContentInTranslatorPanelSucceeded>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    let exerciseType;
    let discoveredBranchData;

    if (contentType === ContentTypes.exercise) {
      exerciseType = clonedState.loadedExercise?.exercise?.type;

      const isHighlighterPhrase =
        exerciseType === ExerciseTypes.highlighter &&
        ['phrase0', 'phrase1', 'phrase2', 'phrase3'].includes(visitedBranch || '');
      if (
        (exerciseType === ExerciseTypes.dialogue && visitedBranch === 'script') ||
        (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') ||
        isHighlighterPhrase
      ) {
        if (exerciseType === ExerciseTypes.dialogue && visitedBranch === 'script') {
          discoveredBranchData = clonedState.loadedExercise.exercise.content[visitedBranch][row as number].line;
          if (newContentId) {
            clonedState.loadedExercise.exercise.content[visitedBranch][row as number].line._id = newContentId;
          }
          clonedState.mediaUploading.audioUploadingInProcess =
            clonedState.mediaUploading.audioUploadingInProcess.filter(
              (item) =>
                item.field !== visitedBranch ||
                (item.field === visitedBranch && item.language !== localization?.language && item.row !== row),
            );
        }
        if (isHighlighterPhrase) {
          discoveredBranchData = clonedState.loadedExercise.exercise.content.phrases[row as number];
          clonedState.loadedExercise.exercise.content.phrasesChanged = true;
          clonedState.loadedExercise.exercise.content.phrases[row as number]._id = newContentId;
          clonedState.loadedExercise.exercise.content.phrases[row as number].changed = true;
          clonedState.mediaUploading.audioUploadingInProcess =
            clonedState.mediaUploading.audioUploadingInProcess.filter(
              (item) =>
                item.field !== visitedBranch ||
                (item.field === visitedBranch && item.language !== localization?.language),
            );
        }

        if (exerciseType === ExerciseTypes.tip && visitedBranch === 'examples') {
          discoveredBranchData =
            clonedState.loadedExercise.exercise.content[visitedBranch][row as number][column as number];
          clonedState.loadedExercise.exercise.content[`${visitedBranch}Changed`] = true;
          clonedState.loadedExercise.exercise.content[visitedBranch][row as number][column as number]._id =
            newContentId;
          clonedState.loadedExercise.exercise.content[visitedBranch][row as number][column as number].changed = true;
          clonedState.mediaUploading.audioUploadingInProcess =
            clonedState.mediaUploading.audioUploadingInProcess.filter(
              (item) =>
                item.field !== visitedBranch ||
                (item.field === visitedBranch && item.language !== localization?.language),
            );
        }
      } else {
        if (bundleName) {
          discoveredBranchData = clonedState.loadedExercise.exercise.content[bundleName][visitedBranch || ''];
          clonedState.loadedExercise.exercise.content[bundleName][visitedBranch || ''].changed = true;
          if (newContentId) {
            clonedState.loadedExercise.exercise.content[bundleName][visitedBranch || '']._id = newContentId;
          }
          clonedState.mediaUploading.audioUploadingInProcess =
            clonedState.mediaUploading.audioUploadingInProcess.filter(
              (item) =>
                item.field !== visitedBranch ||
                (item.field === visitedBranch && item.language !== localization?.language),
            );
        } else {
          discoveredBranchData = clonedState.loadedExercise.exercise.content[visitedBranch || ''];
          clonedState.loadedExercise.exercise.content[visitedBranch || ''].changed = true;
          if (newContentId) {
            clonedState.loadedExercise.exercise.content[visitedBranch || '']._id = newContentId;
          }
          clonedState.mediaUploading.audioUploadingInProcess =
            clonedState.mediaUploading.audioUploadingInProcess.filter(
              (item) =>
                item.field !== visitedBranch ||
                (item.field === visitedBranch && item.language !== localization?.language),
            );
        }
      }
    } else if (contentType === ContentTypes.course) {
      discoveredBranchData = (clonedState.course as any)[visitedBranch || ''];
      clonedState.mediaUploading.audioUploadingInProcess = clonedState.mediaUploading.audioUploadingInProcess.filter(
        (item) =>
          item.field !== visitedBranch || (item.field === visitedBranch && item.language !== localization?.language),
      );
      (clonedState.course as any)[`${visitedBranch}Changed`] = true;
      if (newContentId) {
        (clonedState.course as any)[visitedBranch || '']._id = newContentId;
      }
    } else if (contentType === ContentType.levelOrLesson) {
      discoveredBranchData = (clonedState.selectedGroupsOfParent.parentContents as any)[visitedBranch || ''];
      clonedState.mediaUploading.audioUploadingInProcess = clonedState.mediaUploading.audioUploadingInProcess.filter(
        (item) =>
          item.field !== visitedBranch || (item.field === visitedBranch && item.language !== localization?.language),
      );
      (clonedState.selectedGroupsOfParent.parentContents as any)[`${visitedBranch}Changed`] = true;
      if (newContentId) {
        (clonedState.selectedGroupsOfParent.parentContents as any)[visitedBranch || '']._id = newContentId;
      }
    }

    let foundLocalization = findLocalizationInSearchedLanguage(
      discoveredBranchData.audioLocalizations,
      localization?.language as LanguageV2,
    ) as LocalizationInterface;

    if (!mediaURL) {
      foundLocalization._id = '';
      foundLocalization.value = '';
    } else {
      foundLocalization._id = mediaURL;
      foundLocalization.fileId = mediaURL;
    }

    foundLocalization.type = 'audio';
    foundLocalization.processed = false;

    return clonedState;
  },
  [AudioUploadActions.UPLOAD_AUDIO_SUCCEEDED]: (
    state: CourseSliceInterface,
    { payload }: ReturnType<typeof AudioUploadActionsCreator.uploadAudioSucceeded>,
  ): CourseSliceInterface => {
    const clonedState: CourseSliceInterface = clone(state);

    let { visitedBranch, row, mediaURL, forLearningLang, localization, bundleName, content } = payload;

    if (bundleName && clonedState.loadedExercise.exercise.content[bundleName][visitedBranch] === null) {
      clonedState.loadedExercise.exercise.content[bundleName][visitedBranch] =
        ExerciseDataModelAssembler.generateTranslationsPanelContentPopulatedWithEmptyLocalizations();
    }

    if (clonedState.loadedExercise.exercise.type === ExerciseTypes.dialogue && visitedBranch === 'script') {
      row = row as number;
      let defaultLocalization: LocalizationInterface = findLocalizationInSearchedLanguage(
        clonedState.loadedExercise.exercise.content['script'][row].line.audioLocalizations,
        clonedState.course.learningLanguage as LanguageV2,
      ) as LocalizationInterface;

      defaultLocalization._id = mediaURL;
      defaultLocalization.processed = false;
      clonedState.loadedExercise.exercise.content['script'][row].line._id = content.id;
      clonedState.loadedExercise.exercise.content.scriptChanged = true;
      clonedState.mediaUploading.audioUploadingInProcess = clonedState.mediaUploading.audioUploadingInProcess.filter(
        (item) =>
          item.field !== visitedBranch ||
          (item.field === visitedBranch && item.language !== localization?.language && item.row !== row),
      );

      return clonedState;
    } else {
      let defaultLocalization: LocalizationInterface;

      if (bundleName) {
        defaultLocalization = findLocalizationInSearchedLanguage(
          clonedState.loadedExercise.exercise.content[bundleName][visitedBranch].audioLocalizations,
          forLearningLang ? (clonedState.course.learningLanguage as LanguageV2) : DEFAULT_LANGUAGE_V2,
        ) as LocalizationInterface;

        clonedState.loadedExercise.exercise.content[bundleName][visitedBranch].changed = true;
        defaultLocalization._id = mediaURL;
        defaultLocalization.fileId = mediaURL;
        defaultLocalization.processed = false;
        clonedState.mediaUploading.audioUploadingInProcess = clonedState.mediaUploading.audioUploadingInProcess.filter(
          (item) =>
            item.field !== visitedBranch ||
            (item.field === visitedBranch &&
              (forLearningLang
                ? item.language !== clonedState.course.learningLanguage
                : item.language !== localization?.language)),
        );
        clonedState.loadedExercise.exercise.content[bundleName][visitedBranch]._id = content.id;

        return clonedState;
      } else {
        defaultLocalization = findLocalizationInSearchedLanguage(
          clonedState.loadedExercise.exercise.content[visitedBranch].audioLocalizations,
          forLearningLang ? (clonedState.course.learningLanguage as LanguageV2) : DEFAULT_LANGUAGE_V2,
        ) as LocalizationInterface;

        clonedState.loadedExercise.exercise.content[visitedBranch].changed = true;
        defaultLocalization._id = mediaURL;
        defaultLocalization.processed = false;
        clonedState.mediaUploading.audioUploadingInProcess = clonedState.mediaUploading.audioUploadingInProcess.filter(
          (item) =>
            item.field !== visitedBranch ||
            (item.field === visitedBranch &&
              (forLearningLang
                ? item.language !== clonedState.course.learningLanguage
                : item.language !== localization?.language)),
        );
        clonedState.loadedExercise.exercise.content[visitedBranch]._id = content.id;

        return clonedState;
      }
    }
  },
  [GrammarReviewActions.SET_AUDIO]: (
    state: CourseSliceInterface,
    {
      payload: { localization, fieldName },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: string;
    }>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      draft.mediaUploading.audioUploadingInProcess = draft.mediaUploading.audioUploadingInProcess.filter(
        (item) => item.field !== fieldName || (item.field === fieldName && item.language !== localization.language),
      );
    });
  },
  [RoleplayActions.SET_AUDIO]: (
    state: CourseSliceInterface,
    {
      payload: { localization, fieldName },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: string;
    }>,
  ): CourseSliceInterface => {
    return produce(state, (draft) => {
      draft.mediaUploading.audioUploadingInProcess = draft.mediaUploading.audioUploadingInProcess.filter(
        (item) => item.field !== fieldName || (item.field === fieldName && item.language !== localization.language),
      );
    });
  },
};

export default AudioMediaUploadCourseReducers;
