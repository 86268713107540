import { Text } from '@features/theme';

import { NoDataMessageCell, NoDataMessageRow } from './styles';

export const NoDataMessage = () => (
  <NoDataMessageRow>
    <NoDataMessageCell>
      <Text level={3} spaceBottom="S" variant="header">
        Nothing to export
      </Text>
      <Text level={3} variant="body">
        The export strings are either missing or unavailable for editing
      </Text>
    </NoDataMessageCell>
  </NoDataMessageRow>
);
