const FEATURES = [
  'freezeRoleplayCategories',
  'hideAddToVocabularyToogle',
  'live-lessons',
  'publication',
  'reusingInNewLocation',
  'selectStringsDownloadTranslationRequest',
  'smartling',
  'speakingBites',
  'tooltip',
  'vocabularyReview',
  'vpn',
] as const;
export type Feature = (typeof FEATURES)[number];

const isOnVpn = window.location.origin.includes(process.env.REACT_APP_VPN_DOMAIN || 'logos.busuu.net');

const runtimeFeatureFlags = JSON.parse(localStorage.getItem('feature-flags') || '{}');
const featureFlags: { [key in Feature]: boolean } = {
  freezeRoleplayCategories: process.env.REACT_APP_FREEZE_ROLEPLAY_CATEGORIES === 'true',
  hideAddToVocabularyToogle: process.env.REACT_APP_HIDE_ADD_TO_VOCABULARY_TOOGLE === 'true',
  'live-lessons': process.env.REACT_APP_LIVE_LESSONS_ENABLED === 'true',
  publication: isOnVpn,
  reusingInNewLocation: process.env.REACT_APP_REUSING_IN_NEW_LOCATION_ENABLED === 'true',
  selectStringsDownloadTranslationRequest: process.env.REACT_APP_SELECT_STRINGS_DOWNLOAD_TRANSLATION_REQUEST === 'true',
  smartling: process.env.REACT_APP_SMARTLING === 'true',
  speakingBites: process.env.REACT_APP_SPEAKING_BITES === 'true',
  tooltip: true,
  vocabularyReview: process.env.REACT_APP_VOCABULARY_REVIEW_ENABLED === 'true',
  vpn: isOnVpn,
};

document.body.dataset.featureFlags = JSON.stringify(
  Object.fromEntries(
    FEATURES.map((feature) => [feature, { original: featureFlags[feature], runtime: runtimeFeatureFlags[feature] }]),
  ),
);

export const isFeatureEnabled = (feature: Feature) => runtimeFeatureFlags[feature] ?? featureFlags[feature];
