import styled, { css } from 'styled-components/macro';

import { SearchBox } from '@components/Search';

export const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.M};
`;

export const StyledSearchBox = styled(SearchBox)`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.uiBackgroundSecondary};
    height: 4rem;
    margin-right: ${theme.space.S};
    padding-left: ${theme.space.S} !important;
    width: 42rem;

    input {
      background-color: ${theme.colorV2.uiBackgroundSecondary} !important;

      ::placeholder {
        font-weight: 700;
      }
    }
  `};
`;

export const StringsWithPendingTranslationsFilter = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
  `}
`;
