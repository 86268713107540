import { uniqBy } from 'lodash';
import { ContentTypesType } from '@common/enums/ContentTypes';

import { NavigationItemType } from '../types';
import { DBId } from '@common/types/DBId';

type GetNavigationWithChildrenArgs = {
  newNavigationStructure: NavigationItemType[];
  parentContentType: ContentTypesType;
  parentId: DBId | null;
  rootItemId: DBId;
  getChildrenArray: (type: ContentTypesType, id: DBId) => Promise<NavigationItemType[] | never[]>;
};

/** Get an updated navigation with the target content children  */
const getNavigationWithChildren = async ({
  newNavigationStructure,
  parentContentType,
  parentId,
  rootItemId,
  getChildrenArray,
}: GetNavigationWithChildrenArgs) => {
  // if no parentId is provided, use the rootItemId
  const targetParentChildren = await getChildrenArray(parentContentType, parentId ?? rootItemId);

  // set a duplicate-free final navigation structure
  const navigationStructure = uniqBy([...newNavigationStructure, ...targetParentChildren], 'id');

  return navigationStructure;
};

export default getNavigationWithChildren;
