import { ContentType, ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import { ACTIVITIES, type ActivityType } from '@features/content/activities';
import type { NavigationItemType, NavigationItemFromBackType } from '@features/content/navigation';
import NavigationService from '@services/NavigationService';
import { getLearningLanguageFromRoleplayId, RoleplayService } from '@features/content/roleplay';
import { LanguageV2 } from '../languages';

export const MoveContentService = {
  async getAllNavigation(
    courseElement: NavigationItemType,
    currentPathIds: (string | null | undefined)[],
    selectedContentParentId: string | null | undefined,
  ) {
    const [courseId, levelId, chapterId, lessonId, activityId] = currentPathIds;
    let levels = [];
    let chapters = [];
    let lessons = [];
    let activities = [];
    let exercises = [];
    let childrensLength = 0;

    if (courseId) {
      const courseNavigationResult = await NavigationService.getCourseNavigation(courseId);
      levels =
        courseNavigationResult.status === 200
          ? courseNavigationResult.data.levels.map((level: NavigationItemType) => ({
              ...level,
              parentId: courseId,
              type: ContentTypes.level,
            }))
          : [];
      childrensLength += levels.length;
    }

    if (levelId) {
      const levelNavigationResult = await NavigationService.getLevelNavigation(levelId);
      chapters =
        levelNavigationResult.status === 200
          ? levelNavigationResult.data.chapters.map((chapter: NavigationItemType) => ({
              ...chapter,
              parentId: levelId,
              type: ContentTypes.chapter,
            }))
          : [];
      childrensLength += chapters.length;
    }

    if (chapterId) {
      const chapterNavigationResult = await NavigationService.getChapterNavigation(chapterId);
      lessons =
        chapterNavigationResult.status === 200
          ? chapterNavigationResult.data.lessons.map((lesson: NavigationItemType) => ({
              ...lesson,
              parentId: chapterId,
            }))
          : [];
      childrensLength += lessons.length;
    }

    if (lessonId) {
      const lessonNavigationResult = await NavigationService.getLessonNavigation(lessonId);
      activities =
        lessonNavigationResult.status === 200
          ? lessonNavigationResult.data.activities.map((activity: NavigationItemType) => ({
              ...activity,
              parentId: lessonId,
            }))
          : [];
      childrensLength += activities.length;

      const liveLessonNavigationResult = await NavigationService.getLiveLessonNavigation(lessonId);
      exercises =
        liveLessonNavigationResult.status === 200
          ? liveLessonNavigationResult.data.exercises.map((activity: NavigationItemType) => ({
              ...activity,
              parentId: lessonId,
            }))
          : [];
      childrensLength += exercises.length;
    }

    if (activityId) {
      const activityNavigationResult = await NavigationService.getActivityNavigation(activityId);
      exercises =
        activityNavigationResult.status === 200
          ? activityNavigationResult.data.exercises.map((exercise: NavigationItemType) => ({
              ...exercise,
              parentId: activityId,
            }))
          : [];
      childrensLength += exercises.length;
    }

    if (childrensLength > 0) {
      return [
        {
          id: courseId,
          title: courseElement.title,
          type: ContentTypes.course,
          children: !!levelId || !!levels.length,
          ready: courseElement.ready,
          parentId: null,
          expanded: true,
        },
        ...levels.map((level: NavigationItemType) => ({
          ...level,
          expanded: level.id === levelId && level.id !== selectedContentParentId,
        })),
        ...chapters.map((chapter: NavigationItemType) => ({
          ...chapter,
          expanded: chapter.id === chapterId && chapterId !== selectedContentParentId,
        })),
        ...lessons.map((lesson: NavigationItemType) => ({
          ...lesson,
          expanded: lesson.id === lessonId && lesson.id !== selectedContentParentId,
          type: lesson.type === 'speaking' ? ContentTypes.speakingLesson : lesson.type,
        })),
        ...activities.map((activity: NavigationItemType) => ({
          ...activity,
          expanded: activity.id === activityId && activity.id !== selectedContentParentId,
          type: activity.type === 'speaking' ? ContentTypes.speakingActivity : activity.type,
        })),
        ...exercises,
      ];
    } else {
      return [];
    }
  },
  async getChildren(type: ContentTypesType, id: DBId, currentPathIds: (string | null | undefined)[]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [courseId, levelId, chapterId, lessonId, activityId] = currentPathIds;
    let children;

    switch (type) {
      case ContentTypes.course:
        const courseNavigationResult = await NavigationService.getCourseNavigation(id);
        children =
          courseNavigationResult.status === 200
            ? courseNavigationResult.data.levels.map((level: NavigationItemFromBackType) => ({
                ...level,
                parentId: id,
                type: ContentTypes.level,
                expanded: levelId === level.id,
              }))
            : [];
        break;

      case ContentTypes.level:
        const levelNavigationResult = await NavigationService.getLevelNavigation(id);
        children =
          levelNavigationResult.status === 200
            ? levelNavigationResult.data.chapters.map((chapter: NavigationItemFromBackType) => ({
                ...chapter,
                parentId: id,
                type: ContentTypes.chapter,
                expanded: chapterId === chapter.id,
              }))
            : [];
        break;

      case ContentTypes.chapter:
        const chapterNavigationResult = await NavigationService.getChapterNavigation(id);
        children =
          chapterNavigationResult.status === 200
            ? chapterNavigationResult.data.lessons.map((lesson: NavigationItemFromBackType) => ({
                ...lesson,
                parentId: id,
                expanded: lessonId === lesson.id,
                type: lesson.type === 'speaking' ? ContentTypes.speakingLesson : lesson.type,
              }))
            : [];
        break;

      case ContentTypes.lesson:
      case ContentTypes.certificate:
      case ContentTypes.review:
      case ContentTypes.speakingLesson:
        const lessonNavigationResult = await NavigationService.getLessonNavigation(id);
        children =
          lessonNavigationResult.status === 200
            ? lessonNavigationResult.data.activities.map((activity: NavigationItemFromBackType) => ({
                ...activity,
                parentId: id,
                expanded: activityId === activity.id,
                type: activity.type === 'speaking' ? ContentTypes.speakingActivity : activity.type,
              }))
            : [];
        break;

      case ContentTypes.liveLesson:
        const liveLessonNavigationResult = await NavigationService.getLiveLessonNavigation(id);
        children =
          liveLessonNavigationResult.status === 200
            ? liveLessonNavigationResult.data.exercises.map((exercise: NavigationItemFromBackType) => ({
                ...exercise,
                parentId: id,
              }))
            : [];
        break;

      default:
        if (ACTIVITIES.includes(type as ActivityType)) {
          const activityNavigationResult = await NavigationService.getActivityNavigation(id);
          children =
            activityNavigationResult.status === 200
              ? activityNavigationResult.data.exercises.map((exercise: NavigationItemFromBackType) => ({
                  ...exercise,
                  parentId: id,
                }))
              : [];
        }
        return [];
    }

    return children;
  },
  async getAllRoleplayNavigation(
    roleplayElement: NavigationItemType,
    currentPathIds: (string | null | undefined)[],
    selectedContentParentId: string | null | undefined,
  ) {
    const [roleplayId, roleplayCategoryId] = currentPathIds;
    let categories = [];
    let scenarios = [];
    let childrensLength = 0;

    if (roleplayId) {
      const languageId = getLearningLanguageFromRoleplayId(roleplayId);
      const roleplayCategoriesResult = await RoleplayService.getRoleplayNavigation(languageId);
      categories =
        roleplayCategoriesResult.status === 200
          ? roleplayCategoriesResult.data.roleplayCategories.map((category: NavigationItemType) => ({
              ...category,
              parentId: roleplayId,
              type: ContentTypes.roleplayCategory,
            }))
          : [];

      childrensLength += categories.length;
    }

    if (roleplayCategoryId) {
      const roleplayScenariosResult = await RoleplayService.getRoleplayCategoryNavigation(roleplayCategoryId);
      scenarios =
        roleplayScenariosResult.status === 200
          ? roleplayScenariosResult.data.roleplayScenarios.map((scenario: NavigationItemType) => ({
              ...scenario,
              parentId: roleplayCategoryId,
              type: ContentTypes.roleplayScenario,
            }))
          : [];

      childrensLength += scenarios.length;
    }

    if (childrensLength > 0) {
      return [
        {
          id: roleplayId,
          title: roleplayElement.title,
          type: ContentTypes.roleplay,
          children: !!roleplayCategoryId || !!categories.length,
          ready: roleplayElement.ready,
          parentId: null,
          expanded: true,
        },
        ...categories.map((category: NavigationItemType) => ({
          ...category,
          expanded: category.id === roleplayCategoryId && roleplayCategoryId !== selectedContentParentId,
        })),
        ...scenarios,
      ];
    } else {
      return [];
    }
  },
  async getRoleplayChildren(type: ContentTypesType, id: DBId, currentPathIds: (string | null | undefined)[]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [roleplayId, roleplayCategoryId] = currentPathIds;
    let children;

    switch (type) {
      case ContentTypes.roleplay:
        const roleplayCategoriesResult = await RoleplayService.getRoleplayNavigation(id as LanguageV2);
        children =
          roleplayCategoriesResult.status === 200
            ? roleplayCategoriesResult.data.roleplayCategories.map((category: NavigationItemFromBackType) => ({
                ...category,
                parentId: id,
                expanded: roleplayCategoryId === category.id,
              }))
            : [];
        break;
      case ContentTypes.roleplayCategory:
        const roleplayScenariosResult = await RoleplayService.getRoleplayCategoryNavigation(id);
        children =
          roleplayScenariosResult.status === 200
            ? roleplayScenariosResult.data.roleplayScenarios.map((scenario: NavigationItemFromBackType) => ({
                ...scenario,
                parentId: id,
              }))
            : [];
        break;
      default:
        return [];
    }

    return children;
  },
  async moveGroup(id: DBId, currentParentId: DBId, newParentId: DBId) {
    return await apiClient.noErrorsV2.post(`/content/groups/${id}/move`, { currentParentId, newParentId });
  },
  async moveExercise(id: DBId, currentParentId: DBId, newParentId: DBId) {
    const moveExercisePayload = {
      currentParentId,
      currentParentType: ContentType.group,
      newParentId,
      newParentType: ContentType.group,
    };

    return await apiClient.noErrorsV2.post(`/content/exercises/${id}/move`, moveExercisePayload);
  },
  async moveScenario(id: DBId, currentParentId: DBId, newParentId: DBId) {
    return await apiClient.noErrorsV2.post(`/content/roleplay-scenarios/${id}/move`, { currentParentId, newParentId });
  },
};
