import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { PlacementTestService } from '@services/PlacementTestService';
import { NavigationItem } from './NavigationItem';
import { NavigationContainer, NavigationItemType } from '@features/content/navigation';
import { Loader } from '@features/theme';
import { PlacementTestActionCreators } from '@actionCreators/PlacementTestActionCreators';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectPlacementTest,
  selectPlacementTestNavigationLoadingParentId,
  selectPlacementTestNavigationStructure,
} from '@selectors/PlacementTestSelectors';

type NavigationItemFromBackType = {
  id: DBId;
  title: string | null;
  children: boolean;
  ready: boolean;
  experiment?: boolean;
  parentId?: DBId;
};

/** @deprecated Use NavigationItemV2 from features/content/navigation instead */
export const Navigation = () => {
  const dispatch = useAppDispatch();
  const { courseId, entrypointId, exerciseId } = useParams<{
    courseId: DBId;
    entrypointId: DBId;
    exerciseId: DBId;
  }>();
  const currentPathIds = [courseId, entrypointId, exerciseId];

  const navigationStructure = useAppSelector(selectPlacementTestNavigationStructure);
  const loadingParentId = useAppSelector(selectPlacementTestNavigationLoadingParentId);

  const placementTest = useAppSelector(selectPlacementTest);

  const getChildrenArray = (type: ContentTypesType, id: DBId) => {
    if (!navigationStructure.map((content) => content.parentId).includes(id)) {
      dispatch(PlacementTestActionCreators.setLoadingParentId(id));
      switch (type) {
        case ContentTypes.placementTest:
          return PlacementTestService.getPlacementTestNavigation(id).then((result) =>
            result.data.entrypoints.map((enrypoint: NavigationItemFromBackType) => ({
              ...enrypoint,
              parentId: id,
              type: ContentTypes.entrypoint,
              expanded: entrypointId === enrypoint.id,
            })),
          );
        case ContentTypes.entrypoint:
          return PlacementTestService.getEntrypointNavigation(courseId, id).then((result) =>
            result.data.exercises.map((exercise: NavigationItemFromBackType) => ({
              ...exercise,
              parentId: id,
            })),
          );
        default:
          return [];
      }
    }
    return [];
  };

  const getChildren = async (type: ContentTypesType, id: DBId) => {
    const childrenArray = await getChildrenArray(type, id);
    dispatch(
      PlacementTestActionCreators.updateNavigation([
        ...navigationStructure,
        ...(childrenArray as NavigationItemType[]),
      ]),
    );
    dispatch(PlacementTestActionCreators.setLoadingParentId(''));
  };

  useEffect(() => {
    if (placementTest.content.id) {
      dispatch(PlacementTestActionCreators.getAllNavigation(courseId, entrypointId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, placementTest.content.id]);

  const placementTestElement = navigationStructure.find((content) => content.type === ContentTypes.placementTest);

  if (!placementTestElement) {
    return <Loader />;
  }

  return (
    <NavigationContainer isDisabled={!!loadingParentId} countTopButtons={1}>
      {placementTestElement && (
        <NavigationItem
          content={placementTestElement}
          structure={navigationStructure}
          onExpand={getChildren}
          currentPathIds={currentPathIds}
          loadingParentId={loadingParentId}
        />
      )}
    </NavigationContainer>
  );
};
