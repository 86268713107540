import { ContentTypes } from '@common/enums/ContentTypes';
import type { NavigationItemType } from '../types';

const isRoleplay = (contentType: NavigationItemType['type']) =>
  (
    [
      ContentTypes.roleplay,
      ContentTypes.roleplayCategory,
      ContentTypes.roleplayScenario,
    ] as NavigationItemType['type'][]
  ).includes(contentType);

export default isRoleplay;
