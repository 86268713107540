import { useEffect, useMemo } from 'react';

import { CourseInfoInterface } from '@common/interfaces/courses/CourseInfoInterface';
import { LoadingStage } from '@common/enums/LoadingStage';
import { LANGUAGE_NAMES_V2 } from '@features/content/languages';

import { coursesInfoRequest } from '@actionCreators/CourseActionCreator';
import { isFeatureEnabled } from '@helpers/featuresHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCoursesInfo } from '@selectors/CoursesSelectors';

import { DashboardTableItemsInterface } from '../../types';
import { DashboardTable } from './DashboardTable';

export const DashboardTableWrapper = () => {
  const dispatch = useAppDispatch();
  const { loaded, courses } = useAppSelector(selectCoursesInfo);

  const mapCoursesToTableItems = (courses: CourseInfoInterface[]): DashboardTableItemsInterface[] => {
    const tableItems: DashboardTableItemsInterface[] = [];

    courses.forEach((course) => {
      tableItems.push({
        ...course,
        itemType: 'course',
      });

      if (course.complete) {
        tableItems.push({
          ...course,
          itemType: 'grammar',
          title: `${LANGUAGE_NAMES_V2[course.learningLanguage]} Grammar Review`,
        });

        if (course.placementTest) {
          tableItems.push({
            ...course,
            itemType: 'placementTest',
            title: `${LANGUAGE_NAMES_V2[course.learningLanguage]} Placement Test`,
          });
        }

        tableItems.push({
          ...course,
          itemType: 'roleplay',
          title: `${LANGUAGE_NAMES_V2[course.learningLanguage]} Roleplay`,
        });

        if (isFeatureEnabled('vocabularyReview')) {
          tableItems.push({
            ...course,
            itemType: 'vocabularyReview',
            title: `${LANGUAGE_NAMES_V2[course.learningLanguage]} Vocabulary Review`,
          });
        }
      }
    });

    return tableItems;
  };

  const tableItems = useMemo(() => {
    if (loaded === LoadingStage.loaded) {
      return mapCoursesToTableItems(courses);
    }

    return [];
  }, [courses, loaded]);

  useEffect(() => {
    if (loaded === LoadingStage.notLoaded) {
      dispatch(coursesInfoRequest());
    }
  }, [loaded, dispatch]);

  return <DashboardTable loaded={loaded} items={tableItems} />;
};
