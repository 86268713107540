import { ContentTypes } from '@common/enums/ContentTypes';

import type { NavigationItemType } from '../types';

const isValidTargetToMoveContent = ({
  content,
  selectedContent,
  structure,
}: {
  content: NavigationItemType;
  selectedContent?: NavigationItemType | null;
  structure: NavigationItemType[];
}) => {
  const selectedContentParent = structure?.find(({ id }) => id === selectedContent?.parentId);

  if (
    !content ||
    !selectedContentParent ||
    content.id === selectedContentParent.id ||
    content.type === ContentTypes.roleplay
  )
    return false;

  return content.type === selectedContentParent.type;
};

export default isValidTargetToMoveContent;
