import type { DBId } from '@common/types/DBId';
import { LanguageV2 } from '@features/content/languages';

const ROLEPLAY_ID_PREFIX = 'roleplay_';

/**
 * Helper to extract the learning language from a Roleplay id
 * Roleplay id is stored in DB but we use learning language in API calls
 * Example: roleplay_en -> en
 **/
export const getLearningLanguageFromRoleplayId = (roleplayId: DBId) =>
  roleplayId.replace(ROLEPLAY_ID_PREFIX, '') as LanguageV2;
