import Color from 'color';

// @TODO add colorv2 alternative for deprecated orphan colors when defined by Product Design

const legacyColors = {
  /** @deprecated */
  pink1: '#FABADA',
  /** @deprecated */
  pink2: '#F7C7CA',
  /** @deprecated (ref: palette yellow100) */
  orange: '#FFCA3A',
  /** @deprecated */
  light1: '#E7F0FD',
  /** @deprecated */
  light2: '#F7F9FC',
  /** @deprecated (ref: palette green100) */
  primaryLighter: '#11EE92',
  /** @deprecated (ref: palette green120) */
  primaryGreen4: '#0EBE75',
  /** @deprecated (ref: palette red100) */
  secondaryBase: '#E2222E',
  /** @deprecated */
  secondary1: '#FDE9EB',
  /** @deprecated */
  secondary3: '#FDF4F4',
  /** @deprecated */
  quaternaryLight2: '#F6FAFD',
  /** @deprecated */
  quaternaryLight3: '#E8F1FE',
  /** @deprecated */
  quaternaryBase: '#D6DEE6',
  /** @deprecated (ref: palette darkBlue25) */
  quaternaryDark: '#A7B0B7',
};

// Reference: https://www.figma.com/file/1Kn1cPXOBtH4Mb1WFRlMeC/Logos-Guideline?type=design&node-id=2075-18679&t=SWErKKlW6JVzOOmY-0
const palette = {
  black: '#000000',
  black65: '#6D7783',
  black80: '#4B5766',
  black100: '#1E2D40',
  blue5: '#F3F8FE',
  blue10: '#E7F0FD',
  blue25: '#C3DBFB',
  blue80: '#418BF1',
  blue100: '#116EEE',
  blue120: '#0E58BE',
  darkBlue5: '#F3F5F8',
  darkBlue15: '#DAE1EA',
  darkBlue25: '#A7B0B7',
  green10: '#E8FEF5',
  green25: '#C3FBE4',
  green50: '#88F7C8',
  green100: '#11EE92',
  green120: '#0EBE75',
  purple10: '#EEECFC',
  purple80: '#7660E3',
  purple100: '#5438DC',
  purple120: '#432DB0',
  red10: '#FDE9EB',
  red25: '#F8C8CB',
  red50: '#F19097',
  red80: '#E84E58',
  red100: '#E2222E',
  white: '#FFFFFF',
  yellow10: '#FFFAEC',
  yellow25: '#FFF2CE',
  yellow50: '#FFFAEB',
  yellow100: '#FFCA3A',
} as const;

export const defaultTheme = {
  color: {
    ...legacyColors,
    /** @deprecated Use colorv2.uiDark instead */
    brandBlack: '#252B2F',
    /** @deprecated (ref: palette blue5) */
    brandBlue5: '#F3F8FE',
    /** @deprecated */
    brandGreyscale500: '#F2F7FD',
    /** @deprecated */
    brandGreyscale600: '#D6DEE6',
    /** @deprecated */
    brandGreyscale800: '#666E7E',
    /** @deprecated */
    brandPrimary30: '#B8D4FA',
    /** @deprecated */
    brandPrimary50: '#F3F7FE',
    /** @deprecated */
    brandPrimary500: '#87B6F6',
    /** @deprecated Use colorv2.accentPrimary instead */
    brandPrimaryBase: '#116EEE',
    /** @deprecated Use colorv2.uiLightest instead */
    brandWhite: '#FFFFFF',
    /** @deprecated (ref: palette darkBlue15) */
    darkBlue15: '#DAE1EA',
    /** @deprecated Use colorv2.uiDark instead */
    deepBlue: '#1E2D40',
    /** @deprecated */
    deepBlue65: '#6D7783',
    /** @deprecated (ref: palette blue80) */
    deepBlue80: '#4B5766',
    /** @deprecated */
    quaternaryLight2: '#F6FAFD',
    /** @deprecated Use colorv2.accentPremium instead */
    purple100: '#5438DC',
  } as const,
  colorV2: {
    accentPremium: palette.purple100,
    accentPremiumLight: palette.purple10,
    accentPrimary: palette.blue100,
    accentSecondary: palette.green100,

    baseEditorContainerBorder: palette.darkBlue15,
    baseEditorEmojiSelectorBackground: palette.white,
    baseEditorEmojiSelectorBorder: palette.darkBlue15,
    baseEditorMentionBackground: palette.blue10,
    baseEditorMentionColor: palette.blue100,
    baseEditorMentionsMenuBackground: palette.white,
    baseEditorMentionsMenuBorder: palette.darkBlue15,
    baseEditorMentionsMenuItemBackground: palette.blue5,
    baseEditorPlaceholderColor: palette.black65,
    baseEditorToolbarBackground: palette.blue5,
    baseEditorToolbarBorder: palette.darkBlue15,
    baseEditorToolbarButtonActiveBorder: palette.black100,
    baseEditorToolbarSeparator: palette.darkBlue25,

    breadcrumbsText: palette.blue100,

    buttonPrimaryBaseBackground: palette.blue100,
    buttonPrimaryBaseBorder: palette.blue100,
    buttonPrimaryBaseText: palette.white,
    buttonPrimaryHoverBackground: palette.blue80,
    buttonPrimaryHoverBorder: palette.blue80,
    buttonPrimaryHoverText: palette.white,
    buttonPrimaryPressedBackground: palette.blue120,
    buttomPrimaryPressedBorder: palette.blue120,
    buttonPrimaryPressedText: palette.white,

    buttonSecondaryBaseBackground: palette.white,
    buttonSecondaryBaseBorder: palette.blue100,
    buttonSecondaryBaseText: palette.blue100,
    buttonSecondaryHoverBackground: palette.blue100,
    buttonSecondaryHoverBorder: palette.blue100,
    buttonSecondaryHoverText: palette.white,
    buttonSecondaryPressedBackground: palette.blue120,
    buttonSecondaryPressedBorder: palette.blue120,
    buttonSecondaryPressedText: palette.white,

    buttonTertiaryBaseBackground: palette.white,
    buttonTertiaryBaseBorder: palette.darkBlue15,
    buttonTertiaryBaseText: palette.black80,
    buttonTertiaryHoverBackground: palette.white,
    buttonTertiaryHoverBorder: palette.blue100,
    buttonTertiaryHoverText: palette.blue100,
    buttonTertiaryPressedBackground: palette.white,
    buttonTertiaryPressedBorder: palette.blue120,
    buttonTertiaryPressedText: palette.blue120,

    buttonOnlyTextBaseText: palette.blue100,
    buttonOnlyTextPressedText: palette.blue120,

    creatableSearchableSelectorControlBaseBackground: palette.white,
    creatableSearchableSelectorControlFocusedBackground: palette.blue100,
    creatableSearchableSelectorControlBaseBorder: palette.darkBlue15,
    creatableSearchableSelectorControlBaseText: palette.black100,
    creatableSearchableSelectorControlFocusedText: palette.white,
    creatableSearchableSelectorClearIndicatorBaseColor: palette.black65,
    creatableSearchableSelectorClearIndicatorFocusedColor: palette.white,
    creatableSearchableSelectorCreateOptionColor: palette.blue100,
    creatableSearchableSelectorDropdownIndicatorBaseColor: palette.black100,
    creatableSearchableSelectorDropdownIndicatorFocusedColor: palette.white,
    creatableSearchableSelectorMenuBorder: palette.darkBlue15,
    creatableSearchableSelectorOptionBaseBackground: palette.white,
    creatableSearchableSelectorOptionFocusedBackground: palette.blue5,
    creatableSearchableSelectorPlaceholderBaseText: palette.black65,
    creatableSearchableSelectorPlaceholderFocusedText: palette.white,

    commentEditorActiveBorder: palette.blue100,
    commentEditorCollapsedBorder: palette.darkBlue15,
    commentEditorMentionBackground: palette.blue5,
    commentItemButtonCancelText: palette.black65,
    commentItemButtonConfirmText: palette.blue100,
    commentItemButtonShowMoreText: palette.black80,
    commentItemConfirmDeleteBackground: palette.blue5,
    commentItemConfirmDeleteText: palette.blue100,
    commentItemHoverBackground: palette.blue5,
    commentItemText: palette.black100,
    commentItemMetadataText: palette.black65,
    commentsPanelBackground: palette.white,

    contentMappingsDescriptionText: palette.black65,

    dropdown: {
      border: palette.darkBlue15,
    },

    errorPageDescriptionText: palette.black100,

    filterInputBackground: palette.blue5,
    filterInputBaseBorder: palette.darkBlue15,
    filterInputFocusedBorder: palette.blue100,
    filterInputColor: palette.black65,

    grammarCategoryIconsSelectorFooterBackground: palette.white,
    grammarCategoryIconsSelectorFooterShadow: palette.darkBlue15,
    grammarCategoryIconsSelectorContainerSelected: palette.blue100,
    grammarCategoryIconsSelectorContainerHover: palette.blue5,
    grammarCategoryIconsSelectorButtonCloseBackground: palette.white,
    grammarCategoryIconsSelectorButtonCloseBorder: palette.darkBlue15,
    grammarCategoryIconsSelectorButtonCloseText: palette.blue80,
    grammarCategoryIconsSelectorButtonCloseHoverBackground: palette.blue5,
    grammarCategoryIconsSelectorButtonSaveText: palette.blue100,
    grammarCategoryIconsSelectorSelectedIconBorder: palette.darkBlue15,
    grammarCategoryIconsSelectorSelectedIconButtonEditBackground: palette.white,
    grammarCategoryIconsSelectorSelectedIconButtonEditHoverBackground: palette.blue5,
    grammarCategoryIconsSelectorSelectedIconButtonEditBorder: palette.darkBlue15,
    grammarCategoryIconsSelectorSelectedIconButtonEditText: palette.darkBlue15,
    grammarCategorySearchableSelectorErrorText: palette.red100,

    grammarReviewButtonHoverText: palette.blue120,

    grammarTopicChildrenTitleText: palette.black65,
    grammarTopicLabelContainerBackground: palette.blue100,
    grammarTopicLabelContainerText: palette.white,
    grammarTopicLinkedLessonCardTitleText: palette.black100,
    grammarTopicLinkedLessonCardDescription: palette.black65,
    grammarTopicLinkedLessonsButtonBackground: palette.blue100,
    grammarTopicLinkedLessonsButtonText: palette.white,
    grammarTopicLinkedLessonsModalTitleText: palette.black100,
    grammarTopicLinkedLessonsModalLevelHoverBackground: palette.darkBlue15,
    grammarTopicLinkedLessonsModalLevelSelectedHoverBackground: palette.blue5,
    grammarTopicLinkedLessonsModalLevelSelectedHoverText: palette.blue100,
    grammarTopicLinkedLessonsModalSearchBackground: palette.blue5,
    grammarTopicLinkedLessonsModalSearchBorder: palette.darkBlue15,
    grammarTopicLinkedLessonsListText: palette.black65,

    headerBackground: palette.white,
    headerText: palette.black65,
    headerSaveButtonText: palette.blue100,
    headerSaveButtonBorder: palette.blue100,

    input: {
      background: palette.white,
      border: {
        active: palette.blue100,
        default: palette.darkBlue15,
      },
      color: {
        default: palette.black100,
        placeholder: palette.black65,
      },
    },

    navigationBackground: palette.blue5,
    navigationButtonBackground: palette.blue5,
    navigationButtonHoverBackground: palette.blue10,
    navigationButtonHoverText: palette.blue120,

    navigationItemHoverBackground: palette.blue10,
    navigationItemSelectedBackground: palette.blue10,
    navigationItemButtonExpandText: palette.blue100,
    navigationItemText: palette.blue100,

    newMark: {
      background: palette.green120,
    },

    optionBackgroundActive: palette.blue25,

    otherLocationsItemHoverBackground: palette.blue5,
    otherLocationsItemText: palette.blue100,
    otherLocationsListHeaderText: palette.black65,

    paginationButtonBaseColor: palette.black65,
    pagitationButtonSelectedColor: palette.blue100,
    paginationButtonHoverBaseBackground: palette.white,
    paginationButtonHoverFocusedBackground: palette.blue100,

    placementTestCardDescriptionText: palette.black65,
    placementTestCardExerciseDistractorBackground: palette.darkBlue25,
    placementTestCardImageContainerBackground: palette.blue5,
    placementTestCardActiveBackground: palette.blue10,
    placementTestCardHoverBackground: palette.blue5,
    placementTestCardText: palette.black100,
    placementTestCreateBlockBorder: palette.darkBlue15,
    placementTestCreateBlockText: palette.black65,
    placementTestEntryPointChildrenListTitleText: palette.black65,
    placementTestExerciseBackground: palette.white,
    placementTestLinkedChapterModalTitleText: palette.black100,
    placementTestLinkedChapterModaButtonBackground: palette.blue100,
    placementTestLinkedChapterModaButtonText: palette.white,
    placementTestLinkedChapterModalCardHoverBackground: palette.blue5,
    placementTestLinkedChapterModalCardTitleText: palette.black100,
    placementTestLinkedChapterModalCardRadioButtonDisabled: palette.darkBlue15,
    placementTestLinkedChapterModalListText: palette.black65,

    previewScrollbarThumbBackground: palette.darkBlue25,
    previewScrollbarThumbHoverBackground: palette.black65,
    previewVocabularyMarkBackground: palette.white,
    previewVocabularyMarkBorder: palette.blue100,

    searchFilterSelectorControlBaseBorder: palette.darkBlue15,
    searchFilterSelectorControlFocusedBackground: palette.blue100,
    searchFilterSelectorControlBaseBackground: palette.white,
    searchFilterSelectorControlFocusedText: palette.white,
    searchFilterSelectorControlBaseText: palette.black100,
    searchFilterSelectorMenuBorder: palette.darkBlue15,
    searchFilterSelectorOptionFocusedBackground: palette.blue5,
    searchFilterSelectorOptionBaseBackground: palette.white,
    searchFilterSelectorPlaceholderFocusedText: palette.white,
    searchFilterSelectorPlaceholderBaseText: palette.black65,
    searchFilterSelectorDropdownIndicatorFocusedColor: palette.white,
    searchFilterSelectorDropdownIndicatorBaseColor: palette.black100,
    searchFilterSelectorClearIndicatorFocusedColor: palette.white,
    searchFilterSelectorClearIndicatorBaseColor: palette.black65,
    searchFilterSelectorSelectedOptionColor: palette.blue100,

    systemOverlayBlack: Color(palette.black).alpha(0.4).toString(),
    systemOverlayWhite: Color(palette.white).alpha(0.75).toString(),
    systemOverlayWhiteLight: Color(palette.white).alpha(0.4).toString(),

    textLight: palette.white,
    textPrimary: palette.black100,
    textSecondary: palette.black80,
    textTertiary: palette.black65,
    textQuaternary: palette.darkBlue25,

    uiBackgroundModal: palette.white,
    uiBackgroundPrimary: palette.white,
    uiBackgroundSecondary: palette.blue5,
    uiBackgroundTertiary: palette.darkBlue15,
    uiBackgroundQuaternary: palette.darkBlue5,
    uiDarkest: palette.black,
    uiDark: palette.black100,
    uiDivider: palette.darkBlue15,
    uiLightest: palette.white,

    utilityAlert: palette.yellow100,
    utilityAlertBackground: palette.yellow10,
    utilityError: palette.red100,
    utilityErrorBackground: palette.red10,
    utilityInfo: palette.blue100,
    utilityInfoBackground: palette.blue5,
    utilitySuccess: palette.green100,
    utilitySuccessBackground: palette.green10,

    guidelineBackgroundColor: palette.yellow50,
  } as const,
  space: {
    XS: '0.4rem',
    S: '0.8rem',
    M: '1.6rem',
    L: '2.4rem',
    XL: '4rem',
    XXL: '5.6rem',
  },
  zIndex: {
    backDrop: 5500,
    basePopover: 3500,
    commentsButtonWrapper: 3999,
    contentAnalyticsTooltip: 5600,
    defaultCreatorMenu: 3096,
    navigationSidebar: 5500,
  } as const,
};

export type Theme = typeof defaultTheme;
