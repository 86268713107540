import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { AnyExerciseContentInterface } from '@common/types/exercises/AnyExerciseContentInterface';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import {
  AnyExerciseContentBranch,
  CourseContentBranches,
  ContentBranches,
} from '@common/types/AnyExerciseContentBranch';
import { NavigationItemType } from '@features/content/navigation';

export const getReuseData = ({
  contentForTranslation,
  bundleName,
  visitedBranch,
  isEditAvailable,
  localizationIDForLangExperts,
}: {
  contentForTranslation: GenericContentInterface | CourseInterface | AnyExerciseContentInterface | {};
  bundleName: string;
  visitedBranch: AnyExerciseContentBranch | CourseContentBranches | ContentBranches;
  isEditAvailable: boolean;
  localizationIDForLangExperts: string;
}) => {
  const otherPlacesBundleCount = bundleName ? (contentForTranslation as any)[bundleName]?.mappings?.count : 1;

  const getOtherPlacesStringCount = () => {
    const contentMappings = bundleName
      ? (contentForTranslation as any)[bundleName][visitedBranch]?.mappings
      : (contentForTranslation as any)[visitedBranch]?.mappings;

    return contentMappings?.count ?? contentMappings?.length ?? 0;
  };

  const otherPlacesStringCount: number = getOtherPlacesStringCount();

  const isStringChangeBlocked = () => {
    // If editing is not available, changes are blocked
    if (!isEditAvailable) return true;

    const isReusingConfirmed = bundleName
      ? (contentForTranslation as any)[bundleName]?.[visitedBranch]?.isReusingConfirmed ?? false
      : (contentForTranslation as any)[visitedBranch]?.isReusingConfirmed ?? false;

    // Check for default localization with unconfirmed reuse
    if (localizationIDForLangExperts.includes('default_') && !isReusingConfirmed) {
      return true;
    }

    // Allow changes if there's only one or no other places
    if (!otherPlacesStringCount || otherPlacesStringCount === 1) {
      return false;
    }

    // Check reusing confirmation
    return !isReusingConfirmed;
  };

  const isBundleChangeBlocked = () => {
    // If editing is not available, changes are blocked
    if (!isEditAvailable) return true;

    // If no bundle or there's only one or no other places, changes are allowed
    if (!bundleName || !otherPlacesBundleCount || otherPlacesBundleCount === 1) return false;

    // Check bundle reusing confirmation
    return !(contentForTranslation as any)[bundleName].isReusingConfirmed;
  };

  return {
    isStringChangeBlocked: isStringChangeBlocked(),
    isBundleChangeBlocked: isBundleChangeBlocked(),
  };
};

export const getRealId = (id: string) => {
  const reusedIdPostfix = '_reused';
  if (id) {
    return id.includes(reusedIdPostfix) ? id.substr(0, id.indexOf(reusedIdPostfix)) : id;
  }
  return id;
};

export const processStructureForReuse = (prevStructure: NavigationItemType[], newElements: NavigationItemType[]) => {
  const prevStructureIds = prevStructure.map((struc) => struc.id);
  const loadedStructureIds = newElements.map((struc) => struc.id);
  const updatedPrevStructure = prevStructure.map((struc) => {
    if (loadedStructureIds.includes(struc.id)) {
      return {
        ...struc,
        id: `${struc.id}_reused_0`,
      };
    }
    return struc;
  });

  const newStructure = newElements.map((struc) => {
    const otherReusedIds = prevStructureIds.filter((strucId) => strucId.includes(struc.id));
    if (otherReusedIds.length) {
      return {
        ...struc,
        id: `${struc.id}_reused${otherReusedIds ? `_${otherReusedIds.length}` : ''}`,
      };
    }
    return struc;
  });

  return [...updatedPrevStructure, ...newStructure];
};
