import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { EXERCISES } from '@common/enums/ExerciseTypes';

import { MoveContentService } from '../MoveContentService';
import { ACTIVITIES } from '@features/content/activities';

export const getMoveContentByContentType = ({
  contentType,
  parentContentType,
}: {
  contentType: ContentTypesType;
  parentContentType: ContentTypesType;
}) => {
  // Assure that the content type is an exercise by checking the parent content type is actitivy
  // Avoids the ambiguity of certain content types (e.g. 'dialogue' can be an activity or an exercise)
  const isExercise =
    (EXERCISES as ContentTypesType[]).includes(contentType) &&
    (ACTIVITIES as ContentTypesType[]).includes(parentContentType);

  if (isExercise) {
    return MoveContentService.moveExercise;
  }

  const isRoleplayScenario = contentType === ContentTypes.roleplayScenario;

  if (isRoleplayScenario) {
    return MoveContentService.moveScenario;
  }

  return MoveContentService.moveGroup;
};
