import { Checkbox } from '@features/theme';
import filterIcon from '@static/svg/filter.svg';

import { FiltersContainer, StringsWithPendingTranslationsFilter, StyledSearchBox } from './styles';
import type { FiltersState, FilterValue } from './types';

type DownloadTranslationRequestFiltersProps = {
  filters: FiltersState;
  onFilter: (filterType: keyof FiltersState, value: FilterValue) => void;
};

export const DownloadTranslationRequestFilters = ({ filters, onFilter }: DownloadTranslationRequestFiltersProps) => {
  return (
    <FiltersContainer>
      <StyledSearchBox
        filterTerm={filters.query || ''}
        inputIcon={filterIcon}
        onClear={() => onFilter('query', '')}
        onSearchChange={(value: string) => onFilter('query', value)}
        placeholder="Filter by String ID"
      />

      <StringsWithPendingTranslationsFilter>
        <Checkbox
          checked={filters.pendingTranslationsOnly}
          dataTestId="download-translation-request-pending-translations-filter"
          label="Show only strings with missing translation"
          onToggle={(value) => onFilter('pendingTranslationsOnly', value)}
        />
      </StringsWithPendingTranslationsFilter>
    </FiltersContainer>
  );
};
