import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { LANGUAGE_NAMES_V2, type LanguageV2 } from '@features/content/languages';
import { ACTIVITIES, ACTIVITY_TITLES, type ActivityType } from '@features/content/activities';
import { allTypesMap, getPositionOfLesson } from '@helpers/contentCardHelper';
import { EXERCISES } from '@common/enums/ExerciseTypes';
import { LessonInterface } from '@common/interfaces/contentTypes/LessonInterface';

import type { NavigationItemType } from '../types';
import isRoleplay from './isRoleplay';

const getElementPosition = (content: NavigationItemType, structure: NavigationItemType[]) => {
  if (![ContentTypes.course, ContentTypes.level].includes(content.type as any)) {
    const lessonTypes = ['lesson', 'checkpoint', 'live', 'review', 'certificate'];
    if (lessonTypes.includes(content.type)) {
      const lessons = structure?.filter((element) => content.parentId === element.parentId);
      const positionNumber = getPositionOfLesson(content.id, lessons as unknown as LessonInterface[]);
      return positionNumber !== null ? `${positionNumber + 1}` : '';
    } else if (
      Object.values(EXERCISES).includes(content.type as any) ||
      ACTIVITIES.includes(content.type as ActivityType)
    ) {
      const contentReadyIds = structure
        ?.filter((element) => content.parentId === element.parentId && element.ready)
        .map((element) => element.id);

      const contentPosition =
        contentReadyIds.indexOf(content.id) === -1 ? null : contentReadyIds.indexOf(content.id) + 1;

      return contentPosition ? `${contentPosition}` : '';
    } else {
      const contentReadyIds = structure
        ?.filter((element) => element.type === content.type && content.parentId === element.parentId && element.ready)
        .map((element) => element.id);

      const contentPosition =
        contentReadyIds.indexOf(content.id) === -1 ? null : contentReadyIds.indexOf(content.id) + 1;

      return contentPosition ? `${contentPosition}` : '';
    }
  } else {
    return '';
  }
};

const getCourseContentTitle = (content: NavigationItemType, structure: NavigationItemType[]) => {
  const elementPosition = getElementPosition(content, structure);

  const isLesson = [
    ContentTypes.lesson,
    ContentTypes.liveLesson,
    ContentTypes.certificate,
    ContentTypes.checkpoint,
    ContentTypes.review,
    ContentTypes.roleplay,
    ContentTypes.speakingLesson,
  ].includes(content.type as any);
  const isActivity = ACTIVITIES.includes(content.type as ActivityType);
  const isExercise = EXERCISES.includes(content.type as any);

  if (isLesson) {
    const defaultLessonTitle = 'Untitled lesson';

    if (content.type === ContentTypes.checkpoint) {
      return `Checkpoint${elementPosition ? ` # ${elementPosition}` : ''}`;
    } else if (content.type === ContentTypes.liveLesson) {
      return `Live Lesson - ${content.title || defaultLessonTitle}`;
    } else if (content.type === ContentTypes.certificate) {
      return `Certificate - ${content.title || defaultLessonTitle}`;
    } else if (content.type === ContentTypes.speakingLesson) {
      return `Speaking - ${content.title || defaultLessonTitle}`;
    } else if (content.type === ContentTypes.roleplay) {
      return content.title || 'AI Roleplay';
    } else {
      return content.title || defaultLessonTitle;
    }
  } else if (isActivity) {
    return ACTIVITY_TITLES[content.type as ActivityType];
  } else if (isExercise) {
    return content.title || allTypesMap[content.type as keyof typeof allTypesMap];
  } else {
    const defaultTitle = `Untitled ${content.type}`;

    return content.title || defaultTitle;
  }
};

const getRoleplayContentTitle = (content: NavigationItemType, language: LanguageV2) => {
  if (content.type === ContentTypes.roleplay) {
    return `${LANGUAGE_NAMES_V2[language]} Roleplay`;
  }
  if (content.title) return content.title;
  return allTypesMap[content.type as ContentTypesType];
};

const getNavigationItemTitle = ({
  content,
  language,
  structure,
}: {
  content: NavigationItemType;
  language?: LanguageV2;
  structure?: NavigationItemType[];
}) => {
  if (isRoleplay(content.type) && !!language) {
    return getRoleplayContentTitle(content, language as LanguageV2);
  } else if (!!structure) {
    return getCourseContentTitle(content, structure);
  }

  return content.title;
};

export default getNavigationItemTitle;
