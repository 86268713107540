import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { APIError } from '../types';

export const generateErrorMessage = (error: APIError) => {
  const { detail, status } = error;
  let errorMsg = DEFAULT_ERROR_MESSAGE;

  if (detail && status) {
    errorMsg = `Error code ${status}. ${detail}`;
  }

  return errorMsg;
};
