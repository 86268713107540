/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull http'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/busuu/Busuu%20Logos/implementation/http)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'staging' | 'production';

export const ApiKey: Record<Environment, string> = {
  staging: '760a821ccbcaee7b6a867de594f0d9e7',
  production: '5b3769012d38cae551ffcf5d7f9731c5'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'http',
    versionId: '28ec8df0-2f76-4cf7-8340-949ff30f3699'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface LogosAlternativeValueCreatedProperties {
  exercise_id: string;
  exercise_type: string;
}

export interface LogosCompletionRatesSeenProperties {
  lesson_id: string;
}

export interface LogosContentMovedFromNavigationProperties {
  /**
   * DB id of the Logos component where the event was triggered (e.g. lesson id)
   */
  component_id: string;
  /**
   * Type of Logos layer (course, level, chapter, lesson, etc..)
   */
  component_type: string;
  from: string;
  to: string;
}

export interface LogosGrammarExercisesAddedProperties {
  /**
   * Number of bulk exercises created in Grammar Review
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 2 |
   * | Max Value | 10 |
   */
  grammar_exercises_num: number;
}

export interface LogosSearchOpenedProperties {
  /**
   * Place where the event is originated from
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | /content_creator|header|navigation_panel|resource|resource_bundle|shortcut|string_suggestions/ |
   */
  source?: string;
}

export interface LogosSearchReuseSelectedProperties {
  /**
   * Search type
   */
  type: string;
}

export interface LogosSearchTriggeredProperties {
  /**
   * Place where the event is originated from
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | /content_creator|header|navigation_panel|resource|resource_bundle|shortcut|string_suggestions/ |
   */
  source?: string;
  /**
   * Search type
   */
  type: string;
}

export interface LogosTranslationRequestDownloadedProperties {
  /**
   * DB id of the Logos component where the event was triggered (e.g. lesson id)
   */
  component_id: string;
  /**
   * Type of Logos layer (course, level, chapter, lesson, etc..)
   */
  component_type: string;
  /**
   * The amount of selected strings
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  selected_string_count: number;
}

export interface LogosTranslationRequestOpenedProperties {
  /**
   * DB id of the Logos component where the event was triggered (e.g. lesson id)
   */
  component_id: string;
  /**
   * Type of Logos layer (course, level, chapter, lesson, etc..)
   */
  component_type: string;
  /**
   * The amount of selected strings
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  selected_string_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  string_count: number;
}

export class LogosAlternativeValueCreated implements BaseEvent {
  event_type = 'logos:alternative_value_created';

  constructor(
    public event_properties: LogosAlternativeValueCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosCommentingPanelOpened implements BaseEvent {
  event_type = 'logos:commenting_panel_opened';
}

export class LogosCompletionRatesSeen implements BaseEvent {
  event_type = 'logos:completion_rates_seen';

  constructor(
    public event_properties: LogosCompletionRatesSeenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosContentMovedFromNavigation implements BaseEvent {
  event_type = 'logos:content_moved_from_navigation';

  constructor(
    public event_properties: LogosContentMovedFromNavigationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosGrammarExercisesAdded implements BaseEvent {
  event_type = 'logos:grammar_exercises_added';

  constructor(
    public event_properties: LogosGrammarExercisesAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosSearchClosed implements BaseEvent {
  event_type = 'logos:search_closed';
}

export class LogosSearchOpened implements BaseEvent {
  event_type = 'logos:search_opened';

  constructor(
    public event_properties?: LogosSearchOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosSearchReuseSelected implements BaseEvent {
  event_type = 'logos:search_reuse_selected';

  constructor(
    public event_properties: LogosSearchReuseSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosSearchTriggered implements BaseEvent {
  event_type = 'logos:search_triggered';

  constructor(
    public event_properties: LogosSearchTriggeredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosTranslationRequestDownloaded implements BaseEvent {
  event_type = 'logos:translation_request_downloaded';

  constructor(
    public event_properties: LogosTranslationRequestDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogosTranslationRequestOpened implements BaseEvent {
  event_type = 'logos:translation_request_opened';

  constructor(
    public event_properties: LogosTranslationRequestOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * logos:alternative_value_created
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Aalternative_value_created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. exercise_id)
   * @param options Amplitude event options.
   */
  logosAlternativeValueCreated(
    properties: LogosAlternativeValueCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosAlternativeValueCreated(properties), options);
  }

  /**
   * logos:commenting_panel_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Acommenting_panel_opened)
   *
   * Event to track when user opens commenting panel
   *
   * Owner: Santiago Martín-Cleto
   *
   * @param options Amplitude event options.
   */
  logosCommentingPanelOpened(
    options?: EventOptions,
  ) {
    return this.track(new LogosCommentingPanelOpened(), options);
  }

  /**
   * logos:completion_rates_seen
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Acompletion_rates_seen)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. lesson_id)
   * @param options Amplitude event options.
   */
  logosCompletionRatesSeen(
    properties: LogosCompletionRatesSeenProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosCompletionRatesSeen(properties), options);
  }

  /**
   * logos:content_moved_from_navigation
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Acontent_moved_from_navigation)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. component_id)
   * @param options Amplitude event options.
   */
  logosContentMovedFromNavigation(
    properties: LogosContentMovedFromNavigationProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosContentMovedFromNavigation(properties), options);
  }

  /**
   * logos:grammar_exercises_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Agrammar_exercises_added)
   *
   * Event to track when users create exercises in Grammar Review
   *
   * Owner: Santiago Martín-Cleto
   *
   * @param properties The event's properties (e.g. grammar_exercises_num)
   * @param options Amplitude event options.
   */
  logosGrammarExercisesAdded(
    properties: LogosGrammarExercisesAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosGrammarExercisesAdded(properties), options);
  }

  /**
   * logos:search_closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Asearch_closed)
   *
   * Event to track when user closes search modal
   *
   * Owner: Santiago Martín-Cleto
   *
   * @param options Amplitude event options.
   */
  logosSearchClosed(
    options?: EventOptions,
  ) {
    return this.track(new LogosSearchClosed(), options);
  }

  /**
   * logos:search_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Asearch_opened)
   *
   * Event to track when user opens search modal
   *
   * Owner: Santiago Martín-Cleto
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  logosSearchOpened(
    properties?: LogosSearchOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosSearchOpened(properties), options);
  }

  /**
   * logos:search_reuse_selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Asearch_reuse_selected)
   *
   * Event to track when user reuses component using search
   *
   * Owner: Santiago Martín-Cleto
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  logosSearchReuseSelected(
    properties: LogosSearchReuseSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosSearchReuseSelected(properties), options);
  }

  /**
   * logos:search_triggered
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Asearch_triggered)
   *
   * Event to track when search is triggered
   *
   * Owner: Santiago Martín-Cleto
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  logosSearchTriggered(
    properties: LogosSearchTriggeredProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosSearchTriggered(properties), options);
  }

  /**
   * logos:translation_request_downloaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Atranslation_request_downloaded)
   *
   * The user selects the Download button to generate the csv.
   *
   * @param properties The event's properties (e.g. component_id)
   * @param options Amplitude event options.
   */
  logosTranslationRequestDownloaded(
    properties: LogosTranslationRequestDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosTranslationRequestDownloaded(properties), options);
  }

  /**
   * logos:translation_request_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/busuu/Busuu%20Logos/events/main/latest/logos%3Atranslation_request_opened)
   *
   * The user opens the translation download request modal
   *
   * @param properties The event's properties (e.g. component_id)
   * @param options Amplitude event options.
   */
  logosTranslationRequestOpened(
    properties: LogosTranslationRequestOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogosTranslationRequestOpened(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
