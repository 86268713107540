import { useAppDispatch, useAppSelector } from '@redux/store';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Prompt, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { SelectedGroupsOfParentInterface } from '@common/interfaces/groups/SelectedGroupsOfParentInterface';
import { DialogueCharactersPanel } from '@components/Exercises/Dialogue/DialogueCharactersPanel';
import { GenericContentInterface } from '@common/interfaces/contentTypes/GenericContentInterface';
import { ContentTypesActionsCreator } from '@actionCreators/ContentTypesActionsCreator';
import { getValueByLanguage } from '@features/content';
import { useIsUploadTranslationsAvailable } from '@features/auth/roles';
import CourseImageUploader from '@components/CourseImageUploader/CourseImageUploader';
import { PublishingQueueSidebar } from '@components/Publishing/PublishingQueueSidebar';
import { UploadTranslations } from '@components/UploadTranslations';
import { Loader } from '@features/theme';
import { StringReuseModal } from '@components/StringReuseModal/StringReuseModal';
import TranslationsPanel from '@components/TranslationsPanel/TranslationsPanel';
import { getInitialValues } from '@helpers/formikInitialValuesHelper';
import { CourseNavigation } from '@components/CourseNavigation/CourseNavigation';
import { PublishingErrorsModal } from '@components/Publishing/PublishingErrorsModal/PublishingErrorsModal';
import { AnyLoadedExercise } from '@common/types/exercises/AnyLoadedExercise';
import { PreloaderFullscreen } from '@components/Loaders/PreloaderFullscreen';
import { CourseInterface } from '@common/interfaces/courses/CourseInterface';
import BreadcrumbNavigation from '@components/BreadcrumbNavigation/BreadcrumbNavigation';
import ExerciseLoader from '@components/ExerciseLoader/ExerciseLoader';
import { ContentType, ContentTypes } from '@common/enums/ContentTypes';
import CourseEditionService from '@services/CourseEditionService';
import { SearchModal, useOpenSearchShortcut } from '@features/search';
import { LoadingStage } from '@common/enums/LoadingStage';
import ContentsService from '@services/ContentsService';
import { DBId } from '@common/types/DBId';
import { getRealId } from '@helpers/reuseHelper';
import { ContentLabelsModal } from '@components/ContentLabelsModal/ContentLabelsModal';
import { ContentJSONModal } from '@components/ContentJSONModal/ContentJSONModal';
import { setCourseLabels, setExerciseLabels, setGroupLabels } from '@redux/slices/courseSlice';
import { CourseMainData } from '@components/CourseEdition/CourseMainData';
import { LevelMainData } from '@components/CourseEdition/LevelMainData';
import { ChapterMainData } from '@components/CourseEdition/ChapterMainData';
import { LessonMainData } from '@components/CourseEdition/LessonMainData';
import { ActivityMainData } from '@components/CourseEdition/ActivityMainData';
import { PublishModalContainer } from '@components/CourseEdition/PublishModalContainer';
import { countCourseView } from '@features/dashboard';
import { NAVIGATION_PANEL_WIDTH } from '@features/content/navigation';
import { HelpDisplayer } from '@features/help';
import { ContentOwner } from '@features/content/contentOwnership';

import CourseEditionExerciseHeader from './CourseEditionExerciseHeader';
import { CourseEditionActionsCreator } from '@actionCreators/CourseEditionActionsCreator';
import { useToast } from '@features/app/toast';
import { CourseLanguagesPanel } from '@components/CourseLanguagesPanel/CourseLanguagesPanel';
import { Modal, Panel, useDialogModal } from '@features/modal';
import {
  selectCourse,
  selectIsSaveButtonEnabled,
  selectLoadedExercise,
  selectSelectedGroupsOfParent,
  selectStringReuseModalOpenedFor,
} from '@selectors/CoursesSelectors';
import {
  selectDialogueCharactersPanelVisible,
  selectPublishingPanelVisible,
  selectTranslationsPanel,
} from '@selectors/UiSelectors';
import classnames from 'classnames';
import {
  DownloadTranslationRequestModal,
  UploadTranslationsToLocalisationServiceModal,
  UploadTranslationsToLocalisationServiceProgressModal,
} from '@features/content/translations';

import { ReactComponent as DownloadTranslationsIcon } from './img/download.svg';
import { ReactComponent as LocalisationServiceIcon } from './img/localisation-service.svg';
import { ReactComponent as UploadTranslationsIcon } from './img/upload.svg';
import { AxiosResponse } from 'axios';
import { isFeatureEnabled } from '@helpers/featuresHelper';

const CourseLoaderContainer = styled.div`
  height: 100vh;
  width: calc(100% - ${NAVIGATION_PANEL_WIDTH});
  margin-left: ${NAVIGATION_PANEL_WIDTH};
`;

const BreadcrumbsAndOwnershipWrapper = styled.div`
  display: flex;
  height: 3.8rem;
  justify-content: space-between;
  margin-bottom: 4rem;
`;

const UploadToLocalisationServiceButton = ({ onClick }: { onClick: () => void }) => (
  <div className="context-menu__list-element" key="_translations-upload-to-localisation-service" onClick={onClick}>
    <LocalisationServiceIcon />
    <span>Upload to Smartling</span>
    <span className="context-menu__list-element__new-mark">New</span>
  </div>
);

const DownloadTranslationRequestButton = ({ onClick }: { onClick: () => void }) => (
  <div className="context-menu__list-element" key="_download-translation-request" onClick={onClick}>
    <DownloadTranslationsIcon title="Download translation request" />
    <span>Download translation request</span>
  </div>
);

const CourseEdition = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const dispatch = useAppDispatch();
  const { isUploadTranslationsAvailable, isUploadTranslationsToLocalisationServiceAvailable } =
    useIsUploadTranslationsAvailable();
  let { courseId, levelId, chapterId, lessonId, activityId, exerciseId } = useParams<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
    exerciseId: DBId;
  }>();

  const shouldShowUploadTranslationsToLocalisationService =
    isFeatureEnabled('smartling') && isUploadTranslationsToLocalisationServiceAvailable;

  const { open: openEditCourseModal, modal: editCourseModal } = useDialogModal((modalControls) => (
    <Panel {...modalControls} onClickOutside={modalControls.close} lockScroll>
      <CourseLanguagesPanel courseId={courseId} close={modalControls.close} />
    </Panel>
  ));

  useOpenSearchShortcut();

  useEffect(() => {
    if (courseId) {
      countCourseView(courseId);
    }
  }, [courseId]);

  let contentId = levelId;
  if (exerciseId) {
    contentId = exerciseId;
  } else if (activityId) {
    contentId = activityId;
  } else if (lessonId) {
    contentId = lessonId;
  } else if (chapterId) {
    contentId = chapterId;
  }
  const initialContentId = contentId;
  courseId = getRealId(courseId);
  levelId = getRealId(levelId);
  chapterId = getRealId(chapterId);
  lessonId = getRealId(lessonId);
  activityId = getRealId(activityId);
  exerciseId = getRealId(exerciseId);
  contentId = getRealId(contentId);

  const getGroupParentIdForRequest = () => {
    if (exerciseId) {
      return activityId;
    } else if (activityId && !exerciseId) {
      return lessonId;
    } else if (lessonId && chapterId) {
      return chapterId;
    } else if (lessonId && !chapterId) {
      return levelId;
    } else if (chapterId) {
      return levelId;
    } else if (levelId && !lessonId && !chapterId) {
      return courseId;
    }
  };
  const parentId = getGroupParentIdForRequest();

  const selectedCourse: CourseInterface = useAppSelector(selectCourse);
  const loadedCourse: CourseInterface = selectedCourse;

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const isCourseLoaded = selectedCourse?.loaded === LoadingStage.loaded;

  const selectedGroupsOfParent: SelectedGroupsOfParentInterface = useAppSelector(selectSelectedGroupsOfParent);
  const isGroupLoaded: string = selectedGroupsOfParent.loaded;
  const loadedContent: any = selectedGroupsOfParent.parentContents;

  const loadedExercise: AnyLoadedExercise | null = useAppSelector(selectLoadedExercise);
  const stringReuseModalOpenedFor: string = useAppSelector(selectStringReuseModalOpenedFor);

  const translationsPanel = useAppSelector(selectTranslationsPanel);
  const publishingPanelVisible = useAppSelector(selectPublishingPanelVisible);
  const dialogueCharactersPanelVisible = useAppSelector(selectDialogueCharactersPanelVisible);

  const [uploadingCourseImage, setUploadingCourseImage] = useState(false);

  const [isCourse, setIsCourse] = useState(false);
  const isLevel = selectedGroupsOfParent.parentType === ContentTypes.level && contentId;
  const isChapter = selectedGroupsOfParent.parentType === ContentTypes.chapter && contentId;
  const isLesson = selectedGroupsOfParent.parentType === ContentTypes.lesson && contentId;
  const isActivity = selectedGroupsOfParent.parentType === ContentTypes.activity && contentId;
  const isExercise: boolean = !!exerciseId;
  const isExerciseLoaded = loadedExercise?.loaded === LoadingStage.loaded;

  CourseEditionService.loadCourseOrGroupOrExercise(
    dispatch,
    selectedCourse,
    loadedExercise,
    selectedGroupsOfParent,
    courseId,
    contentId,
    isExercise,
    parentId,
  );

  let { learningLanguage, interfaceLanguages, title: courseName } = selectedCourse;
  let [contextMenuItems, setContextMenuItems] = useState<React.ReactElement[]>(() => []);
  const [showTranslationsUploadModal, setShowTranslationsUploadModal] = useState(false);
  const handleCloseTranslationsUploadModal = () => setShowTranslationsUploadModal(false);
  const handleShowTranslationsUploadModal = () => setShowTranslationsUploadModal(true);
  const [uploadTranslationToLocalisationServicePromise, setUploadTranslationToLocalisationServicePromise] = useState<
    Promise<AxiosResponse> | undefined
  >(undefined);

  const contentName = getValueByLanguage(loadedContent?.titleWithLocalizations?.textLocalizations);

  const initialValues = getInitialValues({
    isCourse,
    course: loadedCourse,
    isLevel: !!isLevel,
    isChapter: !!isChapter,
    isLesson: !!isLesson,
    group: loadedContent,
    isExercise,
    exercise: loadedExercise.exercise,
  });

  useEffect(() => {
    if (
      // For content
      (!isExercise &&
        (contentId !== selectedGroupsOfParent.parentContents?.id || initialContentId?.includes('_reused')) &&
        selectedGroupsOfParent.loaded === LoadingStage.loaded) ||
      // For exercise
      (isExercise &&
        (contentId !== loadedExercise.exercise?.content?.id || initialContentId?.includes('_reused')) &&
        contentId !== undefined &&
        loadedExercise.loaded === LoadingStage.loaded &&
        loadedExercise.exercise !== null)
    ) {
      CourseEditionService.loadCourseOrGroupOrExercise(
        dispatch,
        selectedCourse,
        loadedExercise,
        selectedGroupsOfParent,
        courseId,
        contentId,
        isExercise,
        parentId,
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contentId, isExercise, initialContentId]);

  useEffect(() => {
    setIsCourse(contentId ? false : true);
    if (!contentId) {
      dispatch(CourseEditionActionsCreator.getCourseWithGroups(courseId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contentId]);

  const showToast = useToast();
  const showDownloadStartedToast = () => {
    showToast({
      type: 'info',
      title: 'Download should have started automatically',
    });
  };
  const showDownloadErrorToast = (title: string) => {
    showToast({
      type: 'error',
      title,
    });
  };

  const { open: openUploadToLocalisationServiceModal, modal: uploadTranslationsToLocalisationServiceModal } =
    useDialogModal((modalControls) => (
      <Modal lockScroll overflow="visible" size="M" {...modalControls}>
        <UploadTranslationsToLocalisationServiceModal
          isOpen={modalControls.isOpen}
          language={learningLanguage}
          onCancel={() => {
            modalControls.close();
            setUploadTranslationToLocalisationServicePromise(undefined);
          }}
          onUpload={(uploadTranslationPromise) => {
            modalControls.close();
            setUploadTranslationToLocalisationServicePromise(uploadTranslationPromise);
            openUploadTranslationsToLocalisationServiceProgressModal();
          }}
        />
      </Modal>
    ));

  const {
    open: openUploadTranslationsToLocalisationServiceProgressModal,
    modal: uploadTranslationsToLocalisationServiceProgressModal,
  } = useDialogModal((modalControls) => (
    <Modal lockScroll size="S" {...modalControls}>
      <UploadTranslationsToLocalisationServiceProgressModal
        uploadTranslationPromise={uploadTranslationToLocalisationServicePromise}
        onClose={modalControls.close}
        onError={() => {
          modalControls.close();
          openUploadToLocalisationServiceModal();
        }}
      />
    </Modal>
  ));

  const { open: openDownloadTranslationRequestModal, modal: downloadTranslationRequestModal } = useDialogModal(
    (modalControls) => (
      <Modal lockScroll size="L" {...modalControls}>
        <DownloadTranslationRequestModal
          contentIds={{ courseId, levelId, chapterId, lessonId }}
          contentName={contentName}
          isOpen={modalControls.isOpen}
          learningLanguage={learningLanguage}
          interfaceLanguages={interfaceLanguages}
          onCancel={modalControls.close}
          onDownload={modalControls.close}
          onError={(title) => {
            modalControls.close();
            showDownloadErrorToast(title);
          }}
        />
      </Modal>
    ),
  );

  const handleDownloadTranslationRequestClick = () => {
    /** Open modal to select strings to request translation only for Lesson */
    if (isFeatureEnabled('selectStringsDownloadTranslationRequest') && isLesson) {
      openDownloadTranslationRequestModal();
    } else {
      ContentsService.csv.download(contentId, learningLanguage, interfaceLanguages, contentName, 'group');

      showDownloadStartedToast();
    }
  };

  const getContextMenuItems = useCallback(() => {
    let contextMenuItems: JSX.Element[] = [];

    if (isCourse && isUploadTranslationsAvailable) {
      contextMenuItems = [
        <div
          className="context-menu__list-element"
          onClick={handleShowTranslationsUploadModal}
          key="_translations-upload"
        >
          <UploadTranslationsIcon title="Upload translations" />
          <span>Upload translations</span>
          <HelpDisplayer type="guideline" id="guideline-upload-translations" />
        </div>,
      ];

      if (shouldShowUploadTranslationsToLocalisationService) {
        contextMenuItems.push(<UploadToLocalisationServiceButton onClick={openUploadToLocalisationServiceModal} />);
      }
    } else if (isLesson) {
      if (isUploadTranslationsAvailable) {
        contextMenuItems = [
          <Fragment key="_translations-upload">
            {isUploadTranslationsAvailable ? (
              <div className="context-menu__list-element" onClick={handleShowTranslationsUploadModal}>
                <UploadTranslationsIcon title="Upload translations" />
                <span>Upload translations</span>
                <HelpDisplayer type="guideline" id="guideline-upload-translations" />
              </div>
            ) : null}
          </Fragment>,
        ];

        if (shouldShowUploadTranslationsToLocalisationService) {
          contextMenuItems.push(<UploadToLocalisationServiceButton onClick={openUploadToLocalisationServiceModal} />);
        }
        contextMenuItems.push(<DownloadTranslationRequestButton onClick={handleDownloadTranslationRequestClick} />);
      } else {
        contextMenuItems = [<DownloadTranslationRequestButton onClick={handleDownloadTranslationRequestClick} />];
      }
    }

    return contextMenuItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCourse, isLesson, contentId, isUploadTranslationsAvailable, loadedContent]);

  useEffect(() => {
    setContextMenuItems(getContextMenuItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupsOfParent, isUploadTranslationsAvailable]);

  /* Opened page for not exercise and exercise is loaded */
  if (!isExercise && isExerciseLoaded) {
    dispatch(CourseEditionActionsCreator.clearCurrentExercise());

    return <PreloaderFullscreen />;
  }

  const getLabels = () => {
    if (isExercise) {
      return loadedExercise?.exercise?.content.labels || [];
    }

    if (isCourse) {
      return loadedCourse.labels;
    }

    if (isLevel || isLesson || isActivity) {
      return selectedGroupsOfParent.parentContents?.labels || [];
    }

    return [];
  };

  const saveLabels = async (newLabels: string[]) => {
    if (isExercise && loadedExercise) {
      await dispatch(
        setExerciseLabels({
          exerciseId: exerciseId,
          exerciseType: loadedExercise?.exercise.type,
          labels: newLabels,
        }),
      );
    }

    if (isLevel || isLesson || isActivity) {
      await dispatch(
        setGroupLabels({
          groupId: activityId || lessonId || levelId,
          labels: newLabels,
        }),
      );
    }

    if (isCourse) {
      await dispatch(
        setCourseLabels({
          courseId: courseId,
          labels: newLabels,
        }),
      );
    }
  };

  const getContentType = () => {
    if (isCourse) {
      return ContentTypes.course;
    } else if (isExercise) {
      return ContentTypes.exercise;
    } else {
      return ContentType.levelOrLesson;
    }
  };

  const getContentDates = () => {
    if (isCourse) {
      return selectedCourse.dates;
    } else if (isExercise) {
      return loadedExercise.exercise.content.dates;
    } else {
      return selectedGroupsOfParent.parentContents?.dates;
    }
  };

  const getContentOwner = () => {
    if (isCourse) {
      return selectedCourse.owner;
    } else if (isExercise) {
      return loadedExercise.exercise.content.owner;
    } else {
      return selectedGroupsOfParent.parentContents?.owner;
    }
  };

  const getComponentId = () => {
    if (isCourse) {
      return courseId;
    } else if (isExercise) {
      return exerciseId;
    } else {
      return selectedGroupsOfParent.parentId;
    }
  };

  return (
    <>
      {editCourseModal}
      <div className="course-edition">
        <Prompt when={isSaveButtonEnabled} message={() => `Are you sure you want to navigate?`} />
        <PublishModalContainer />
        <ContentLabelsModal labels={getLabels()} onSave={saveLabels} />
        <ContentJSONModal type={getContentType()} parentId={parentId} contentId={isCourse ? courseId : contentId} />
        {/* Opened page for not exercise and course is not loaded */}
        {!isExercise && !isCourseLoaded && <PreloaderFullscreen />}
        {publishingPanelVisible && <PublishingQueueSidebar />}
        {dialogueCharactersPanelVisible && <DialogueCharactersPanel />}
        {stringReuseModalOpenedFor && <StringReuseModal />}
        <PublishingErrorsModal />
        {isCourseLoaded && (
          <>
            <CourseNavigation collapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} />
            {(isExercise && !isExerciseLoaded) ||
            (isCourse && !isCourseLoaded) ||
            (!isExercise && !isCourse && isGroupLoaded !== LoadingStage.loaded) ? (
              <CourseLoaderContainer>
                <Loader size="L" />
              </CourseLoaderContainer>
            ) : (
              <Formik initialValues={initialValues} onSubmit={() => {}}>
                <div
                  className={classnames('course-edition__course-data-composer', {
                    collapsed: sidebarCollapsed,
                  })}
                >
                  {uploadingCourseImage && <CourseImageUploader close={() => setUploadingCourseImage(false)} />}
                  <SearchModal />
                  {translationsPanel.visibleForCourse && (
                    <TranslationsPanel content={loadedCourse} type={ContentTypes.course} />
                  )}
                  {translationsPanel.visibleForLevelAndLesson && (
                    <TranslationsPanel content={loadedContent} type={ContentType.levelOrLesson} />
                  )}
                  {translationsPanel.visible && (
                    <TranslationsPanel
                      content={loadedExercise.exercise.content}
                      type={ContentTypes.exercise}
                      exerciseType={loadedExercise.exercise.type}
                    />
                  )}
                  <CourseEditionExerciseHeader
                    courseName={courseName}
                    isCourse={isCourse}
                    isExercise={isExercise}
                    contextMenuItems={contextMenuItems}
                    selectedGroupsOfParent={selectedGroupsOfParent}
                    loadedExercise={loadedExercise}
                    learningLanguage={learningLanguage}
                    interfaceLanguages={interfaceLanguages}
                    onInterfaceLanguagesClick={openEditCourseModal}
                  />
                  <BreadcrumbsAndOwnershipWrapper>
                    <BreadcrumbNavigation
                      onCourseNavigationChange={(selectedNode: GenericContentInterface) => {
                        setIsCourse(selectedNode.type === ContentTypes.course);
                        dispatch(ContentTypesActionsCreator.onCourseNavigationChange(selectedNode));
                        if (selectedNode.type === ContentTypes.course) {
                          dispatch(CourseEditionActionsCreator.getCourseWithGroups(courseId));
                        }
                      }}
                    />
                    <ContentOwner
                      dates={getContentDates()}
                      componentId={getComponentId()}
                      contentType={getContentType()}
                      owner={getContentOwner()}
                    />
                  </BreadcrumbsAndOwnershipWrapper>
                  {isCourse && <CourseMainData openUploadCourseImage={() => setUploadingCourseImage(true)} />}
                  {isLevel && loadedContent && <LevelMainData />}
                  {isChapter && loadedContent && <ChapterMainData />}
                  {isLesson && loadedContent && <LessonMainData />}
                  {isActivity && loadedContent && <ActivityMainData />}
                  {isExercise && isExerciseLoaded && <ExerciseLoader loadedExercise={loadedExercise} />}
                </div>
              </Formik>
            )}
          </>
        )}
        <UploadTranslations show={showTranslationsUploadModal} onHide={handleCloseTranslationsUploadModal} />
      </div>
      {isFeatureEnabled('selectStringsDownloadTranslationRequest') && downloadTranslationRequestModal}
      {uploadTranslationsToLocalisationServiceModal}
      {uploadTranslationsToLocalisationServiceProgressModal}
    </>
  );
};

export default withRouter(CourseEdition);
