import { Button } from 'react-bootstrap';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { useIsEditAvailable } from '@features/content/courses';
import { useAppDispatch } from '@redux/store';

import { useRoleplay } from '../useRoleplay';

export const TabFooter = () => {
  const dispatch = useAppDispatch();
  const { isEditAvailable } = useIsEditAvailable();

  const { onSave } = useRoleplay();

  return (
    <footer className="translations-panel-modal__save-cancel">
      <Button
        className="translations-panel-modal__cancel"
        onClick={() => dispatch(RoleplayActionCreators.closeTranslationsPanel())}
      >
        Cancel
      </Button>
      <Button
        className="translations-panel-modal__save"
        disabled={!isEditAvailable}
        onClick={() => {
          onSave();
          dispatch(RoleplayActionCreators.closeTranslationsPanel());
        }}
      >
        Save
      </Button>
    </footer>
  );
};
