import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { ContentTypesActions } from '@actions/ContentTypesActions';
import { PublishingActions } from '@actions/PublishingActions';
import { RoleplayActions } from '@actions/RoleplayActions';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { LoadingStage } from '@common/enums/LoadingStage';
import { MediaTypesType } from '@common/enums/MediaTypes';
import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import { ChangeStatusInterface } from '@common/interfaces/contentTypes/ChangeStatusInterface';
import { LocalizationInterface } from '@common/interfaces/localization/LocalizationInterface';
import { RoleplaySliceType } from '@common/interfaces/slices/RoleplaySliceType';
import { ValidationInterface } from '@common/interfaces/validation/ValidationInterface';
import { DBId } from '@common/types/DBId';
import ExerciseDataModelAssembler from '@components/Exercises/ExerciseDataModelAssembler';
import { ALL_LANGUAGES_V2, type LanguageV2 } from '@features/content/languages';
import { ImageDataType } from '@features/content/media';
import type {
  RoleplayCategoryContentType,
  RoleplayContentType,
  RoleplayListItemType,
  RoleplayCategoryFieldWithLocalizationNames,
  RoleplayCategoryListItemType,
  RoleplayCategoryFormikValues,
  RoleplayScenarioContentType,
  RoleplayScenarioStringFieldNames,
  RoleplayScenarioNumberFieldNames,
  RoleplayScenarioFormikValues,
  RoleplayScenarioFieldWithLocalizationNames,
} from '@features/content/roleplay';
import {
  roleplayInitialContent,
  roleplayCategoryInitialContent,
  roleplayScenarioInitialContent,
} from '@redux/initialStates/roleplayInitialState';

export const RoleplayReducers = {
  // Common
  [RoleplayActions.SET_CONTENT_ID]: (
    state: RoleplaySliceType,
    {
      payload: { contentId, contentType, fieldName },
    }: PayloadAction<{
      contentType: ContentTypesType;
      fieldName: RoleplayCategoryFieldWithLocalizationNames & RoleplayScenarioFieldWithLocalizationNames;
      contentId: DBId;
    }>,
  ) => {
    switch (contentType) {
      case ContentTypes.roleplayCategory: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.roleplayCategory.content[fieldName];
          if (fieldToUpdate) {
            fieldToUpdate.id = contentId;
          }
        });
      }
      case ContentTypes.roleplayScenario: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.roleplayScenario.content[fieldName];
          if (fieldToUpdate) {
            fieldToUpdate.id = contentId;
          }
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },
  [ContentTypesActions.EDIT_EVERYWHERE]: (
    state: RoleplaySliceType,
    {
      payload: { contentType, visitedBranch },
    }: PayloadAction<{
      contentType: ContentTypesType;
      visitedBranch: RoleplayCategoryFieldWithLocalizationNames | RoleplayScenarioFieldWithLocalizationNames;
      contentId: DBId;
    }>,
  ): RoleplaySliceType => {
    switch (contentType) {
      case ContentTypes.roleplayCategory: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.roleplayCategory.content[visitedBranch as keyof RoleplayCategoryContentType];

          if (fieldToUpdate) {
            (fieldToUpdate as TranslationsPanelContentInterface).isReusingConfirmed = true;
          }
        });
      }
      case ContentTypes.roleplayScenario: {
        return produce(state, (draft) => {
          const fieldToUpdate = draft.roleplayScenario.content[visitedBranch];

          if (fieldToUpdate) {
            fieldToUpdate.isReusingConfirmed = true;
          }
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },

  // Roleplay
  [RoleplayActions.ROLEPLAY_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplay.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.ROLEPLAY_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayContentType & { learningLanguage: LanguageV2 }>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplay.loaded = LoadingStage.loaded;
      draft.roleplay.content = { ...payload, type: ContentTypes.roleplay };
      draft.roleplay.id = payload.id;
      draft.roleplay.interfaceLanguages = ALL_LANGUAGES_V2 as unknown as LanguageV2[];
      draft.roleplay.learningLanguage = payload.learningLanguage;
    });
  },
  [RoleplayActions.SET_INITIAL_ROLEPLAY]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplay.loaded = LoadingStage.notLoaded;
      draft.roleplay.content = roleplayInitialContent;
      draft.roleplay.categoriesData.loaded = LoadingStage.notLoaded;
      draft.roleplay.categoriesData.categories = [];
    });
  },
  [RoleplayActions.ROLEPLAY_CATEGORIES_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplay.categoriesData.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.ROLEPLAY_CATEGORIES_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplay.categoriesData.loaded = LoadingStage.loaded;
      draft.roleplay.categoriesData.categories = payload.map((category) => ({
        ...category,
        type: ContentTypes.roleplayCategory,
      }));
    });
  },
  [RoleplayActions.SET_STRING]: (
    state: RoleplaySliceType,
    {
      payload: { newString: notFilledNewString, contentType, fieldName, isReuse = false },
    }: PayloadAction<{
      newString: TranslationsPanelContentInterface;
      contentType: ContentTypesType;
      fieldName: RoleplayCategoryFieldWithLocalizationNames;
      isReuse?: boolean;
    }>,
  ): RoleplaySliceType => {
    const newString = {
      ...notFilledNewString,
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['textLocalizations'],
        notFilledNewString.textLocalizations,
      ),
      ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
        ['audioLocalizations'],
        notFilledNewString.audioLocalizations,
      ),
      isReused: isReuse,
      mappings: isReuse ? notFilledNewString.mappings : { count: 1 },
    };

    switch (contentType) {
      case ContentTypes.roleplayCategory: {
        return produce(state, (draft) => {
          draft.roleplayCategory.content[fieldName] = newString;
          draft.roleplayCategory.content[`${fieldName}Changed`] = true;
        });
      }
      case ContentTypes.roleplayScenario: {
        return produce(state, (draft) => {
          draft.roleplayScenario.content[fieldName] = newString;
          draft.roleplayScenario.content[`${fieldName}Changed`] = true;
        });
      }
      default: {
        console.log('Unknown type');
        return state;
      }
    }
  },
  [RoleplayActions.SET_VALIDATION_RESULT]: (
    state: RoleplaySliceType,
    {
      payload,
    }: PayloadAction<{
      type: ContentTypesType;
      data: { validationStatus: ValidationInterface; changeStatus: ChangeStatusInterface };
    }>,
  ) => {
    const { type: contentType, data: validationResult } = payload;

    switch (contentType) {
      case ContentTypes.roleplay: {
        return produce(state, (draft) => {
          draft.roleplay.content.validationStatus = validationResult.validationStatus;
          draft.roleplay.content.changeStatus = validationResult.changeStatus;
        });
      }
      case ContentTypes.roleplayCategory: {
        return produce(state, (draft) => {
          draft.roleplayCategory.content.validationStatus = validationResult.validationStatus;
          draft.roleplayCategory.content.changeStatus = validationResult.changeStatus;
        });
      }
      case ContentTypes.roleplayScenario: {
        return produce(state, (draft) => {
          draft.roleplayScenario.content.validationStatus = validationResult.validationStatus;
          draft.roleplayScenario.content.changeStatus = validationResult.changeStatus;
        });
      }

      default: {
        console.log('Unknown Roleplay type');
      }
    }
  },

  // Roleplay Navigation
  [RoleplayActions.SET_LOADING_PARENT_ID]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<DBId | LanguageV2>,
  ) => {
    return produce(state, (draft) => {
      draft.navigation.loadingParentId = payload;
    });
  },
  [RoleplayActions.UPDATE_NAVIGATION]: (state: RoleplaySliceType, { payload }: PayloadAction<any>) => {
    return produce(state, (draft) => {
      draft.navigation.structure = payload;
    });
  },

  // Roleplay Category
  [RoleplayActions.ROLEPLAY_CATEGORY_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.ROLEPLAY_CATEGORY_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayCategoryContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.loaded = LoadingStage.loaded;
      draft.roleplayCategory.content = {
        ...payload,
        title:
          payload.title?.textLocalizations?.length || payload.title?.audioLocalizations?.length
            ? {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.title?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.title?.audioLocalizations,
                ),
              }
            : {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        type: ContentTypes.roleplayCategory,
      };
      draft.roleplayCategory.id = payload.id;
    });
  },
  [RoleplayActions.ROLEPLAY_SCENARIOS_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayCategoryListItemType[]>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.scenariosData.loaded = LoadingStage.loaded;
      draft.roleplayCategory.scenariosData.scenarios = payload.map((scenario) => ({
        ...scenario,
        type: ContentTypes.roleplayScenario,
      }));
    });
  },
  [RoleplayActions.SET_INITIAL_ROLEPLAY_CATEGORY]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.loaded = LoadingStage.notLoaded;
      draft.roleplayCategory.content = roleplayCategoryInitialContent;
      draft.roleplayCategory.scenariosData.loaded = LoadingStage.notLoaded;
      draft.roleplayCategory.scenariosData.scenarios = [];
    });
  },
  [RoleplayActions.SET_ROLEPLAY_CATEGORY_TITLE]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<TranslationsPanelContentInterface>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.content.title = {
        ...payload,
        ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
          ['textLocalizations'],
          payload.textLocalizations,
        ),
        ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
          ['audioLocalizations'],
          payload.audioLocalizations,
        ),
      };
    });
  },
  [RoleplayActions.SET_ROLEPLAY_CATEGORY_ICON]: (state: RoleplaySliceType, { payload }: PayloadAction<string>) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.content.icon = payload;
      draft.roleplayCategory.content.iconChanged = true;
    });
  },
  [RoleplayActions.SET_CATEGORY_ALL_VALUES]: (
    state: RoleplaySliceType,
    { payload: { values } }: PayloadAction<{ values: RoleplayCategoryFormikValues }>,
  ): RoleplaySliceType => {
    return produce(state, (draft) => {
      if (values.titleChanged && draft.roleplayCategory.content.title?.textLocalizations) {
        if (draft.roleplayCategory.content.title?.isReused) {
          draft.roleplayCategory.content.title.isReused = false;
        }

        draft.roleplayCategory.content.title.textLocalizations =
          draft.roleplayCategory.content.title.textLocalizations.map((loc) => ({
            ...loc,
            value: values.title.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.title.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }
    });
  },
  [RoleplayActions.SET_CATEGORY_TO_NOT_CHANGED]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayCategory.content.iconChanged = false;
      draft.roleplayCategory.content.titleChanged = false;
    });
  },

  // Scenario
  [RoleplayActions.SET_INITIAL_SCENARIO]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayScenario.loaded = LoadingStage.notLoaded;
      draft.roleplayScenario.content = roleplayScenarioInitialContent;
    });
  },
  [RoleplayActions.SCENARIO_LOADING]: (state: RoleplaySliceType) => {
    return produce(state, (draft) => {
      draft.roleplayScenario.loaded = LoadingStage.loading;
    });
  },
  [RoleplayActions.SCENARIO_LOADED]: (
    state: RoleplaySliceType,
    { payload }: PayloadAction<RoleplayScenarioContentType>,
  ) => {
    return produce(state, (draft) => {
      draft.roleplayScenario.loaded = LoadingStage.loaded;

      const phraseBookFieldNames = [
        'phraseBookPhrase1',
        'phraseBookPhrase2',
        'phraseBookPhrase3',
        'phraseBookPhrase4',
        'phraseBookPhrase5',
      ];

      const content = {
        ...payload,
        /**
         * Fields with localization
         */
        title:
          payload.title?.textLocalizations?.length || payload.title?.audioLocalizations?.length
            ? {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.title?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.title?.audioLocalizations,
                ),
              }
            : {
                ...payload.title,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        description:
          payload.description?.textLocalizations?.length || payload.description?.audioLocalizations?.length
            ? {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.description?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.description?.audioLocalizations,
                ),
              }
            : {
                ...payload.description,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        goal:
          payload.goal?.textLocalizations?.length || payload.goal?.audioLocalizations?.length
            ? {
                ...payload.goal,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.goal?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.goal?.audioLocalizations,
                ),
              }
            : {
                ...payload.goal,
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        // @TODO Make triggerMessages scalable like phrasebook
        triggerMessage1:
          payload.triggerMessages?.[0]?.textLocalizations?.length ||
          payload.triggerMessages?.[0]?.audioLocalizations?.length
            ? {
                ...payload.triggerMessages[0],
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['textLocalizations'],
                  payload.triggerMessages[0]?.textLocalizations,
                ),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                  ['audioLocalizations'],
                  payload.triggerMessages[0]?.audioLocalizations,
                ),
              }
            : {
                ...(payload.triggerMessages?.[0] ?? {}),
                ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                  'textLocalizations',
                  'audioLocalizations',
                ]),
              },
        ...phraseBookFieldNames.reduce((acc: Record<string, any>, phraseBookFieldName, index) => {
          acc[phraseBookFieldName] =
            payload.phrasebook?.[index]?.textLocalizations?.length ||
            payload.phrasebook?.[index]?.audioLocalizations?.length
              ? {
                  ...payload.phrasebook[index],
                  ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                    ['textLocalizations'],
                    payload.phrasebook?.[index]?.textLocalizations,
                  ),
                  ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranchesForSomeLanguages(
                    ['audioLocalizations'],
                    payload.phrasebook?.[index]?.audioLocalizations,
                  ),
                }
              : {
                  ...(payload.phrasebook?.[index] ?? {}),
                  ...ExerciseDataModelAssembler.prepareEmptyLocalizationBranches([
                    'textLocalizations',
                    'audioLocalizations',
                  ]),
                };

          return acc;
        }, {}),
        /**
         * Fields without localization
         */
        chatbotGoal: payload.meta?.chatbotGoal as string,
        chatbotRole: payload.meta?.chatbotRole as string,
        chatbotScenario: payload.meta?.chatbotScenario as string,
        grammar: payload.meta?.grammar as string,
        sampleDialogue: payload.meta?.sampleDialogue as string,
        vocabulary: payload.meta?.vocabulary as string,
        voiceAccent: payload.meta?.voice.accent as string,
        voiceGender: payload.meta?.voice.gender as string,
        type: ContentTypes.roleplayScenario,
      };

      // We don't need to store these arrays coming from Backend - delete to avoid ambiguity
      delete content.phrasebook;
      delete content.triggerMessages;

      draft.roleplayScenario.content = content;
      draft.roleplayScenario.id = payload.id;
    });
  },
  [RoleplayActions.SET_SCENARIO_IMAGE]: (
    state: RoleplaySliceType,
    { payload: { description, id, localizationId, value } }: PayloadAction<ImageDataType>,
  ) => {
    return produce(state, (draft) => {
      if (id === null) {
        draft.roleplayScenario.content.image = null;
      } else {
        draft.roleplayScenario.content.image = {
          description,
          id,
          localizationId,
          value,
          processed: false,
        };
      }
    });
  },
  [RoleplayActions.UPDATE_SCENARIO_FIELD]: (
    state: RoleplaySliceType,
    {
      payload: { fieldName, value },
    }: PayloadAction<{
      fieldName: RoleplayScenarioStringFieldNames | RoleplayScenarioNumberFieldNames;
      value: string | number;
    }>,
  ) => {
    return produce(state, (draft) => {
      if (fieldName === 'messageLimit') {
        draft.roleplayScenario.content.messageLimit = value as number;
      } else {
        draft.roleplayScenario.content[fieldName] = value as string;
      }
      draft.roleplayScenario.content[`${fieldName}Changed`] = true;
    });
  },
  [RoleplayActions.SET_MEDIA_VALUE_AFTER_PROCESSING]: (
    state: RoleplaySliceType,
    {
      payload: { contentType, mediaType, mediaUrl },
    }: PayloadAction<{ contentType: DBId; mediaType: MediaTypesType; mediaUrl: string }>,
  ) => {
    return produce(state, (draft) => {
      if (contentType === ContentTypes.roleplayScenario) {
        (draft.roleplayScenario.content[mediaType as keyof RoleplayScenarioContentType] as ImageDataType).value =
          mediaUrl;
        (draft.roleplayScenario.content[mediaType as keyof RoleplayScenarioContentType] as ImageDataType).processed =
          true;
      }
    });
  },
  [RoleplayActions.SET_SCENARIO_ALL_VALUES]: (
    state: RoleplaySliceType,
    { payload: { values } }: PayloadAction<{ values: RoleplayScenarioFormikValues }>,
  ): RoleplaySliceType => {
    return produce(state, (draft) => {
      if (values.titleChanged && draft.roleplayScenario.content.title?.textLocalizations) {
        if (draft.roleplayScenario.content.title?.isReused) {
          draft.roleplayScenario.content.title.isReused = false;
        }

        draft.roleplayScenario.content.title.textLocalizations =
          draft.roleplayScenario.content.title.textLocalizations.map((loc) => ({
            ...loc,
            value: values.title.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.title.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }

      if (values.descriptionChanged && draft.roleplayScenario.content.description?.textLocalizations) {
        if (draft.roleplayScenario.content.description?.isReused) {
          draft.roleplayScenario.content.description.isReused = false;
        }
        draft.roleplayScenario.content.description.textLocalizations =
          draft.roleplayScenario.content.description?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.description.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.description.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }

      if (values.goalChanged && draft.roleplayScenario.content.goal?.textLocalizations) {
        draft.roleplayScenario.content.goal.textLocalizations =
          draft.roleplayScenario.content.goal?.textLocalizations.map((loc) => ({
            ...loc,
            value: values.goal.find((value) => value.language === loc.language && !value.isPhonetic)?.value || '',
            phoneticValue:
              values.goal.find((value) => value.language === loc.language && value.isPhonetic)?.value || '',
          }));
      }

      if (values.phraseBookPhrase1Changed && draft.roleplayScenario.content.phraseBookPhrase1.textLocalizations) {
        draft.roleplayScenario.content.phraseBookPhrase1.textLocalizations =
          draft.roleplayScenario.content.phraseBookPhrase1?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.phraseBookPhrase1.find((value) => value.language === loc.language && !value.isPhonetic)?.value ||
              '',
            phoneticValue:
              values.phraseBookPhrase1.find((value) => value.language === loc.language && value.isPhonetic)?.value ||
              '',
          }));
      }

      if (values.phraseBookPhrase2Changed && draft.roleplayScenario.content.phraseBookPhrase2.textLocalizations) {
        draft.roleplayScenario.content.phraseBookPhrase2.textLocalizations =
          draft.roleplayScenario.content.phraseBookPhrase2?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.phraseBookPhrase2.find((value) => value.language === loc.language && !value.isPhonetic)?.value ||
              '',
            phoneticValue:
              values.phraseBookPhrase2.find((value) => value.language === loc.language && value.isPhonetic)?.value ||
              '',
          }));
      }

      if (values.phraseBookPhrase3Changed && draft.roleplayScenario.content.phraseBookPhrase3.textLocalizations) {
        draft.roleplayScenario.content.phraseBookPhrase3.textLocalizations =
          draft.roleplayScenario.content.phraseBookPhrase3?.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.phraseBookPhrase3.find((value) => value.language === loc.language && !value.isPhonetic)?.value ||
              '',
            phoneticValue:
              values.phraseBookPhrase3.find((value) => value.language === loc.language && value.isPhonetic)?.value ||
              '',
          }));
      }

      if (values.phraseBookPhrase4Changed && draft.roleplayScenario.content.phraseBookPhrase4.textLocalizations) {
        draft.roleplayScenario.content.phraseBookPhrase4.textLocalizations =
          draft.roleplayScenario.content.phraseBookPhrase4.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.phraseBookPhrase4.find((value) => value.language === loc.language && !value.isPhonetic)?.value ||
              '',
            phoneticValue:
              values.phraseBookPhrase4.find((value) => value.language === loc.language && value.isPhonetic)?.value ||
              '',
          }));
      }

      if (values.phraseBookPhrase5Changed && draft.roleplayScenario.content.phraseBookPhrase5.textLocalizations) {
        draft.roleplayScenario.content.phraseBookPhrase5.textLocalizations =
          draft.roleplayScenario.content.phraseBookPhrase5.textLocalizations.map((loc) => ({
            ...loc,
            value:
              values.phraseBookPhrase5.find((value) => value.language === loc.language && !value.isPhonetic)?.value ||
              '',
            phoneticValue:
              values.phraseBookPhrase5.find((value) => value.language === loc.language && value.isPhonetic)?.value ||
              '',
          }));
      }
    });
  },

  // Translation Panel
  [RoleplayActions.SET_AUDIO]: (
    state: RoleplaySliceType,
    {
      payload: { localization, fieldName, mediaURL, type },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: RoleplayCategoryFieldWithLocalizationNames;
      mediaURL: string;
      type: ContentTypesType;
    }>,
  ): RoleplaySliceType => {
    return produce(state, (draft) => {
      const contentName = type === ContentTypes.roleplayCategory ? 'roleplayCategory' : 'roleplayScenario';

      draft[contentName].content[`${fieldName}Changed`] = true;

      if (draft[contentName].content[fieldName]) {
        draft[contentName].content[fieldName].audioLocalizations = draft[contentName].content[
          fieldName
        ]?.audioLocalizations.map((loc) => {
          if (loc.language === localization.language) {
            return {
              ...loc,
              value: !mediaURL ? '' : loc.value,
              fileId: mediaURL || null,
              _id: mediaURL || undefined,
              type: 'audio',
              processed: false,
            };
          }
          return loc;
        });
      }
    });
  },
  [RoleplayActions.REMOVE_AUDIO]: (
    state: RoleplaySliceType,
    {
      payload: { localization, fieldName, type },
    }: PayloadAction<{
      localization: LocalizationInterface;
      fieldName: RoleplayCategoryFieldWithLocalizationNames;
      type: ContentTypesType;
    }>,
  ): RoleplaySliceType => {
    return produce(state, (draft) => {
      const contentName = type === ContentTypes.roleplayCategory ? 'roleplayCategory' : 'roleplayScenario';

      draft[contentName].content[fieldName].audioLocalizations = draft[contentName].content[
        fieldName
      ].audioLocalizations.map((loc) =>
        loc.language === localization.language
          ? {
              ...loc,
              value: '',
              fileId: null,
              _id: undefined,
              processed: false,
            }
          : loc,
      );
    });
  },
  [PublishingActions.CHANGE_READY_STATE]: (
    state: RoleplaySliceType,
    {
      payload: { contentType, id, forUpdate },
    }: PayloadAction<{ contentType: ContentTypesType; id: DBId; forUpdate: boolean }>,
  ): RoleplaySliceType => {
    switch (contentType) {
      case ContentTypes.roleplay:
        return produce(state, (draft) => {
          draft.roleplay.content.ready = forUpdate || !state.roleplay.content.ready;
          draft.roleplay.content.changeStatus.hasNewChanges = state.roleplay.content.ready && !forUpdate;
          draft.roleplay.content.changeStatus.hasPendingChanges = true;
        });
      case ContentTypes.roleplayCategory:
        return produce(state, (draft) => {
          draft.roleplayCategory.content.ready = forUpdate || !state.roleplayCategory.content.ready;
          draft.roleplayCategory.content.changeStatus.hasNewChanges =
            draft.roleplayCategory.content.ready && !forUpdate;
          draft.roleplayCategory.content.changeStatus.hasPendingChanges = true;

          // update parent
          draft.roleplay.categoriesData.categories = draft.roleplay.categoriesData.categories.map((category) =>
            category.id === id
              ? {
                  ...category,
                  ready: forUpdate || !category.ready,
                  changeStatus: { hasNewChanges: category.ready && !forUpdate, hasPendingChanges: true },
                }
              : category,
          );
        });
      case ContentTypes.roleplayScenario:
        return produce(state, (draft) => {
          draft.roleplayScenario.content.ready = forUpdate || !state.roleplayScenario.content.ready;
          draft.roleplayScenario.content.changeStatus.hasNewChanges =
            draft.roleplayScenario.content.ready && !forUpdate;
          draft.roleplayScenario.content.changeStatus.hasPendingChanges = true;

          // update parent
          draft.roleplayCategory.scenariosData.scenarios = draft.roleplayCategory.scenariosData.scenarios.map(
            (scenario) =>
              scenario.id === id
                ? {
                    ...scenario,
                    ready: forUpdate || !scenario.ready,
                    changeStatus: { hasNewChanges: scenario.ready && !forUpdate, hasPendingChanges: true },
                  }
                : scenario,
          );
        });
      default:
        console.error('Roleplay: Unknown content type on publish');
        return state;
    }
  },
};
