import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components/macro';

const GRID_TEMPLATE_COLUMNS = '30% 45% 10% 15%';

export const StyledTable = styled.table`
  font-size: 1.4rem;
  position: relative;
  width: 100%;
`;

export const StyledTableHead = styled.thead`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.uiBackgroundQuaternary};
    display: grid;
    grid-template-columns: ${GRID_TEMPLATE_COLUMNS};
    left: 0;
    margin: 0 calc(${theme.space.M} * -1) ${theme.space.M};
    padding: 0 ${theme.space.M};
    position: sticky;
    top: 0;
    z-index: 1;
  `}
`;

export const StyledTableHeader = styled.th.attrs(() => ({ scope: 'col' }))`
  ${({ theme }) => css`
    color: ${theme.colorV2.textTertiary};
    display: flex;
    gap: ${theme.space.M};
    padding: ${theme.space.M} 0;

    &:first-child {
      padding-left: ${theme.space.L};
    }
  `}
`;

export const GroupLabelRow = styled.tr`
  position: relative;
`;

export const GroupLabelTableHeader = styled.th.attrs(() => ({ scope: 'colgroup' }))`
  ${({ theme }) => css`
    background-image: linear-gradient(to bottom, ${theme.colorV2.uiBackgroundQuaternary}, ${theme.colorV2.uiLightest});
    font-weight: 400;
    left: ${theme.space.M};
    padding: 0 ${theme.space.XS};
    position: absolute;
    top: -1.1rem;
  `}
`;

export const StringDataRow = styled.tr`
  ${({ theme }) => css`
    background-color: ${theme.colorV2.uiBackgroundPrimary};
    border-left: solid 0.1rem ${theme.colorV2.uiDivider};
    border-right: solid 0.1rem ${theme.colorV2.uiDivider};
    box-shadow: 0px 2px 4px 0px #00000017;
    display: grid;
    grid-template-columns: ${GRID_TEMPLATE_COLUMNS};

    /* tr right after a group label */
    [data-group-label] + & {
      border-top: solid 0.1rem ${theme.colorV2.uiDivider};
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      padding-top: ${theme.space.L};
    }

    /* tr right before a group label or last tr */
    &[data-group-last-string],
    &:last-child {
      border-bottom: solid 0.1rem ${theme.colorV2.uiDivider};
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
      padding-bottom: ${theme.space.L};
      margin-bottom: ${theme.space.L};
    }

    &:hover > td {
      background-color: ${theme.colorV2.uiBackgroundSecondary};
    }
  `}
`;

export const StringDataCell = styled.td`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.space.M};
    padding: ${theme.space.S};
    overflow: hidden;

    &:first-child {
      padding-left: ${theme.space.L};
    }

    &:last-child {
      padding-right: ${theme.space.L};
    }
  `}
`;

export const Truncation = styled.div<{ lines: number }>`
  display: -webkit-box;
  max-height: 5.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
`;

export const StyledTooltip = styled(ReactTooltip)`
  font-size: 1.6rem !important;
  max-width: 53rem;
`;

export const NoDataMessageRow = styled.tr`
  text-align: center;
`;

export const NoDataMessageCell = styled.td`
  color: ${({ theme }) => theme.colorV2.textSecondary};
  padding-top: 22rem;
`;
