import { OtherLocationsInterface } from '@common/interfaces/exercises/OtherLocationsInterface';
import { ContentTypes, ContentType } from '@common/enums/ContentTypes';

import { getLearningLanguageFromRoleplayId } from '@features/content/roleplay';

const coursePathRegex = /\/course\/[\w]+/;

export const getLink = (currentWholeLocation: OtherLocationsInterface[]) => {
  const newURL = currentWholeLocation.reduce((result: string, item: OtherLocationsInterface) => {
    if (item.type === ContentTypes.course) {
      return `/${item.type}/${item.id}`;
    } else if (item.type === ContentTypes.grammarReview) {
      return `${result}/grammar-review`;
    } else if (item.type === ContentTypes.grammarCategory) {
      return `${result}/category/${item.id}`;
    } else if (item.type === ContentTypes.grammarTopic) {
      return `${result}/topic/${item.id}`;
    } else if (item.type === ContentTypes.placementTest) {
      return `${result}/placement-test`;
    } else if (item.type === ContentTypes.entrypoint) {
      return `${result}/entrypoint/${item.id}`;
    } else if (item.type === ContentTypes.roleplay) {
      // Roleplay is attached to Course in DB, remove it to get the correct path
      if (coursePathRegex.test(result)) {
        result = result.replace(coursePathRegex, '');
      }
      return `${result}/roleplay/${getLearningLanguageFromRoleplayId(item.id)}`;
    } else if (item.type === ContentTypes.roleplayCategory) {
      return `${result}/category/${item.id}`;
    } else if (item.type === ContentTypes.roleplayScenario) {
      return `${result}/scenario/${item.id}`;
    } else if (item.type === ContentType.group) {
      return `${result}/${item.groupType}/${item.id}`;
    } else if (item.type === ContentTypes.exercise) {
      return `${result}/${item.type}/${item.id}`;
    } else {
      return result;
    }
  }, '');

  return newURL;
};
