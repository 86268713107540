import { ContentTypes } from '@common/enums/ContentTypes';
import { EXERCISES, type ExerciseType } from '@common/enums/ExerciseTypes';
import { ACTIVITIES, type ActivityType } from '@features/content/activities';
import type { LanguageV2 } from '@features/content/languages';
import type { NavigationItemType } from '@features/content/navigation';

import isRoleplay from './isRoleplay';

const getCourseContentUrl = (content: NavigationItemType, structure: NavigationItemType[]) => {
  const courseId = structure.find((element) => element.type === ContentTypes.course)?.id;
  switch (content.type) {
    case ContentTypes.course:
      return `/course/${courseId}`;
    case ContentTypes.level:
      return `/course/${courseId}/level/${content.id}`;
    case ContentTypes.chapter:
      return `/course/${courseId}/level/${content.parentId}/chapter/${content.id}`;
    case ContentTypes.lesson:
    case ContentTypes.checkpoint:
    case ContentTypes.certificate:
    case ContentTypes.liveLesson:
    case ContentTypes.review:
    case ContentTypes.roleplay:
    case ContentTypes.speakingLesson:
      const levelId = structure?.find((item) => item.id === content.parentId)?.parentId;
      return `/course/${courseId}/level/${levelId}/chapter/${content.parentId}/lesson/${content.id}`;

    default:
      if (ACTIVITIES.includes(content.type as ActivityType) && content.hasOwnProperty('oldType')) {
        const chapterId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${content.parentId}/activity/${content.id}`;
      }

      if (
        EXERCISES.includes(content.type as ExerciseType) &&
        content.type !== ContentTypes.slidePptx &&
        content.type !== ContentTypes.slidePdf
      ) {
        const lessonId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const chapterId = structure?.find((item) => item.id === lessonId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${lessonId}/activity/${content.parentId}/exercise/${content.id}`;
      } else if (
        EXERCISES.includes(content.type as ExerciseType) &&
        (content.type === ContentTypes.slidePptx || content.type === ContentTypes.slidePdf)
      ) {
        const chapterId = structure?.find((item) => item.id === content.parentId)?.parentId;
        const levelId = structure?.find((item) => item.id === chapterId)?.parentId;

        return `/course/${courseId}/level/${levelId}/chapter/${chapterId}/lesson/${content.parentId}/exercise/${content.id}`;
      } else {
        return `/course/${courseId}/content/${content.id}`;
      }
  }
};

const getRoleplayContentUrl = (content: NavigationItemType, language: LanguageV2, structure: NavigationItemType[]) => {
  if (content.type === ContentTypes.roleplay) {
    return `/roleplay/${language}`;
  }
  if (content.type === ContentTypes.roleplayCategory) {
    return `/roleplay/${language}/category/${content.id}`;
  }
  if (content.type === ContentTypes.roleplayScenario) {
    const categoryId = structure.find((element) => element.id === content.parentId)?.id;
    return `/roleplay/${language}/category/${categoryId}/scenario/${content.id}`;
  }
  return '';
};

const getNavigationItemUrl = ({
  content,
  language,
  structure,
}: {
  content: NavigationItemType;
  language?: LanguageV2 | undefined;
  structure: NavigationItemType[];
}) => {
  if (isRoleplay(content.type) && !!language) {
    return getRoleplayContentUrl(content, language, structure);
  } else {
    return getCourseContentUrl(content, structure);
  }
};

export default getNavigationItemUrl;
