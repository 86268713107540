import { MouseEvent, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ContentTypes } from '@common/enums/ContentTypes';
import { ContextualMenu } from '@features/theme';
import { isFeatureEnabled } from '@helpers/featuresHelper';

import type { NavigationItemType } from '../types';
import { useOnClickOutside } from '../useOnClickOutside';
import { ReactComponent as CopyIdIcon } from './_img/copy.svg';
import { ReactComponent as MoveIcon } from './_img/move.svg';
import { CONTENT_ACTIONS_MENU_TRIGGER_PREFIX, TEST_ID_CONTENT_ACTIONS_MENU_BACKDROP } from './constants';
import { isMoveable } from './_helpers/isMoveable';
import { ContentActionsMenuBackdrop, ContentActionsOption } from './styles';

type ContentActionsMenuPropsType = {
  selectedContent: NavigationItemType | null;
  show: boolean;
  onClickOutside: (evt: MouseEvent<any>) => void;
  onCopyId: (evt: MouseEvent<HTMLDivElement>) => void;
  onMoveContent: () => void;
};

export const ContentActionsMenu = ({
  selectedContent,
  show,
  onClickOutside,
  onCopyId,
  onMoveContent,
}: ContentActionsMenuPropsType) => {
  const selectedContentId = selectedContent?.id;
  const triggerClientRect = selectedContent
    ? document.getElementById(`${CONTENT_ACTIONS_MENU_TRIGGER_PREFIX}-${selectedContentId}`)?.getBoundingClientRect()
    : undefined;

  const contentActionsMenuRef = useRef(null);
  const onClick = useOnClickOutside<HTMLDivElement>(contentActionsMenuRef, onClickOutside);

  // Check if FF is enabled ONLY for roleplay scenarios
  const showMoveOption =
    selectedContent?.type === ContentTypes.roleplayScenario
      ? isFeatureEnabled('reusingInNewLocation') && isMoveable(selectedContent?.type)
      : isMoveable(selectedContent?.type);

  return (
    <>
      <ContextualMenu position={triggerClientRect} ref={contentActionsMenuRef} show={show} variant="small">
        <CopyToClipboard text={selectedContentId as string}>
          <ContentActionsOption onClick={onCopyId}>
            <CopyIdIcon />
            Copy ID
          </ContentActionsOption>
        </CopyToClipboard>
        {showMoveOption ? (
          <ContentActionsOption onClick={onMoveContent}>
            <MoveIcon />
            Move
          </ContentActionsOption>
        ) : null}
      </ContextualMenu>
      <ContentActionsMenuBackdrop data-testid={TEST_ID_CONTENT_ACTIONS_MENU_BACKDROP} show={show} onClick={onClick} />
    </>
  );
};
