import styled, { css } from 'styled-components/macro';

import { Warning } from '@components/Warning';
import { Loader } from '@features/theme';
import { ReactComponent as ExclamationIcon } from '@static/svg/exclamation.svg';
import { ReactComponent as CloseIcon } from '@static/svg/x-icon.svg';

import { ReactComponent as EditIcon } from './_img/edit.svg';

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textPrimary};
    font-weight: 800;
    font-size: 2.4rem;
    margin-bottom: ${theme.space.S};
  `}
`;

export const ModalDescription = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textSecondary};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 150%;
    margin-bottom: ${theme.space.L};
  `}
`;

export const ModalFieldsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.space.L};
    height: 31rem;
    margin-bottom: ${theme.space.L};
  `}
`;

export const ModalFieldsContainerCol = styled.div`
  flex: 1;
`;

export const StyledWarning = styled((props) => <Warning {...props} />)`
  margin-bottom: ${({ theme }) => theme.space.L};
`;

export const UploadFileArea = styled.label`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.uiBackgroundSecondary};
    border: solid 0.1rem ${theme.colorV2.input.border.default};
    border-radius: 0.8rem;
    display: flex;
    height: calc(100% - ${theme.space.S});
    justify-content: center;
  `}
`;

export const UploadFileName = styled.div`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colorV2.accentPrimary};
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: 0 ${theme.space.M};
    min-height: 3.2rem;
    width: 100%;
  `}
`;

export const UploadFileEditIcon = styled(EditIcon)`
  min-width: 3.2rem;
`;

export const UploadFailedWarning = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colorV2.utilityErrorBackground};
    border-radius: 1.2rem;
    color: ${theme.colorV2.utilityError};
    display: flex;
    flex: 1;
    font-size: 1.4rem;
    gap: ${theme.space.S};
    line-height: 5rem;
    padding: 0 ${theme.space.M};
    margin-bottom: ${theme.space.L};
  `}
`;

export const UploadFailedWarningIcon = styled(ExclamationIcon)`
  height: 2.4rem;
  width: 2.4rem;

  path {
    fill: ${({ theme }) => theme.colorV2.utilityError};
  }
`;

export const UploadingProgressContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 32rem;
  justify-content: center;
  position: relative;
  text-align: center;
`;

export const UploadingProgressLoader = styled(Loader).attrs(() => ({ size: 'L' }))`
  height: 50% !important;
  margin-bottom: 0;
`;

export const UploadingProgressCloseIcon = styled(CloseIcon)`
  ${({ theme }) => css`
    height: 2rem;
    position: absolute;
    right: calc(${theme.space.M} * -1);
    top: calc(${theme.space.M} * -1);
    width: 2rem;

    :hover {
      cursor: pointer;
    }

    path {
      fill: ${theme.colorV2.textQuaternary};
    }
  `}
`;

export const ModalButtonArea = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.M};
  justify-content: flex-end;
`;
