import styled, { css } from 'styled-components/macro';

export const ModalTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorV2.textPrimary};
    font-weight: 800;
    font-size: 3rem;
    margin-bottom: ${theme.space.L};
  `}
`;

export const ModalStringsListContainer = styled.div`
  ${({ theme }) => css`
    align-items: flex-start;
    background-color: ${theme.colorV2.uiBackgroundQuaternary};
    display: flex;
    gap: ${theme.space.L};
    height: 61rem;
    margin-bottom: ${theme.space.L};
    overflow: scroll;
    padding: 0 ${theme.space.M};
    position: relative;
  `}
`;

export const ModalButtonArea = styled.div`
  ${({ theme }) => css`
    border-top: solid 1px ${theme.colorV2.uiDivider};
    display: flex;
    gap: ${theme.space.M};
    justify-content: flex-end;
    padding-top: ${theme.space.L};
  `}
`;
