import styled, { css } from 'styled-components/macro';

import { CommonFilterSelector, LanguageSelector } from '@components/SelectorCommonComponents';

const refreshIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABExJREFUaEPtmWuoTlkYx3/Hddym3Bn3y0STmYjkmg/uuSQ+IEOaSUeiqWmGREqoiRJyV5RbkgbTIEaTycQXhUlqLhiXI9eIjOsZ+mvt037Xu/d79tqXOedMZ9XbW3uv5/Jfz7Oe9az/LqKKj6Iq7j/VACo6gmlGoCcwEtB/d6ADUAtoANwzv9+Bs8CvwDngbdIFSAqgNTAHmAm0dXTmCrAH2ATccZQtmx4XQBNgOfAlUCeucSP3HNgMrAAeRtA1Fthi5hXHATANWAM0i2DMZYqiUAz8UI7QLaCNmVPiAqA2sBqYG2LgKfCT+f0G/AU8A14CLYB2QF9gMKBVrBeiZy3wNfBvyPucfRMVwAfAQWBUgNI/DbCdwD8Rl7wRoEguBNoHyHwPfA4ovezhDKAucCjAeTm71KTTq4iO29O0f74FlgTspV+A8cATS8gZwAZTafx6lB6TAKVKGkOl93BANFRqRwMPfEacAEwF9loeyumhltI0QHwE/Aj0spRdBoYDt83zyABUKnXw+KuN8n0gcD8NjwN0NAaOAP2td9eAYcBV+/ArtInt1HkBDADOZ+S8p1YntzbxCMuOyqxO+ov+52EAVGeFWqXTG/OBVRk776lX4dgHTLDs6aBrGgXAMmCxb+IfwKdA3GoTB7f6qO3A9ELCYRG4blUEnZBb43iRUKYGoIMt7PAMvQ8ofToa4wqbOkudqlkMr7dRFXIeYRFQ7d0GaAXUbeogy2r4extnG1FbCWfFDgJJAJRWBgBxU6hULXhlAOAQrPyp1QASLV8Kwv+LCKinXwTUdFwQdYc64NRBVthQBN7EcN5zuCQGG5EqWAFIws1kDUDN3EZzP54FHLPRJwGQdQqprVZP5nWffwOdXAGsB74qwBCkmg6WstmG9PIe3zA9Wc60KBHYBXxh9kqWDvt16z5wCejqe6gWX5f/cgHkXRpMMzfFcDz/BYgFwHc+Q69Nd+zdi8teBUXgE+BEQHU5ZWgOEVhZjs8MAVzfZ2SdSeU8u0EA9KwzcDJg04hZHgM8yghBS+CMse+ZEIHQLcxmGAAJ64IhqlAR8Q/RKrov5IUzIajmwM9AD0vPZGB/mO5CACQjSkW1t4+lQBVBNTothkJc0AFr5WUyNHU8f8oDoHkfGsZ4iAVCF3xR7CsTbG5Vm28MgSD+1T/UokwEtIFDRxQAEhaTvNsotJXdNBVD720eM8xwQ2CGYaG7BEwSuSXqUsx2wREVgJTofix6XQdb0BCTfBQ4bT4f6RTVBlQ1EYn7sclvEVaiCgUiaIiFEOFbcOX9KZTzwSBCczbOUCytylsdx/f6jjav0IYN0qcI5HyyidgeizcVty/Gwl+vHX1+P11pIs5Jp+xjVwVJLzSq2+pZ9DHCf+xH8UOVbIfpd+5GEQiLQFxZW643MAjoZ/JdXzDVSepDiD4XaU+I7b4AHLdJ2rhOJI1AXLupyVUDSG0pYyqq8hF4BwVvytLtZFpdAAAAAElFTkSuQmCC';

export const FilterContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space.M};
`;

export const StyledCommonFilterSelector = styled(CommonFilterSelector)`
  width: 100% !important;

  [role='option']:first-of-type {
    background: url(${refreshIcon}) no-repeat 1.2rem center / 1.8rem 1.8rem;
    padding-left: ${({ theme }) => theme.space.XL};
  }
`;

export const StyledLanguageSelector = styled(LanguageSelector)<{ disabled: boolean }>`
  ${({ disabled, theme }) => css`
    width: 100% !important;

    .language-selector__control {
      background: ${disabled ? theme.colorV2.uiBackgroundSecondary : 'inherit'};
    }

    // As no 'rule' prop here, hide ':' in custom value container
    .language-selector__value-container::before {
      display: none;
    }
  `}
`;

export const DueDateInput = styled.input`
  ${({ theme }) => css`
    border: solid 0.1rem ${theme.colorV2.input.border.default};
    border-radius: 0.8rem;
    color: ${theme.colorV2.input.color.default};
    font-size: 1.5rem;
    line-height: 3.8rem;
    padding: 0 ${theme.space.S};
    width: 100%;

    &::placeholder {
      color: ${theme.colorV2.textTertiary};
    }
  `}
`;
