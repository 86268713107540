import { flatten } from 'lodash';

import { DBId } from '@common/types/DBId';

import type { StringTable } from '../types';

/** Returns all string ids by iterating strings data object  */
export const getAllStringsIds = (stringsData?: StringTable): DBId[] => {
  if (!stringsData) return [];

  const stringDataFlatArray = flatten(Object.values(stringsData));
  const allStringsIds = stringDataFlatArray.reduce((acc: DBId[], group) => {
    const groupStringsIds = group.items.map((item) => item.id);
    (acc as DBId[]).push(...groupStringsIds);

    return acc;
  }, []);

  return allStringsIds;
};
