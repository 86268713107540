import { DBId } from '@common/types/DBId';
import { Checkbox } from '@features/theme';

import { StringTableGroup } from '../types';
import {
  GroupLabelRow,
  GroupLabelTableHeader,
  StringDataCell,
  StringDataRow,
  StyledTooltip,
  Truncation,
} from './styles';

const MAX_STRING_LENGTH_TO_DISPLAY = 150;

type StringsGroupProps = {
  data: StringTableGroup;
  selectedStrings: DBId[];
  onToggle: (selectedString: DBId) => void;
};

export const StringsGroup = ({ data, selectedStrings, onToggle }: StringsGroupProps) => {
  const { label, items } = data;
  const visibleItems = items.filter((item) => item.visible);

  const getStringRowProps = (index: number) => {
    const props: { 'data-group-last-string'?: string } = {};
    const countVisibleItems = visibleItems.length;

    if (countVisibleItems === 1 || index === countVisibleItems - 1) {
      props['data-group-last-string'] = '';
    }

    return props;
  };

  return (
    <>
      <GroupLabelRow data-group-label>
        <GroupLabelTableHeader colSpan={4}>{label}</GroupLabelTableHeader>
      </GroupLabelRow>
      {visibleItems.map((item, index) => (
        <StringDataRow {...getStringRowProps(index)} key={`string-row-${item.id}`}>
          <StringDataCell>
            <Checkbox
              checked={selectedStrings.includes(item.id)}
              dataTestId={`string-toggle-${item.id}`}
              key={item.id}
              onToggle={() => onToggle(item.id)}
            />
            {item.id}
          </StringDataCell>
          <StringDataCell data-tip={item.value} data-for={`string-value-tooltip-${item.id}`}>
            <Truncation dangerouslySetInnerHTML={{ __html: item.value ?? '<em>Empty string</em>' }} lines={2} />
            {item.value && item.value.length > MAX_STRING_LENGTH_TO_DISPLAY && (
              <StyledTooltip effect="solid" id={`string-value-tooltip-${item.id}`} place="bottom" />
            )}
          </StringDataCell>
          <StringDataCell>{item.translations}</StringDataCell>
          <StringDataCell>{item.context}</StringDataCell>
        </StringDataRow>
      ))}
    </>
  );
};
