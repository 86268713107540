import { MouseEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import type { DBId } from '@common/types/DBId';
import { ChildrenArray, type NavigationItemType } from '@features/content/navigation';
import type { LanguageV2 } from '@features/content/languages';

import { getNavigationItemTitle, getNavigationItemUrl, isRoleplay, isValidTargetToMoveContent } from '../_helpers';

import { NavigationItemLabel } from './NavigationItemLabel';

type NavigationItemV2PropsType = {
  content: NavigationItemType;
  selectedContent?: NavigationItemType | null;
  currentPathIds: string[];
  isInMoveContentModal?: boolean;
  loadingParentId: string;
  selectedTargetLocationId?: DBId;
  structure: NavigationItemType[];
  onExpand: (type: ContentTypesType, id: DBId) => void;
  onSelect?: (contentId: DBId) => void;
  onToggleContentActionsMenu?: (evt: MouseEvent<HTMLElement>, content: NavigationItemType | null) => void;
};

export const NavigationItemV2 = ({
  content,
  currentPathIds,
  isInMoveContentModal,
  loadingParentId,
  selectedContent,
  selectedTargetLocationId,
  structure,
  onExpand,
  onSelect,
  onToggleContentActionsMenu,
}: NavigationItemV2PropsType) => {
  const { languageId, exerciseId } = useParams<{
    languageId?: LanguageV2;
    exerciseId?: DBId;
  }>();
  const [expanded, setExpanded] = useState(content.expanded);

  const isRoleplayContent = isRoleplay(content.type);

  const isSelectable = isInMoveContentModal && isValidTargetToMoveContent({ content, selectedContent, structure });
  // does this navigation item match the current path (the user is on this page)?
  const isOnCurrentPath = currentPathIds.includes(content.id);

  useEffect(() => {
    if (!expanded && !isSelectable && isOnCurrentPath) {
      setExpanded(true);
      content.children && handleExpand(content.type as ContentTypesType, content.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnCurrentPath]);

  useEffect(() => {
    if (!expanded && !isSelectable && content.id === loadingParentId) {
      setExpanded(true);
      content.children && handleExpand(content.type as ContentTypesType, content.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, expanded, isSelectable, loadingParentId]);

  const handleExpand = (type: ContentTypesType, id: DBId) => {
    setExpanded(!expanded);
    if (!expanded) {
      onExpand(type, id);
    }
  };

  const getChildrenAmount = () => {
    const childrenArray = structure.filter((element) => element.parentId === content.id);
    const grandChildrenArray = structure.filter(
      (element) => element.parentId && childrenArray.map((element) => element.id).includes(element.parentId),
    );

    if (content.type === ContentTypes.course) {
      return structure.filter((element) => element.type !== ContentTypes.course).length;
    }

    const grandGrandChildrenArray = structure.filter(
      (element) => element.parentId && grandChildrenArray.map((element) => element.id).includes(element.parentId),
    );
    const grandGrandGrandChildrenArray = structure.filter(
      (element) => element.parentId && grandGrandChildrenArray.map((element) => element.id).includes(element.parentId),
    );

    return [...childrenArray, ...grandChildrenArray, ...grandGrandChildrenArray, ...grandGrandGrandChildrenArray]
      .length;
  };

  // @TODO: make common function to check is content legacy
  const isLegacyActivity = content?.type === 'entity' || (content?.type === 'quiz' && content?.oldType === 'review');
  const disabledForMoveContentModal = isInMoveContentModal && content.id === selectedContent?.parentId;

  return (
    <>
      <NavigationItemLabel
        content={content}
        currentPathIds={currentPathIds}
        disabled={!isRoleplay && (isLegacyActivity || disabledForMoveContentModal)}
        exerciseId={exerciseId || ''}
        expanded={expanded}
        handleExpand={handleExpand}
        isSelectable={isSelectable}
        isSelected={content.id === selectedTargetLocationId}
        loadingParentId={loadingParentId}
        selectedContent={selectedContent}
        title={getNavigationItemTitle({ content, language: isRoleplayContent ? languageId : undefined, structure })}
        url={getNavigationItemUrl({ content, language: isRoleplayContent ? languageId : undefined, structure })}
        onSelect={onSelect}
        onToggleContentActionsMenu={onToggleContentActionsMenu}
      />
      <ChildrenArray
        visible={content.children && expanded && (!isInMoveContentModal || !disabledForMoveContentModal)}
        childrenAmount={getChildrenAmount()}
      >
        {structure
          .filter((element) => element.parentId === content.id)
          .map((element) => (
            <NavigationItemV2
              content={element}
              currentPathIds={currentPathIds}
              isInMoveContentModal={isInMoveContentModal}
              key={element.id}
              loadingParentId={loadingParentId}
              selectedContent={selectedContent}
              selectedTargetLocationId={selectedTargetLocationId}
              structure={structure}
              onExpand={onExpand}
              onSelect={onSelect}
              onToggleContentActionsMenu={onToggleContentActionsMenu}
            />
          ))}
      </ChildrenArray>
    </>
  );
};
