import { flatten } from 'lodash';
import { useCallback, useEffect, useReducer, useState } from 'react';

import { DBId } from '@common/types/DBId';
import { useCache } from '@features/app/cache';
import { userTracking } from '@features/app/tracking';
import { LanguageV2 } from '@features/content/languages';
import { Button, Loader } from '@features/theme';

import {
  areFiltersSelected,
  DownloadTranslationRequestFilters,
  type FiltersState,
} from './DownloadTranslationRequestFilters';
import { DownloadTranslationRequestService } from './DownloadTranslationRequestService';
import { StringsTable } from './StringsTable';

import { getAllStringsIds } from './_helpers/getAllStringsIds';
import { getFilteredStringDataState } from './_helpers/getFilteredStringDataState';
import { mapStringsRawDataToStringsTableData } from './_helpers/mapStringsRawDataToStringsTableData';
import type { StringsListPayload, StringTable, StringTableGroup } from './types';
import { ModalButtonArea, ModalStringsListContainer, ModalTitle } from './styles';
import { ContentTypes } from '@common/enums/ContentTypes';

const FILTERS_INITIAL_STATE: FiltersState = {
  pendingTranslationsOnly: false,
  query: '',
};

/**
 * String List cache
 * 1. in-memory cache fallbacks to
 * 2. session storage fallbacks to
 * 3. API request
 *
 * Cache TTL: 15 min;
 */
const STRINGS_LIST_STORAGE_KEY = 'strings-list';
const STRINGS_LIST_LAST_UPDATED_STORAGE_KEY = `${STRINGS_LIST_STORAGE_KEY}-last-updated`;
const STRING_LIST_CACHE_TTL = 15 * 60 * 1000; // ms

let stringsListCache: Record<DBId, StringTable> = {};
let stringsListCacheLastUpdated = '';

type DownloadTranslationRequestModalProps = {
  contentIds: StringsListPayload;
  contentName: string;
  interfaceLanguages: LanguageV2[];
  isOpen: boolean;
  learningLanguage: LanguageV2;
  onCancel: () => void;
  onDownload: () => void;
  onError: (description: string) => void;
};

export const DownloadTranslationRequestModal = ({
  contentIds,
  contentName,
  interfaceLanguages,
  isOpen,
  learningLanguage,
  onCancel,
  onDownload,
  onError,
}: DownloadTranslationRequestModalProps) => {
  /** Init state */
  const [countAllStrings, setCountAllStrings] = useState(0);
  const [filters, setFilters] = useReducer(
    (state: FiltersState, action: Partial<FiltersState & { reset: boolean }>) => {
      if (action.reset) return FILTERS_INITIAL_STATE;
      return { ...state, ...action };
    },
    FILTERS_INITIAL_STATE,
  );
  const [selectedStrings, setSelectedStrings] = useState<DBId[]>([]);
  const [stringsData, setStringsData] = useState<StringTable | undefined>();
  const [stringsPreselected, setStringsPreselected] = useState(false);

  const [storedStringsListCache, shouldRefreshStringsListCache, updateStringsListStorage] = useCache({
    expirationInterval: STRING_LIST_CACHE_TTL,
    lastUpdated: stringsListCacheLastUpdated,
    lastUpdatedStorageKey: STRINGS_LIST_LAST_UPDATED_STORAGE_KEY,
    storageKey: STRINGS_LIST_STORAGE_KEY,
  });

  const cleanUp = () => {
    setCountAllStrings(0);
    setFilters({ reset: true });
    setSelectedStrings([]);
    setStringsData(undefined);
    setStringsPreselected(false);
  };

  const fetchStringsList = useCallback(() => {
    const cacheKey = contentIds.lessonId;
    let cache = stringsListCache[cacheKey] ?? storedStringsListCache?.[cacheKey];

    if (shouldRefreshStringsListCache() || !cache) {
      DownloadTranslationRequestService.getStringsList(contentIds).then((result) => {
        const { data } = result;
        const stringsDataValue = mapStringsRawDataToStringsTableData({ data, interfaceLanguages, learningLanguage });

        stringsListCache[cacheKey] = cache = stringsDataValue;
        stringsListCacheLastUpdated = new Date().toISOString();
        updateStringsListStorage(stringsListCache, stringsListCacheLastUpdated);

        setStringsData(stringsDataValue);
        setCountAllStrings(getAllStringsIds(stringsDataValue).length);
      });
    } else {
      setStringsData(cache);
      setCountAllStrings(getAllStringsIds(cache).length);
    }
  }, [
    contentIds,
    interfaceLanguages,
    learningLanguage,
    shouldRefreshStringsListCache,
    storedStringsListCache,
    updateStringsListStorage,
  ]);

  const onToggle = useCallback(
    (stringId: DBId) => {
      const nextSelectedStrings = [...selectedStrings];
      const stringIdIndex = selectedStrings.findIndex((selectedString) => selectedString === stringId);

      if (stringIdIndex > -1) {
        // string is already selected, then remove it from the selectedStrings state
        nextSelectedStrings.splice(stringIdIndex, 1);
      } else {
        // string not selected, then add it to the selectedStrings state
        nextSelectedStrings.push(stringId);
      }

      setSelectedStrings(nextSelectedStrings);
    },
    [selectedStrings],
  );

  const onToggleCheckAll = (selectAll: boolean) => {
    setSelectedStrings(() => (selectAll ? getAllStringsIds(stringsData) : []));
  };

  const preselectStringsWithPendingTranslations = useCallback((stringsData: StringTable | StringTableGroup) => {
    const allStringsIds = Object.values(stringsData);
    const stringIdsToPreselect = flatten(allStringsIds).reduce((acc, current) => {
      const { items } = current as StringTableGroup;

      items.forEach(({ hasPendingTranslations, id }) => {
        if (hasPendingTranslations) {
          acc.push(id);
        }
      });

      return acc;
    }, []);

    setSelectedStrings(stringIdsToPreselect);
    setStringsPreselected(true);

    return stringIdsToPreselect;
  }, []);

  const applyFilters = () => {
    let newState = { ...stringsData } as StringTable | undefined;
    newState = getFilteredStringDataState(stringsData, filters);

    setCountAllStrings(newState ? getAllStringsIds(newState).length : 0);
    setStringsData(newState);
  };

  const clearFilters = () => {
    setCountAllStrings(getAllStringsIds(stringsData).length);
    setFilters({ reset: true });
    setStringsData(getFilteredStringDataState(stringsData, FILTERS_INITIAL_STATE));
  };

  const handleOnCancel = () => {
    cleanUp();
    onCancel();
  };

  const handleOnDownload = async () => {
    try {
      await DownloadTranslationRequestService.requestTranslationFile({
        contentName,
        learningLanguage,
        resourceIds: selectedStrings,
      });

      userTracking.logosTranslationRequestDownloaded({
        component_id: contentIds.lessonId,
        component_type: ContentTypes.lesson,
        selected_string_count: selectedStrings.length,
      });

      onDownload();
    } catch (error) {
      onError('An error ocurred when requesting the translations file');
    }
  };

  useEffect(() => {
    try {
      if (isOpen) {
        fetchStringsList();
      }
    } catch (error) {
      onError('An error ocurred when fetching the strings list');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!stringsPreselected && stringsData) {
      const initialSelectedStrings = preselectStringsWithPendingTranslations(stringsData);

      userTracking.logosTranslationRequestOpened({
        component_id: contentIds.lessonId,
        component_type: ContentTypes.lesson,
        string_count: getAllStringsIds(stringsData).length,
        selected_string_count: initialSelectedStrings.length,
      });
    }
  }, [stringsPreselected, stringsData, preselectStringsWithPendingTranslations, contentIds.lessonId, countAllStrings]);

  useEffect(() => {
    if (stringsData) {
      if (areFiltersSelected(filters)) {
        applyFilters();
      } else {
        clearFilters();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div data-testid="download-translation-request-modal">
      <ModalTitle>Select fields to be included in export</ModalTitle>

      <DownloadTranslationRequestFilters
        filters={filters}
        onFilter={(filterType, value) => {
          setFilters({ [filterType]: value });
        }}
      />

      <ModalStringsListContainer>
        {stringsData ? (
          <StringsTable
            countAllStrings={countAllStrings}
            data={stringsData}
            learningLanguage={learningLanguage}
            selectedStrings={selectedStrings}
            onToggle={onToggle}
            onToggleCheckAll={onToggleCheckAll}
          />
        ) : (
          <Loader size="L" />
        )}
      </ModalStringsListContainer>

      <ModalButtonArea>
        <Button size="M" variant="tertiary" onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button disabled={!selectedStrings.length} size="M" onClick={handleOnDownload}>
          Download
        </Button>
      </ModalButtonArea>
    </div>
  );
};
