import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { throttle } from 'lodash';
import { MouseEvent, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import type { ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { Modal, useDialogModal } from '@features/modal';
import { useToast } from '@features/app/toast';
import { userTracking } from '@features/app/tracking';
import type { LanguageV2 } from '@features/content/languages';
import {
  ContentActionsMenu,
  NavigationContainer,
  NavigationItemV2,
  getNavigationItemUrl,
  isRoleplay,
  type NavigationItemType,
} from '@features/content/navigation';
import { getMoveContentByContentType, MoveContentModalContent } from '@features/content/reuse';
import { Loader } from '@features/theme';

import type { APICustomError } from './types';

type NavigationProps = {
  countTopButtons?: number;
  currentPathIds: DBId[];
  loadingParentId: DBId;
  params: ReturnType<typeof useParams>;
  rootItem: NavigationItemType;
  structure: NavigationItemType[];
  getAllNavigation: () => void;
  getChildren: (type: ContentTypesType, id: DBId) => void;
  onMoveContentEnd: (parentId: DBId) => void;
  onMoveContentSuccess: (
    newNavigationStructure: NavigationItemType[],
    parentId?: DBId,
    parentContentType?: ContentTypesType,
  ) => void;
};

export const Navigation = ({
  countTopButtons = 0,
  currentPathIds,
  loadingParentId,
  params,
  rootItem,
  structure,
  getAllNavigation,
  getChildren,
  onMoveContentEnd,
  onMoveContentSuccess,
}: NavigationProps) => {
  const [isMoveContentProcessing, setIsMoveContentProcessing] = useState(false);
  const [selectedContent, setSelectedContent] = useState<NavigationItemType | null>(null);

  const navigationContainerRef = useRef(null);

  const history = useHistory();

  const showToast = useToast();

  const onMoveContentError = (error: Error | AxiosError<APICustomError>) => {
    let errorDescription = 'An error occurred when trying to move content.';

    if (axios.isAxiosError(error)) {
      errorDescription = error.response?.data.detail || errorDescription;
    }

    showToast({
      type: 'error',
      title: 'Error',
      description: errorDescription,
    });

    Sentry.captureException(error, (scope) => {
      scope.setTag('logosFeature', 'Move content');
      scope.setTag(
        'logosSection',
        `${isRoleplay(selectedContent?.type as ContentTypesType) ? 'Roleplay' : 'Course'} navigation`,
      );
      return scope;
    });
  };

  const onMoveContentConfirm = ({
    selectedContentId,
    currentParentId,
    newParentId,
    newNavigationStructure,
  }: {
    selectedContentId: DBId;
    currentParentId: DBId;
    newParentId: DBId;
    newNavigationStructure: NavigationItemType[];
  }) => {
    const parentContentType = structure.find(({ id }) => id === currentParentId)?.type as ContentTypesType;
    const moveContent = getMoveContentByContentType({
      contentType: selectedContent?.type as ContentTypesType,
      parentContentType,
    });

    setIsMoveContentProcessing(true);

    moveContent(selectedContentId, currentParentId, newParentId)
      .then(() => {
        // Check if moving content action was performed from the selected content own page and replace the outdated path with the new one
        if (Object.values(params).includes(selectedContentId)) {
          const updatedSelectedContent = newNavigationStructure.find(
            (structureItem) => structureItem.id === selectedContentId,
          )!;
          const newUrl = getNavigationItemUrl({
            content: updatedSelectedContent,
            structure: newNavigationStructure,
            language: isRoleplay(selectedContent?.type as ContentTypesType)
              ? (params.languageId as LanguageV2)
              : undefined,
          });

          history.replace(newUrl, { shallow: true });
        }

        userTracking.logosContentMovedFromNavigation({
          component_id: selectedContentId,
          component_type: selectedContent?.type as string,
          from: currentParentId,
          to: newParentId,
        });

        showToast({
          type: 'success',
          title: 'Content was moved sucessfully',
        });

        onMoveContentSuccess(newNavigationStructure, newParentId, parentContentType);
      })
      .catch((error) => {
        onMoveContentError(error);
      })
      .finally(() => {
        setSelectedContent(null);
        setIsMoveContentProcessing(false);
        onMoveContentEnd(newParentId);
      });
  };

  const { open: openMoveContentModal, modal: moveContentModal } = useDialogModal((modalControls) => (
    <Modal lockScroll size="L" {...modalControls} onClickOutside={modalControls.close}>
      <MoveContentModalContent
        busy={isMoveContentProcessing}
        currentStructure={structure}
        isOpen={modalControls.isOpen}
        selectedContent={selectedContent}
        rootItem={rootItem as NavigationItemType}
        onCancel={() => {
          setSelectedContent(null);
          modalControls.close();
        }}
        onConfirm={({ selectedContentId, currentParentId, newParentId, newNavigationStructure }) => {
          onMoveContentConfirm({
            selectedContentId,
            currentParentId,
            newParentId,
            newNavigationStructure,
          });
          modalControls.close();
        }}
        onError={(error) => {
          onMoveContentError(error);
          modalControls.close();
        }}
      />
    </Modal>
  ));

  useEffect(() => {
    getAllNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (navigationContainerRef.current) {
      const navigationContainerElement = navigationContainerRef.current as HTMLDivElement;

      // close potential ContentActionsMenu already displayed
      const onNavigationContainerScroll = throttle(() => {
        if (selectedContent) setSelectedContent(null);
      }, 250);

      navigationContainerElement.addEventListener('scroll', onNavigationContainerScroll);

      return () => {
        navigationContainerElement.removeEventListener('scroll', onNavigationContainerScroll);
      };
    }
  });

  if (!rootItem) {
    return <Loader />;
  }

  const onToggleContentActionsMenu = (evt: MouseEvent<HTMLElement>, contentFromToggler: NavigationItemType | null) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!selectedContent) {
      setSelectedContent(contentFromToggler);
    } else if (contentFromToggler?.id === selectedContent?.id || !contentFromToggler) {
      setSelectedContent(null);
    }
  };

  const onCopyId = (evt: MouseEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    setSelectedContent(null);
    showToast({
      type: 'info',
      title: 'Copied to clipboard',
      description: 'ID has been copied to clipboard',
    });
  };

  return (
    <>
      <NavigationContainer
        isDisabled={!!loadingParentId}
        countTopButtons={countTopButtons}
        ref={navigationContainerRef}
      >
        {rootItem && (
          <NavigationItemV2
            content={rootItem}
            currentPathIds={currentPathIds}
            loadingParentId={loadingParentId}
            selectedContent={selectedContent}
            structure={structure}
            onExpand={getChildren}
            onToggleContentActionsMenu={onToggleContentActionsMenu}
          />
        )}
      </NavigationContainer>
      {rootItem && (
        <>
          <ContentActionsMenu
            selectedContent={selectedContent}
            show={!!selectedContent}
            onClickOutside={() => setSelectedContent(null)}
            onCopyId={onCopyId}
            onMoveContent={openMoveContentModal}
          />
          {!!selectedContent && moveContentModal}
        </>
      )}
    </>
  );
};
