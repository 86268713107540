import { AxiosError, AxiosResponse } from 'axios';
import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { useTheme } from 'styled-components';

import { LanguageV2 } from '@features/content/languages';
import { Button, Text } from '@features/theme';

import {
  ModalButtonArea,
  ModalDescription,
  ModalFieldsContainer,
  ModalFieldsContainerCol,
  ModalTitle,
  StyledWarning,
  UploadFailedWarning,
  UploadFailedWarningIcon,
  UploadFileArea,
  UploadFileEditIcon,
  UploadFileName,
} from './styles';

import { APIError, FiltersState } from './types';
import { UploadTranslationsToLocalisationServiceService } from './UploadTranslationsToLocalisationServiceService';
import { LocalisationFilters } from './_filters/LocalisationFilters';
import { mapFiltersToUploadTranslationsPayload } from './_util/mapFiltersToUploadTranslationsPayload';
import { generateErrorMessage } from './_util/generateErrorMessage';

const filtersInitialState: FiltersState = {
  dueDate: '',
  job: {
    id: undefined,
    name: undefined,
  },
  projectId: undefined,
  targetLanguages: [],
};

type UploadTranslationsToLocalisationServiceModalProps = {
  isOpen: boolean;
  language: LanguageV2;
  onCancel: () => void;
  onUpload: (uploadTranslationsPromise: Promise<AxiosResponse>) => void;
};

export const UploadTranslationsToLocalisationServiceModal = ({
  isOpen,
  language,
  onCancel,
  onUpload,
}: UploadTranslationsToLocalisationServiceModalProps) => {
  const [createdJobNames, setCreatedJobNames] = useState<string[]>([]);
  const [error, setError] = useState<APIError>();
  const [modalTitle, setModalTitle] = useState('');
  const [filters, setFilters] = useReducer(
    (state: FiltersState, newState: Partial<FiltersState>) => ({ ...state, ...newState }),
    filtersInitialState,
  );
  const [showOverrideWarning, setShowOverrideWarning] = useState(false);
  const [translationsFile, setTranslationsFile] = useState<File | undefined>();
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);

  const theme = useTheme();

  const cleanUp = () => {
    setFilters(filtersInitialState);
    setTranslationsFile(undefined);
    setIsUploadDisabled(true);
    setError(undefined);
  };

  const handleOnCancel = () => {
    cleanUp();
    onCancel();
  };

  const handleOnCreateJob = (name: string) => {
    // Store the name of the created job to differentiate between created and existing jobs later
    setCreatedJobNames([...createdJobNames, name]);
  };

  const handleOnUpload = () => {
    if (translationsFile) {
      const uploadTranslationsPromise = UploadTranslationsToLocalisationServiceService.uploadTranslations({
        ...mapFiltersToUploadTranslationsPayload(filters),
        language,
        file: translationsFile,
      });

      uploadTranslationsPromise
        .then((result) => {
          if (result.status === 204) {
            cleanUp();
          }
        })
        .catch((error: AxiosError<APIError>) => {
          setError(error.response?.data);
        });

      onUpload(uploadTranslationsPromise);
    }
  };

  useEffect(() => {
    if (!translationsFile || Object.values(filters).some((filter) => filter === undefined)) {
      setIsUploadDisabled(true);
    } else {
      setIsUploadDisabled(false);
    }
  }, [filters, translationsFile]);

  useEffect(() => {
    // Show override warning in case of a translation file to be uploaded to an exisisting job, otherwise hide it
    setShowOverrideWarning(
      Boolean(translationsFile && filters.job.name && !createdJobNames.includes(filters.job.name ?? '')),
    );
  }, [createdJobNames, filters.job, translationsFile]);

  useEffect(() => {
    setModalTitle(error ? 'File upload error!' : 'Upload to Smartling');
  }, [error]);

  return (
    <div data-testid="upload-translations-to-localisation-service-modal">
      <ModalTitle>{modalTitle}</ModalTitle>
      {error ? (
        <UploadFailedWarning>
          <UploadFailedWarningIcon />
          {generateErrorMessage(error)}
        </UploadFailedWarning>
      ) : (
        <ModalDescription>
          Create translation request: provide CSV file for final translation for Smartling
        </ModalDescription>
      )}
      {showOverrideWarning && (
        <StyledWarning text="You're uploading content to an existing job. Proceeding will override all content in that job." />
      )}
      <ModalFieldsContainer>
        <ModalFieldsContainerCol>
          <LocalisationFilters
            isOpen={isOpen}
            filters={filters}
            onCreateJob={handleOnCreateJob}
            onFilter={(filterType, value) => setFilters({ [filterType]: value })}
          />
        </ModalFieldsContainerCol>
        <ModalFieldsContainerCol>
          <UploadFileArea htmlFor="browse-file-upload-to-localisation-service">
            {translationsFile ? (
              <UploadFileName>
                {translationsFile.name}
                <UploadFileEditIcon />
              </UploadFileName>
            ) : (
              <Text color={theme.colorV2.accentPrimary} level={2}>
                Choose a CSV file
              </Text>
            )}
            <input
              accept=".csv"
              data-testid="browse-file-upload-to-localisation-service"
              hidden
              id="browse-file-upload-to-localisation-service"
              type="file"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.files !== null) {
                  setTranslationsFile(e.target.files[0]);
                }
              }}
            />
          </UploadFileArea>
        </ModalFieldsContainerCol>
      </ModalFieldsContainer>
      <ModalButtonArea>
        <Button size="M" variant="tertiary" onClick={handleOnCancel}>
          Close
        </Button>
        <Button disabled={isUploadDisabled} size="M" onClick={handleOnUpload}>
          Upload
        </Button>
      </ModalButtonArea>
    </div>
  );
};
