import styled, { css } from 'styled-components/macro';

import { NAVIGATION_PANEL_WIDTH } from '../constants';

export const ContentActionsOption = styled.div`
  align-items: center;
  display: flex;
  gap: 0.8rem;
  min-width: 22.8rem;
  pointer-events: all;
`;

export const ContentActionsMenuBackdrop = styled.div<{ show: boolean }>`
  ${({ show, theme }) => css`
    bottom: 0;
    display: ${show ? 'block' : 'none'};
    left: ${NAVIGATION_PANEL_WIDTH};
    right: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.zIndex.backDrop};
  `}
`;
