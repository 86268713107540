import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { CourseNavigationActionsCreator } from '@actionCreators/CourseNavigationActionsCreator';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { Logo } from '@features/app/logo';
import { ACTIVITIES, type ActivityType } from '@features/content/activities';
import { GrammarReviewButton } from '@features/content/grammar';
import { PlacementTestButton } from '@features/content/placementTest';
import {
  Navigation,
  NavigationPanelFooter,
  NavigationPanelContainer,
  LogoAndNavigationContainer,
  getNavigationWithChildren,
  type NavigationItemType,
  type NavigationItemFromBackType,
} from '@features/content/navigation';
import { getRealId, processStructureForReuse } from '@helpers/reuseHelper';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectCourse, selectCourseStructure, selectLoadingCourseParentId } from '@selectors/CoursesSelectors';
import NavigationService from '@services/NavigationService';

import { ReactComponent as CollapseIcon } from './_img/collapse.svg';
import { ReactComponent as ExpandIcon } from './_img/expand.svg';

const CollapseButton = styled.button`
  display: block;
  position: absolute;
  top: 9.6rem;
  left: 100%;

  width: 3.6rem;
  height: 3.6rem;
  background: ${({ theme }) => theme.colorV2.navigationBackground};
  border-radius: 0 0.8rem 0.8rem 0;
  border: 0;
`;

export const CourseNavigation = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: (callback: (state: boolean) => boolean) => void;
}) => {
  const courseStructure = useAppSelector(selectCourseStructure);
  const loadingParentId = useAppSelector(selectLoadingCourseParentId);
  const course = useAppSelector(selectCourse);

  const dispatch = useAppDispatch();

  const params = useParams<{
    courseId: DBId;
    levelId: DBId;
    chapterId: DBId;
    lessonId: DBId;
    activityId: DBId;
    exerciseId: DBId;
  }>();
  const { courseId, levelId, chapterId, lessonId, activityId, exerciseId } = params;

  const countTopButtons = course.isComplete ? 2 : 0;
  const currentPathIds = [
    courseId,
    levelId,
    chapterId,
    getRealId(lessonId),
    getRealId(activityId),
    getRealId(exerciseId),
  ];
  const rootItem = courseStructure.find((content) => content.type === ContentTypes.course);

  const getAllNavigation = () => {
    if (course.id) {
      dispatch(CourseNavigationActionsCreator.getAllNavigation(courseId, levelId, chapterId, lessonId, activityId));
    }
  };

  const getChildrenArray = (type: ContentTypesType, id: DBId) => {
    if (!courseStructure.map((content) => content.parentId).includes(id)) {
      dispatch(CourseNavigationActionsCreator.setLoadingParentId(id));
      switch (type) {
        case ContentTypes.course:
          return NavigationService.getCourseNavigation(id).then((result) => {
            return result.data.levels.map((level: NavigationItemFromBackType) => ({
              ...level,
              parentId: id,
              type: ContentTypes.level,
              expanded: levelId === level.id,
            }));
          });
        case ContentTypes.level:
          return NavigationService.getLevelNavigation(id).then((result) => {
            return result.data.chapters.map((chapter: NavigationItemFromBackType) => ({
              ...chapter,
              parentId: id,
              type: ContentTypes.chapter,
              expanded: chapterId === chapter.id,
            }));
          });
        case ContentTypes.chapter:
          return NavigationService.getChapterNavigation(id).then((result) =>
            result.data.lessons.map((lesson: NavigationItemFromBackType) => ({
              ...lesson,
              parentId: id,
              expanded: lessonId === lesson.id,
              type: lesson.type === 'speaking' ? ContentTypes.speakingLesson : lesson.type,
            })),
          );

        case ContentTypes.lesson:
        case ContentTypes.certificate:
        case ContentTypes.review:
        case ContentTypes.roleplay:
        case ContentTypes.speakingLesson:
          return NavigationService.getLessonNavigation(id).then((result) =>
            result.data.activities.map((activity: NavigationItemFromBackType) => ({
              ...activity,
              parentId: id,
              expanded: activityId === activity.id,
              type: activity.type === 'speaking' ? ContentTypes.speakingActivity : activity.type,
            })),
          );
        case ContentTypes.liveLesson:
          return NavigationService.getLiveLessonNavigation(id).then((result) =>
            result.data.exercises.map((exercise: NavigationItemFromBackType) => ({
              ...exercise,
              parentId: id,
            })),
          );
        default:
          if (ACTIVITIES.includes(type as ActivityType)) {
            return NavigationService.getActivityNavigation(id).then((result) =>
              result.data.exercises.map((exercise: NavigationItemFromBackType) => ({
                ...exercise,
                parentId: id,
              })),
            );
          }
          return Promise.resolve([]);
      }
    }

    return Promise.resolve([]);
  };

  const getChildren = async (type: ContentTypesType, id: DBId) => {
    const childrenArray = await getChildrenArray(type, id);
    dispatch(CourseNavigationActionsCreator.updateNavigation(processStructureForReuse(courseStructure, childrenArray)));
    dispatch(CourseNavigationActionsCreator.setLoadingParentId(''));
  };

  const onMoveContentSuccess = async (
    newNavigationStructure: NavigationItemType[],
    destinationParentId: DBId = courseId,
    destinationParentContentType: ContentTypesType = ContentTypes.roleplay,
  ) => {
    dispatch(CourseNavigationActionsCreator.setLoadingParentId(destinationParentId));

    const finalNavigationStructure = await getNavigationWithChildren({
      newNavigationStructure,
      parentContentType: destinationParentContentType,
      parentId: destinationParentId,
      rootItemId: courseId,
      getChildrenArray,
    });

    dispatch(CourseNavigationActionsCreator.updateNavigation(finalNavigationStructure));
  };

  const onMoveContentEnd = () => {
    dispatch(CourseNavigationActionsCreator.setLoadingParentId(''));
    // @TODO refresh group list if the user is in the moved content destination parent page
  };

  return (
    <NavigationPanelContainer $collapsed={collapsed}>
      <CollapseButton onClick={() => setCollapsed((collapsed) => !collapsed)}>
        {collapsed ? <ExpandIcon /> : <CollapseIcon />}
      </CollapseButton>
      <LogoAndNavigationContainer $collapsed={collapsed}>
        <Logo size="small" className="course-edition__logo" withLink />
        <GrammarReviewButton collapsed={collapsed} />
        <PlacementTestButton collapsed={collapsed} />
        {!collapsed && (
          <Navigation
            countTopButtons={countTopButtons}
            currentPathIds={currentPathIds}
            loadingParentId={loadingParentId}
            params={params}
            rootItem={rootItem as NavigationItemType}
            structure={courseStructure}
            getAllNavigation={getAllNavigation}
            getChildren={getChildren}
            onMoveContentEnd={onMoveContentEnd}
            onMoveContentSuccess={onMoveContentSuccess}
          />
        )}
      </LogoAndNavigationContainer>
      <NavigationPanelFooter collapsed={collapsed} />
    </NavigationPanelContainer>
  );
};
