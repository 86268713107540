import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { NavigationContainer, NavigationItemV2, type NavigationItemType } from '@features/content/navigation';

import { getCurrentPathIds } from './_util/getCurrentPathIds';
import { MoveContentService } from '../MoveContentService';

const ContentTreeWrapper = styled.div`
  border-color: ${({ theme }) => theme.colorV2.uiDivider};
  border-style: solid;
  border-width: 0.1rem 0;
  height: 50rem;
  margin: 2.4rem 0;
  min-height: 50rem;
  padding: 2.4rem 0;
  overflow: auto;

  a {
    max-width: none !important;
  }
`;

type ContentTreePropsType = {
  rootItem: NavigationItemType;
  isOpen: boolean;
  navigationCourseStructure: NavigationItemType[];
  selectedContent: NavigationItemType | null;
  selectedTargetLocationId: string | undefined;
  structure: NavigationItemType[];
  setStructure: Dispatch<SetStateAction<NavigationItemType[]>>;
  onError: (error: any) => void;
  onSelect: (contentId: string) => void;
};

export const ContentTree = ({
  rootItem,
  isOpen,
  navigationCourseStructure,
  selectedContent,
  selectedTargetLocationId,
  structure,
  setStructure,
  onError,
  onSelect,
}: ContentTreePropsType) => {
  const [loadingParentId, setLoadingParentId] = useState('');

  const currentPathIds = useMemo(() => {
    let _currentPathIds: (string | null | undefined)[] = [];

    if (selectedContent) {
      _currentPathIds = getCurrentPathIds(selectedContent, navigationCourseStructure).slice(0, -1); // remove selectedContent id as only parents and ancestors are displayed
    }

    return _currentPathIds;
  }, [navigationCourseStructure, selectedContent]);

  useEffect(() => {
    if (isOpen) {
      // get a fresh navigation structure independent of app navigation

      const getAllNavigation =
        rootItem?.type === ContentTypes.roleplay
          ? MoveContentService.getAllRoleplayNavigation
          : MoveContentService.getAllNavigation;

      getAllNavigation(rootItem, currentPathIds, selectedContent?.parentId)
        .then((result) => {
          setStructure(result);
        })
        .catch((error) => {
          onError(error);
        });
    }

    return () => {
      setLoadingParentId('');
      setStructure([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootItem, currentPathIds, isOpen]);

  const onExpand = async (type: ContentTypesType, id: string) => {
    setLoadingParentId(id);

    const getChildren =
      rootItem?.type === ContentTypes.roleplay
        ? MoveContentService.getRoleplayChildren
        : MoveContentService.getChildren;

    getChildren(type, id, currentPathIds)
      .then((result) => {
        setStructure([...structure, ...result]);
        setLoadingParentId('');
      })
      .catch((error) => onError(error));
  };

  return (
    <ContentTreeWrapper>
      {structure.length > 0 ? (
        <NavigationContainer countTopButtons={0} isDisabled={!!loadingParentId}>
          <NavigationItemV2
            content={rootItem}
            currentPathIds={currentPathIds as string[]}
            isInMoveContentModal
            loadingParentId={loadingParentId}
            selectedContent={selectedContent}
            selectedTargetLocationId={selectedTargetLocationId}
            structure={structure}
            onExpand={onExpand}
            onSelect={onSelect}
          />
        </NavigationContainer>
      ) : null}
    </ContentTreeWrapper>
  );
};
