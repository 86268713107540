import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { TranslationsPanelContentInterface } from '@common/interfaces/exercises/TranslationsPanelContentInterface';
import type { SelectorOptionType } from '@components/SearchableSelector/SearchableSelector';
import { DEFAULT_LANGUAGE_V2 } from '@features/content/languages';

import { SearchableSelectorWithErrorState } from './styles';

type NameSelectorProps = {
  disabled: boolean;
  isIssuesShown: boolean;
  options?: TranslationsPanelContentInterface[];
  placeholder: string;
  getOptions: () => Promise<AxiosResponse<TranslationsPanelContentInterface[]>>;
  onSelect: (title: TranslationsPanelContentInterface) => void;
};

export const NameSelector = ({
  disabled,
  isIssuesShown,
  options = [],
  placeholder,
  getOptions,
  onSelect,
}: NameSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const [names, setNames] = useState<TranslationsPanelContentInterface[]>(options);

  useEffect(() => {
    if (!names.length && !loading) {
      setLoading(true);
      getOptions().then((result) => {
        setNames(result.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [names, loading]);

  const namesOptions = names.map((name) => ({
    label: name.textLocalizations.find((loc) => loc.language === DEFAULT_LANGUAGE_V2)?.value || '',
    value: name._id || '',
  }));

  const handleOnSelect = (selectedOption: SelectorOptionType | null) => {
    const title = names.find((name) => name._id === selectedOption?.value);

    if (title) {
      onSelect(title);
    }
  };

  return (
    <SearchableSelectorWithErrorState
      disabled={disabled}
      isIssuesShown={isIssuesShown}
      options={namesOptions}
      placeholder={placeholder}
      onSelect={handleOnSelect}
    />
  );
};
