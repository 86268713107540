import { type LanguageV2, DEFAULT_LANGUAGE_V2 } from '@features/content/languages';
import { Checkbox } from '@features/theme';

import { StyledTableHead, StyledTableHeader } from './styles';

type StringsTableHeadersProps = {
  countAllStrings: number;
  countSelectedStrings: number;
  learningLanguage: LanguageV2;
  onToggleCheckAll: (selectAll: boolean) => void;
};

export const StringsTableHeaders = ({
  countAllStrings,
  countSelectedStrings,
  learningLanguage,
  onToggleCheckAll,
}: StringsTableHeadersProps) => {
  const valueLanguages =
    learningLanguage === DEFAULT_LANGUAGE_V2 ? DEFAULT_LANGUAGE_V2 : `${learningLanguage}/${DEFAULT_LANGUAGE_V2}`;
  const isSelectAllChecked = countSelectedStrings > 0 && countSelectedStrings === countAllStrings;
  const areSomeStringsSelected = countSelectedStrings > 0;

  return (
    <StyledTableHead>
      <StyledTableHeader>
        <Checkbox
          checked={isSelectAllChecked}
          dataTestId="select-all-strings-toggle"
          partialChecked={areSomeStringsSelected}
          onToggle={onToggleCheckAll}
        />{' '}
        String ID
      </StyledTableHeader>
      <StyledTableHeader>Value ({valueLanguages.toUpperCase()})</StyledTableHeader>
      <StyledTableHeader>Translations</StyledTableHeader>
      <StyledTableHeader>Context</StyledTableHeader>
    </StyledTableHead>
  );
};
