import { useParams } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { ContentTypes, ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { Logo } from '@features/app/logo';
import type { LanguageV2 } from '@features/content/languages';
import {
  Navigation,
  NavigationPanelFooter,
  NavigationPanelContainer,
  LogoAndNavigationContainer,
  type NavigationItemType,
  getNavigationWithChildren,
} from '@features/content/navigation';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectRoleplayNavigationLoadingParentId,
  selectRoleplayNavigationStructure,
} from '@selectors/roleplaySelectors';
import { RoleplayService } from '../RoleplayService';
import { RoleplayListItemType } from '../types';

export const NavigationPanel = () => {
  const loadingParentId = useAppSelector(selectRoleplayNavigationLoadingParentId);
  const roleplayNavigationStructure = useAppSelector(selectRoleplayNavigationStructure);

  const dispatch = useAppDispatch();

  const params = useParams<{
    languageId: LanguageV2;
    categoryId: DBId;
    scenarioId: DBId;
  }>();
  const { languageId, categoryId, scenarioId } = params;

  const currentPathIds = [`roleplay_${languageId}`, categoryId, scenarioId];
  const rootItem = roleplayNavigationStructure.find((content) => content.type === ContentTypes.roleplay);

  const getAllNavigation = () => {
    dispatch(RoleplayActionCreators.getAllNavigation(languageId, `roleplay_${languageId}`, categoryId, scenarioId));
  };

  const getChildrenArray = (type: ContentTypesType, id: DBId) => {
    if (!roleplayNavigationStructure.map((content) => content.parentId).includes(id)) {
      dispatch(RoleplayActionCreators.setLoadingParentId(id));

      switch (type) {
        case ContentTypes.roleplay:
          return RoleplayService.getRoleplayNavigation(languageId).then((result) =>
            result.data.categories.map((category: RoleplayListItemType) => ({
              ...category,
              parentId: id,
              type: ContentTypes.roleplayCategory,
              expanded: categoryId === category.id,
            })),
          );
        case ContentTypes.roleplayCategory:
          return RoleplayService.getRoleplayCategoryNavigation(id).then((result) =>
            result.data.roleplayScenarios.map((scenario: RoleplayListItemType) => ({
              ...scenario,
              parentId: id,
              type: ContentTypes.roleplayScenario,
            })),
          );

        default:
          return Promise.resolve([]);
      }
    }

    return Promise.resolve([]);
  };

  const getChildren = async (type: ContentTypesType, id: DBId) => {
    const childrenArray = await getChildrenArray(type, id);
    dispatch(
      RoleplayActionCreators.updateNavigation([
        ...roleplayNavigationStructure,
        ...(childrenArray as NavigationItemType[]),
      ]),
    );
    dispatch(RoleplayActionCreators.setLoadingParentId(''));
  };

  const onMoveContentSuccess = async (
    newNavigationStructure: NavigationItemType[],
    destinationParentId: DBId = `roleplay_${languageId}`,
    destinationParentContentType: ContentTypesType = ContentTypes.roleplay,
  ) => {
    dispatch(RoleplayActionCreators.setLoadingParentId(destinationParentId));

    const finalNavigationStructure = await getNavigationWithChildren({
      newNavigationStructure,
      parentContentType: destinationParentContentType,
      parentId: destinationParentId,
      rootItemId: `roleplay_${languageId}`,
      getChildrenArray,
    });

    dispatch(RoleplayActionCreators.updateNavigation(finalNavigationStructure));
  };

  const onMoveContentEnd = (destinationParentId: DBId) => {
    dispatch(RoleplayActionCreators.setLoadingParentId(''));

    // if the user is in the destination category, refresh the group list
    if (destinationParentId === categoryId) {
      dispatch(RoleplayActionCreators.getRoleplayScenarios(categoryId));
    }
  };

  return (
    <NavigationPanelContainer>
      <LogoAndNavigationContainer>
        <Logo size="small" className="course-edition__logo" withLink />
        <Navigation
          countTopButtons={1}
          currentPathIds={currentPathIds}
          loadingParentId={loadingParentId}
          params={params}
          rootItem={rootItem as NavigationItemType}
          structure={roleplayNavigationStructure}
          getAllNavigation={getAllNavigation}
          getChildren={getChildren}
          onMoveContentEnd={onMoveContentEnd}
          onMoveContentSuccess={onMoveContentSuccess}
        />
      </LogoAndNavigationContainer>
      <NavigationPanelFooter />
    </NavigationPanelContainer>
  );
};
