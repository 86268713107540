import type { FiltersState } from '../DownloadTranslationRequestFilters';
import type { StringTable, StringTableItem } from '../types';

/** Update group items visibility based on filters */
const updateGroupItemsVisibility = (groupItems: StringTableItem[], filters: FiltersState) => {
  const { query: stringId, pendingTranslationsOnly } = filters;

  return groupItems.map((item) => {
    /*
      Item is visible if:
      1. no stringId filter is applied or
      2. item id matches the stringId filter and
      3. item has pending translations and pendingTranslationsOnly filter is applied
    */
    const isVisible =
      (stringId === '' || item.id === stringId) && (!pendingTranslationsOnly || item.hasPendingTranslations);

    return {
      ...item,
      visible: isVisible,
    };
  });
};

export const getFilteredStringDataState = (stringsData: StringTable | undefined, filters: FiltersState) => {
  if (!stringsData) return;

  const newState = { ...stringsData } as StringTable;

  Object.keys(newState).forEach((key) => {
    const groupKey = key as keyof StringTable;
    const group = newState[groupKey];

    if (Array.isArray(group)) {
      group.forEach((groupChild) => {
        groupChild.items = updateGroupItemsVisibility(groupChild.items, filters);
      });
    } else {
      group.items = updateGroupItemsVisibility(group.items, filters);
    }
  });

  return newState;
};
