import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import { DBId } from '@common/types/DBId';
import { apiClient } from '@features/api';
import { LanguageV2 } from '@features/content/languages';

import type { StringsListPayload } from './types';

export const DownloadTranslationRequestService = {
  async getStringsList({ courseId, levelId, chapterId, lessonId }: StringsListPayload): Promise<AxiosResponse> {
    return apiClient.noErrorsV2.get('translations/strings', { params: { courseId, levelId, chapterId, lessonId } });
  },

  async requestTranslationFile({
    contentName,
    learningLanguage,
    resourceIds,
  }: {
    contentName?: string;
    learningLanguage: LanguageV2;
    resourceIds: DBId[];
  }) {
    const { data: fileContents } = await apiClient.noErrorsV2.post('translations/strings/csv', {
      learningLanguage,
      resourceIds,
    });

    fileDownload(
      fileContents,
      `translations_${contentName || 'untitled_content'}.csv`,
      'text/csv;charset=utf-8',
      '\uFEFF',
    );
  },
};
