import { reuseConstants } from '@features/content/reuse';

import { NavigationItemType } from '../../types';

export const isMoveable = (contentType: NavigationItemType['type'] | undefined) => {
  if (!contentType) return false;

  const { MOVEABLE_CONTENT_TYPES } = reuseConstants;

  return MOVEABLE_CONTENT_TYPES.includes(contentType);
};
