import { ReactElement, useEffect } from 'react';
import { Prompt, useParams } from 'react-router-dom';

import { RoleplayActionCreators } from '@actionCreators/RoleplayActionsCreators';
import { LoadingStage } from '@common/enums/LoadingStage';
import { PublishModalContainer } from '@components/CourseEdition/PublishModalContainer';
import { PublishingErrorsModal } from '@components/Publishing/PublishingErrorsModal/PublishingErrorsModal';
import { PublishingQueueSidebar } from '@components/Publishing/PublishingQueueSidebar';
import { StringReuseModal } from '@components/StringReuseModal/StringReuseModal';
import type { LanguageV2 } from '@features/content/languages';
import { useOpenSearchShortcut } from '@features/search';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectIsSaveButtonEnabled, selectStringReuseModalOpenedFor } from '@selectors/CoursesSelectors';
import { selectRoleplay } from '@selectors/roleplaySelectors';
import { selectPublishingPanelVisible } from '@selectors/UiSelectors';

import { NavigationPanel } from './NavigationPanel/NavigationPanel';

export const RoleplayPage = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const { languageId } = useParams<{ languageId: LanguageV2 }>();

  const isSaveButtonEnabled = useAppSelector(selectIsSaveButtonEnabled);
  const roleplay = useAppSelector(selectRoleplay);
  const publishingPanelVisible = useAppSelector(selectPublishingPanelVisible);
  const stringReuseModalOpenedFor = useAppSelector(selectStringReuseModalOpenedFor);

  useOpenSearchShortcut();

  useEffect(() => {
    if (roleplay.loaded === LoadingStage.notLoaded && languageId) {
      dispatch(RoleplayActionCreators.getRoleplay(languageId));
    }
  }, [dispatch, languageId, roleplay]);

  return (
    <div className="course-edition">
      <Prompt when={isSaveButtonEnabled} message="Are you sure you want to navigate?" />
      <PublishModalContainer />
      {publishingPanelVisible && <PublishingQueueSidebar />}
      <PublishingErrorsModal />
      {stringReuseModalOpenedFor && <StringReuseModal />}
      <NavigationPanel />
      {children}
    </div>
  );
};
