import Select, {
  StylesConfig,
  MultiValueGenericProps,
  OptionProps,
  components,
  MultiValue,
  ControlProps,
} from 'react-select';
import { FC } from 'react';

import { useSelectorCommonStyles } from '../useSelectorCommonStyles';
import { CustomDropdownIndicator } from '../CustomDropdownIndicator';
import { CustomClearIndicator } from '../CustomClearIndicator';
import { SearchImage } from '../SearchImage';
import { CommonFilterOptionType } from '../types';

const CustomControl = (props: ControlProps<CommonFilterOptionType>) => (
  <components.Control {...props}>
    {!(props.selectProps.value as MultiValue<CommonFilterOptionType>)?.length && (
      <SearchImage isMenuOpen={props.selectProps.menuIsOpen} />
    )}
    {props.children}
  </components.Control>
);

const CLASSNAME_PREFIX = 'multi-selector';

type CommonFilterMultiSelectorProps = {
  className?: string;
  classNamePrefix?: string;
  closeMenuOnSelect?: boolean;
  disabled?: boolean;
  options: CommonFilterOptionType[];
  optionContainer?: FC<OptionProps<CommonFilterOptionType, true>>;
  placeholder?: string;
  rule?: string;
  value: MultiValue<CommonFilterOptionType> | null;
  valueContainer?: FC<MultiValueGenericProps<CommonFilterOptionType, true>>;
  onChange: (option: MultiValue<CommonFilterOptionType> | null) => void;
  onFocus?: () => void;
};

export const CommonFilterMultiSelector = ({
  className,
  classNamePrefix = CLASSNAME_PREFIX,
  closeMenuOnSelect,
  disabled = false,
  options,
  placeholder = 'Common multi Filter',
  rule = 'Rule',
  optionContainer = (props) => <components.Option {...props} />,
  value,
  valueContainer = (props) => <components.MultiValueContainer {...props} />,
  onChange,
  onFocus,
}: CommonFilterMultiSelectorProps) => {
  const CommonSelectorStyles = useSelectorCommonStyles<CommonFilterOptionType>({ classNamePrefix });

  const MultiSelectorStyles: StylesConfig<CommonFilterOptionType> = {
    ...CommonSelectorStyles,
    dropdownIndicator: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.dropdownIndicator && CommonSelectorStyles.dropdownIndicator(defaultStyles, props)),
      display: `${value?.length || disabled ? 'none' : 'block'}`,
    }),
    valueContainer: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.valueContainer && CommonSelectorStyles.valueContainer(defaultStyles, props)),
      ':before': {
        content: `"${value?.length ? `${rule}: ` : ''}"`,
        marginRight: '0.4rem',
      },
    }),
    option: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.option && CommonSelectorStyles.option(defaultStyles, props)),
      color: 'inherit',
      ':after': {
        backgroundImage: 'none',
      },
    }),
    placeholder: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.placeholder && CommonSelectorStyles.placeholder(defaultStyles, props)),
      paddingLeft: '2.5rem',
    }),
    input: (defaultStyles, props) => ({
      ...defaultStyles,
      ...(CommonSelectorStyles.input && CommonSelectorStyles.input(defaultStyles, props)),
      marginLeft: value?.length ? '0' : '2.8rem',
    }),
  };

  return (
    <Select<CommonFilterOptionType, true>
      aria-label={classNamePrefix}
      classNamePrefix={classNamePrefix}
      className={className}
      closeMenuOnSelect={closeMenuOnSelect}
      value={value}
      isDisabled={disabled}
      isSearchable
      isMulti
      placeholder={placeholder}
      options={options}
      hideSelectedOptions={false}
      styles={MultiSelectorStyles}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
        MultiValueContainer: valueContainer,
        Option: optionContainer,
        Control: CustomControl,
      }}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};
