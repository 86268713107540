import { MouseEvent } from 'react';

import { type ContentTypesType } from '@common/enums/ContentTypes';
import type { DBId } from '@common/types/DBId';
import { Loader } from '@features/theme';
import { getRealId } from '@helpers/reuseHelper';

import { ReactComponent as ContentActionsMenuIcon } from '../_img/content-actions-menu-icon.svg';
import { CONTENT_ACTIONS_MENU_TRIGGER_PREFIX } from '../ContentActionsMenu';
import {
  ChildrenLoadingContainer,
  ContentActionsMenuTrigger,
  ExpandButton,
  ItemLabel,
  NavItem,
  NavLink,
  StyledCardPublishingStatus,
  StyledMoveIcon,
  StyledUnreadCommentsIcon,
} from './styles';
import type { NavigationItemType } from '../types';

type CommonNavigationItemPropsType = {
  content: NavigationItemType;
  currentPathIds: string[];
  disabled?: boolean;
  exerciseId: DBId;
  expanded: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  loadingParentId: string;
  selectedContent?: NavigationItemType | null;
  title: string;
  url: string;
  handleExpand: (type: ContentTypesType, id: DBId) => void;
  onSelect?: (contentId: DBId) => void;
  onToggleContentActionsMenu?: (evt: MouseEvent<HTMLElement>, content: NavigationItemType | null) => void;
};

export const NavigationItemLabel = ({
  content,
  currentPathIds,
  disabled = false,
  expanded,
  isSelectable,
  isSelected,
  loadingParentId,
  selectedContent,
  title,
  url,
  handleExpand,
  onSelect,
  onToggleContentActionsMenu,
}: CommonNavigationItemPropsType) => {
  const isActive =
    currentPathIds.includes(getRealId(content.id)) &&
    (!content.parentId || currentPathIds.includes(getRealId(content.parentId)));

  const showExpandButton = !disabled && content.children && content.id !== selectedContent?.id;

  return (
    <ItemLabel
      contentId={content.id}
      data-testid={`navigation-item-label-${content.id}`}
      disabled={!!loadingParentId || disabled}
      idForContentActionsMenu={selectedContent?.id}
      isActive={isActive}
      isSelectable={isSelectable}
      isSelected={isSelected}
      onClick={() => isSelectable && onSelect && onSelect(content.id)}
      onMouseEnter={(evt) => {
        // close potential ContentActionsMenu already displayed from other navigation item
        if (content.id !== selectedContent?.id && onToggleContentActionsMenu) {
          onToggleContentActionsMenu(evt, null);
        }
      }}
    >
      {loadingParentId === content.id && content.children ? (
        <ChildrenLoadingContainer>
          <Loader size="S" />
        </ChildrenLoadingContainer>
      ) : (
        <>
          {isSelectable ? (
            <StyledMoveIcon />
          ) : (
            <ExpandButton
              expanded={expanded}
              isActive={isActive}
              visible={showExpandButton}
              onClick={(e) => {
                e.stopPropagation();
                content.children && !isSelectable && handleExpand(content.type as ContentTypesType, content.id);
              }}
            />
          )}
        </>
      )}

      <NavItem isSelectable={isSelectable} isSelected={isSelected}>
        <NavLink
          disabled={!!selectedContent || !!loadingParentId || disabled}
          isActive={isActive}
          title={title}
          to={url}
        >
          <StyledCardPublishingStatus hasPendingChanges={false} ready={content.ready} />
          {title}
        </NavLink>
        {(!selectedContent || content.id !== selectedContent.id) && content.unreadComments && (
          <StyledUnreadCommentsIcon />
        )}
        {onToggleContentActionsMenu ? (
          <ContentActionsMenuTrigger
            aria-haspopup
            id={`${CONTENT_ACTIONS_MENU_TRIGGER_PREFIX}-${content.id}`}
            data-testid="content-actions-menu-trigger"
            onClick={(evt) => onToggleContentActionsMenu(evt, content)}
          >
            <ContentActionsMenuIcon />
          </ContentActionsMenuTrigger>
        ) : null}
      </NavItem>
    </ItemLabel>
  );
};
