import { ContentTypes } from '@common/enums/ContentTypes';
import { DEFAULT_LANGUAGE_V2, LanguageV2 } from '@features/content/languages';

import { DBId } from '@common/types/DBId';

import { APIStringRawData, APITextLocalizations, StringTable, StringTableItem } from '../types';

const getStringValueToDisplay = (textLocalizations: APITextLocalizations, learningLanguage: LanguageV2) => {
  const learningLanguageLocalization = textLocalizations[learningLanguage];
  const defaultLanguageLocalization = textLocalizations[DEFAULT_LANGUAGE_V2];
  let stringValueToDisplay = learningLanguageLocalization?.value;

  // If learning language is not EN then add the EN translation to be displayed
  if (learningLanguage !== DEFAULT_LANGUAGE_V2 && defaultLanguageLocalization) {
    stringValueToDisplay = `${stringValueToDisplay} / ${textLocalizations[DEFAULT_LANGUAGE_V2].value}`;
  }

  return stringValueToDisplay;
};

const getTranslationsInfo = (textLocalizations: APITextLocalizations, interfaceLanguages: LanguageV2[]) => {
  const currentLocalizations = { ...textLocalizations };
  const nonInterfaceLanguageLocalizations = Object.keys(currentLocalizations).filter(
    (language) => !interfaceLanguages.includes(language as LanguageV2),
  );

  // Remove a potential localization coming from API which is not included in the current active interface languages
  if (nonInterfaceLanguageLocalizations.length) {
    nonInterfaceLanguageLocalizations.forEach((language) => {
      delete currentLocalizations[language as LanguageV2];
    });
  }

  const countAvailableTranslations = Object.values(currentLocalizations).reduce((acc, localization) => {
    if (localization.value) acc++;

    return acc;
  }, 0);
  const countInterfaceLanguages = interfaceLanguages.length;

  // If there are less less available translations than interface languages then there are pending translations
  const hasPendingTranslations = countAvailableTranslations < countInterfaceLanguages;

  return {
    hasPendingTranslations,
    translationsLabel: `${countAvailableTranslations}/${countInterfaceLanguages}`,
  };
};

const addStringToGroup = ({
  context,
  dest,
  id,
  interfaceLanguages,
  learningLanguage,
  textLocalizations,
}: {
  dest: StringTableItem[];
  id: DBId;
  context: string;
  interfaceLanguages: LanguageV2[];
  learningLanguage: LanguageV2;
  textLocalizations: APITextLocalizations;
}) => {
  /** Avoid duplicates */
  if (dest.find((item) => item.id === id)) return;

  const { hasPendingTranslations, translationsLabel } = getTranslationsInfo(textLocalizations, interfaceLanguages);

  dest.push({
    context,
    id,
    hasPendingTranslations,
    translations: translationsLabel,
    value: getStringValueToDisplay(textLocalizations, learningLanguage),
    visible: true,
  });
};

export const mapStringsRawDataToStringsTableData = ({
  data,
  interfaceLanguages,
  learningLanguage,
}: {
  data: APIStringRawData[];
  interfaceLanguages: LanguageV2[];
  learningLanguage: LanguageV2;
}) => {
  let output: Partial<StringTable> = {};

  data.forEach(({ context, id, location, textLocalizations }) => {
    switch (location.type) {
      case ContentTypes.course:
        if (!output.course) {
          output.course = {
            label: location.label,
            items: [],
          };
        }
        addStringToGroup({
          dest: output.course.items,
          context,
          id,
          interfaceLanguages,
          learningLanguage,
          textLocalizations,
        });

        break;

      case ContentTypes.level:
        if (!output.level) {
          output.level = {
            label: location.label,
            items: [],
          };
        }

        addStringToGroup({
          dest: output.level.items,
          context,
          id,
          interfaceLanguages,
          learningLanguage,
          textLocalizations,
        });

        break;

      case ContentTypes.chapter:
        if (!output.chapter) {
          output.chapter = {
            label: location.label,
            items: [],
          };
        }

        addStringToGroup({
          dest: output.chapter.items,
          context,
          id,
          interfaceLanguages,
          learningLanguage,
          textLocalizations,
        });

        break;

      case ContentTypes.lesson:
        if (!output.lesson) {
          output.lesson = {
            label: location.label,
            items: [],
          };
        }

        addStringToGroup({
          dest: output.lesson.items,
          context,
          id,
          interfaceLanguages,
          learningLanguage,
          textLocalizations,
        });

        break;

      case ContentTypes.exercise:
        if (!output.exercises) {
          output.exercises = [];
        }

        const exerciseIndex = output.exercises.findIndex((exercise) => exercise.id === location.id);

        if (exerciseIndex > -1) {
          addStringToGroup({
            dest: output.exercises[exerciseIndex].items,
            context,
            id,
            interfaceLanguages,
            learningLanguage,
            textLocalizations,
          });
        } else {
          /** Add a new exercise group */
          output.exercises.push({
            label: location.label,
            id: location.id,
            items: [],
          });

          const newExerciseIndex = output.exercises.findIndex((exercise) => exercise.id === location.id);

          addStringToGroup({
            dest: output.exercises[newExerciseIndex].items,
            context,
            id,
            interfaceLanguages,
            learningLanguage,
            textLocalizations,
          });
        }

        break;

      default:
        break;
    }
  });

  return output as StringTable;
};
