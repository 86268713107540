import styled from 'styled-components';

import { SearchableSelector } from '@components/SearchableSelector/SearchableSelector';

export const SearchableSelectorWithErrorState = styled(SearchableSelector)<{ isIssuesShown: boolean }>`
  margin-bottom: 0.4rem;

  ${({ isIssuesShown, theme }) =>
    isIssuesShown &&
    `
      border-radius: 0.8rem;
      border: 0.1rem solid ${theme.colorV2.utilityError};
    `}
`;
