import { useState } from 'react';
import styled from 'styled-components/macro';

import { useIsUploadTranslationsAvailable } from '@features/auth/roles';
import { UploadTranslations } from '@components/UploadTranslations';
import ContentsService from '@services/ContentsService';
import type { LanguageV2 } from '@features/content/languages';
import { ReactComponent as DownloadIcon } from '@components/CourseEdition/img/download.svg';
import { ReactComponent as UploadIcon } from '@components/CourseEdition/img/upload.svg';
import { useToast } from '@features/app/toast';
import { HelpDisplayer } from '@features/help';
import { ContextualMenu } from '@features/theme';
import { useAppSelector } from '@redux/store';
import { selectPlacementTestId } from '@selectors/PlacementTestSelectors';

import { ReactComponent as ContextualMenuIcon } from './context-menu-trigger.svg';

const MenuItem = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const StyledContextualMenuIcon = styled(ContextualMenuIcon)`
  transform: scale(1.1);
  transform-origin: right;
  margin-right: 0.8rem;
`;

const StyledHelpDisplayer = styled(HelpDisplayer)`
  display: inline;
`;

type UploadTranslationMenuProps = {
  learningLanguage: LanguageV2;
  interfaceLanguages: LanguageV2[];
};

export const UploadTranslationMenu = ({ learningLanguage, interfaceLanguages }: UploadTranslationMenuProps) => {
  const placementTestId = useAppSelector(selectPlacementTestId);

  const { isUploadTranslationsAvailable } = useIsUploadTranslationsAvailable();

  const [showTranslationsUploadModal, setShowTranslationsUploadModal] = useState(false);

  const showToast = useToast();

  const onDownload = () => {
    ContentsService.csv.download(
      placementTestId,
      learningLanguage,
      interfaceLanguages,
      'placementTest',
      'placementTest',
    );

    showToast({
      type: 'info',
      title: 'Download should have started automatically',
    });
  };

  return (
    <>
      <ContextualMenu position="left" triggerIcon={<StyledContextualMenuIcon />} variant="small" withTrigger>
        {[
          isUploadTranslationsAvailable ? (
            <MenuItem key={0} onClick={() => setShowTranslationsUploadModal(true)}>
              <UploadIcon />
              Upload translations
              <StyledHelpDisplayer type="guideline" id="guideline-upload-translations" />
            </MenuItem>
          ) : null,
          <MenuItem key={1} onClick={onDownload}>
            <DownloadIcon />
            Download translation request
          </MenuItem>,
        ]}
      </ContextualMenu>
      <UploadTranslations show={showTranslationsUploadModal} onHide={() => setShowTranslationsUploadModal(false)} />
    </>
  );
};
