import { AxiosResponse } from 'axios';
import { Fragment, ReactNode, useState } from 'react';

import { useToast } from '@features/app/toast';
import { Modal, useDialogModal } from '@features/modal';
import { useIsUploadTranslationsAvailable } from '@features/auth/roles';
import { HeaderItemSeparator } from '@features/content/header';
import { LanguageV2 } from '@features/content/languages';
import { HelpDisplayer } from '@features/help';
import { ContextualMenu } from '@features/theme';

import {
  UploadTranslationsToLocalisationServiceModal,
  UploadTranslationsToLocalisationServiceProgressModal,
} from '../UploadTranslationsToLocalisationService';
import { NewMark, StyledContextualMenuTriggerIcon } from './styles';
import { ReactComponent as DownloadTranslationsIcon } from './_img/download.svg';
import { ReactComponent as LocalisationServiceIcon } from './_img/localisation-service.svg';
import { ReactComponent as UploadTranslationsIcon } from './_img/upload.svg';
import { isFeatureEnabled } from '@helpers/featuresHelper';

type UploadDownloadTranslationsOptionProps = {
  label: string;
  icon: ReactNode;
  showHelp?: boolean;
  showNew?: boolean;
  onClick: () => void;
};

const UploadDownloadTranslationsOption = ({
  label,
  icon,
  showHelp = false,
  showNew = false,
  onClick,
}: UploadDownloadTranslationsOptionProps) => (
  <div className="context-menu__list-element" onClick={onClick}>
    {icon}
    <span>{label}</span>
    {showNew && <NewMark>New</NewMark>}
    {showHelp && <HelpDisplayer type="guideline" id="guideline-upload-translations" />}
  </div>
);

type UploadDownloadTranslationsProps = {
  language: LanguageV2;
  showSeparator?: boolean;
  onDownload: () => void;
  onUpload: () => void;
  onUploadToLocalisationService: () => void;
};

export const UploadDownloadTranslationsMenu = ({
  language,
  showSeparator = true,
  onDownload,
  onUpload,
  onUploadToLocalisationService,
}: UploadDownloadTranslationsProps) => {
  const [uploadTranslationToLocalisationServicePromise, setUploadTranslationToLocalisationServicePromise] = useState<
    Promise<AxiosResponse> | undefined
  >(undefined);

  const { isUploadTranslationsAvailable, isUploadTranslationsToLocalisationServiceAvailable } =
    useIsUploadTranslationsAvailable();
  const shouldShowUploadTranslationsToLocalisationService =
    isFeatureEnabled('smartling') && isUploadTranslationsToLocalisationServiceAvailable;

  const { open: openUploadToLocalisationServiceModal, modal: uploadTranslationsToLocalisationServiceModal } =
    useDialogModal((modalControls) => (
      <Modal lockScroll overflow="visible" size="M" {...modalControls}>
        <UploadTranslationsToLocalisationServiceModal
          isOpen={modalControls.isOpen}
          language={language}
          onCancel={() => {
            modalControls.close();
            setUploadTranslationToLocalisationServicePromise(undefined);
          }}
          onUpload={(uploadTranslationPromise) => {
            modalControls.close();
            setUploadTranslationToLocalisationServicePromise(uploadTranslationPromise);
            openUploadTranslationsToLocalisationServiceProgressModal();
          }}
        />
      </Modal>
    ));

  const {
    open: openUploadTranslationsToLocalisationServiceProgressModal,
    modal: uploadTranslationsToLocalisationServiceProgressModal,
  } = useDialogModal((modalControls) => (
    <Modal lockScroll size="S" {...modalControls}>
      <UploadTranslationsToLocalisationServiceProgressModal
        uploadTranslationPromise={uploadTranslationToLocalisationServicePromise}
        onClose={modalControls.close}
        onError={() => {
          modalControls.close();
          openUploadToLocalisationServiceModal();
        }}
      />
    </Modal>
  ));

  const showToast = useToast();

  const showDownloadStartedToast = () => {
    showToast({
      type: 'info',
      title: 'Download should have started automatically',
    });
  };

  return (
    <>
      <ContextualMenu position="left" triggerIcon={<StyledContextualMenuTriggerIcon />} variant="small" withTrigger>
        {[
          <Fragment key="_translations-upload">
            {isUploadTranslationsAvailable ? (
              <UploadDownloadTranslationsOption
                label="Upload translations"
                icon={<UploadTranslationsIcon />}
                showHelp
                onClick={onUpload}
              />
            ) : null}
          </Fragment>,
          <Fragment key="_translations-upload-to-localisation-service">
            {shouldShowUploadTranslationsToLocalisationService ? (
              <UploadDownloadTranslationsOption
                label="Upload to Smartling"
                icon={<LocalisationServiceIcon />}
                showNew
                onClick={openUploadToLocalisationServiceModal}
              />
            ) : null}
          </Fragment>,
          <UploadDownloadTranslationsOption
            icon={<DownloadTranslationsIcon />}
            key="_translations-download"
            label="Download translation request"
            onClick={() => {
              onDownload();
              showDownloadStartedToast();
            }}
          />,
        ]}
      </ContextualMenu>
      {showSeparator && <HeaderItemSeparator />}
      {uploadTranslationsToLocalisationServiceModal}
      {uploadTranslationsToLocalisationServiceProgressModal}
    </>
  );
};
