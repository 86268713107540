import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentTypes, type ContentTypesType } from '@common/enums/ContentTypes';
import { DBId } from '@common/types/DBId';
import { GrammarReviewService } from '@services/GrammarReviewService';
import { NavigationItem } from './NavigationItem';
import { NavigationContainer, NavigationItemType } from '@features/content/navigation';
import { Loader } from '@features/theme';
import { GrammarReviewActionCreators } from '@actionCreators/GrammarReviewActionCreators';
import { useAppDispatch, useAppSelector } from '@redux/store';
import {
  selectGrammarNavigationLoadingParentId,
  selectGrammarNavigationStructure,
  selectGrammarReview,
} from '@selectors/GrammarSelectors';

type NavigationItemFromBackType = {
  id: DBId;
  title: string | null;
  children: boolean;
  ready: boolean;
  experiment?: boolean;
  parentId?: DBId;
};

/** @deprecated  Use Navigation from features/content/navigation instead */
export const Navigation = () => {
  const dispatch = useAppDispatch();
  const { courseId, categoryId, topicId, exerciseId } = useParams<{
    courseId: DBId;
    categoryId: DBId;
    topicId: DBId;
    exerciseId: DBId;
  }>();
  const currentPathIds = [courseId, categoryId, topicId, exerciseId];

  const navigationStructure = useAppSelector(selectGrammarNavigationStructure);
  const loadingParentId = useAppSelector(selectGrammarNavigationLoadingParentId);

  const grammarReview = useAppSelector(selectGrammarReview);

  const getChildrenArray = (type: ContentTypesType, id: DBId) => {
    if (!navigationStructure.map((content) => content.parentId).includes(id)) {
      dispatch(GrammarReviewActionCreators.setLoadingParentId(id));

      switch (type) {
        case ContentTypes.grammarReview:
          return GrammarReviewService.getReviewNavigation(id).then((result) =>
            result.data.categories.map((category: NavigationItemFromBackType) => ({
              ...category,
              parentId: id,
              type: ContentTypes.grammarCategory,
              expanded: categoryId === category.id,
            })),
          );
        case ContentTypes.grammarCategory:
          return GrammarReviewService.getCategoryNavigation(courseId, id).then((result) =>
            result.data.topics.map((topic: NavigationItemFromBackType) => ({
              ...topic,
              parentId: id,
              type: ContentTypes.grammarTopic,
              expanded: categoryId === topic.id,
            })),
          );
        case ContentTypes.grammarTopic:
          return GrammarReviewService.getTopicNavigation(courseId, id).then((result) =>
            result.data.exercises.map((exercise: NavigationItemFromBackType) => ({
              ...exercise,
              parentId: id,
            })),
          );
        default:
          return [];
      }
    }
    return [];
  };

  const getChildren = async (type: ContentTypesType, id: DBId) => {
    const childrenArray = await getChildrenArray(type, id);
    dispatch(
      GrammarReviewActionCreators.updateNavigation([
        ...navigationStructure,
        ...(childrenArray as NavigationItemType[]),
      ]),
    );
    dispatch(GrammarReviewActionCreators.setLoadingParentId(''));
  };

  useEffect(() => {
    if (grammarReview.content.id) {
      dispatch(GrammarReviewActionCreators.getAllNavigation(courseId, categoryId, topicId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, grammarReview.content.id]);

  const grammarReviewElement = navigationStructure.find((content) => content.type === ContentTypes.grammarReview);

  if (!grammarReviewElement) {
    return <Loader />;
  }

  return (
    <NavigationContainer isDisabled={!!loadingParentId} countTopButtons={1}>
      {grammarReviewElement && (
        <NavigationItem
          content={grammarReviewElement}
          structure={navigationStructure}
          onExpand={getChildren}
          currentPathIds={currentPathIds}
          loadingParentId={loadingParentId}
        />
      )}
    </NavigationContainer>
  );
};
